import React, { useEffect, useState } from "react";
import HeaderBursa from "./HeaderBursa";
import bg2 from "../../../images/background/bg2.png";
import bg3 from "../../../images/background/bg3.png";
import bg4 from "../../../images/background/bg4.png";
import bg5 from "../../../images/background/bg5.png";
import bg6 from "../../../images/background/bg6.png";
import FooterBursa from "./FooterBursa";
import Select from "react-select";
import axiosInstance from "../../../services/AxiosInstance";
import CardBursa from "./Card/CardBursa";
import CardPaginate from "./ShowPage/CardPaginate";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import TablePaginate from "./ShowPage/TablePaginate";
import { showLoading, closeLoading } from "../../customs/loading/loading";
import Filter from "../../customs/Filter/filter";
import SearchInput from "../../customs/Filter/searchInput";
import { Modal } from "react-bootstrap";
import { useCart } from "../../../services/CartContext";
import Swal from "sweetalert2";

const TitleTooltip = ({ title, el }) => {
  const renderTooltip = (props) => (
    <Tooltip id="dynamic-tooltip" {...props}>
      {title}
    </Tooltip>
  );

  return (
    <>
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <>
          <h4 className="text-dark text-truncate mb-0">{title}</h4>
        </>
      </OverlayTrigger>
    </>
  );
};

// const getColorByTypeSoal = (type) => {
//   switch (type) {
//     case "PG":
//       return "primary";
//     case "SA":
//       return "warning";
//     case "BS":
//       return "success";
//     case "UR":
//       return "danger";
//     default:
//       return "primary";
//   }
// };

const getTypeName = (type) => {
  if (type == "PG") {
    return "pilihan-ganda";
  } else if (type == "MPG") {
    return "multiple-pilihan-ganda";
  } else if (type == "BS") {
    return "benar-salah";
  } else if (type == "UR") {
    return "uraian";
  } else if (type == "PJ") {
    return "penjodohan";
  } else if (type == "SA") {
    return "sebab-akibat";
  }
};

const loadData = async (currentPage, perPage, searchKeyword, query) => {
  showLoading();
  try {
    const response = await axiosInstance.get(
      `bursa/paket-soal?page=${currentPage}&search=${searchKeyword}&per_page=${perPage}${
        query != null ? query : ""
      }&type=tryout`
    );
    var data = response.data.data;
    var tmp = [];
    for (var i = 0; i < data.length; i++) {
      tmp.push({
        id: data[i].id,
        code: data[i].code,
        judul: data[i].detail.judul,
        jenis: "paket-soal",
        jumlah_butir: data[i].detail.soal_id_list.length ?? 0,
        created_by: data[i].created_by_user.name,
        cover: data[i].detail.image !== null ? data[i].detail.image : bg6,
        price: data[i].price,
        score: data[i].score,
        data: data[i],
      });
    }
    closeLoading();
    return {
      total: response.data.total,
      data: tmp,
    };
  } catch (error) {
    console.log(error);
  }
};

const BursaTryout = () => {
  const [perPage, setPerPage] = useState(12);
  const [showStyle, setShowStyle] = useState("card");
  const [selBursaPaket, setSelBursaPaket] = useState(null);

  const [konten, setKonten] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [query, setQuery] = useState("");

  useEffect(() => {
    prepData();
  }, [query, currentPage, searchKeyword]);

  const prepData = async () => {
    showLoading();
    try {
      const rest = await loadData(currentPage, perPage, searchKeyword, query);
      setTotal(rest.total);
      setKonten(rest.data);
      await closeLoading();
    } catch (error) {
      await closeLoading();
    }
  };

  const handleQueryFilter = (query) => {
    const params = Object.entries(query)
      .filter(([_, value]) => value !== null)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    const queryString = params ? `&${params}` : "";
    setQuery(queryString);
    setCurrentPage(1);
  };

  const handleSearch = (query) => {
    setSearchKeyword(query);
    setCurrentPage(1);
  };

  const handlePaging = (page) => {
    setCurrentPage(page);
  };

  const fetchDetail = (id) => {
    setSelBursaPaket(id);
  };

  return (
    <>
      <HeaderBursa />
      <div className="container min-vh-100">
        <div className="row">
          {/* <div className="col-12 mt-3">
            <div className="breadcumb d-flex">
              <b>Home </b> <span className="ms-1"> / Bursa Paket Soal</span>
            </div>
          </div> */}

          <div className="col-12 mt-3">
            <div className="card">
              <div className="card-header row">
                <div className="col-sm-12 col-md-4">
                  <h4 className="mb-0">List Bursa Tryout</h4>
                  <p className="text-dark mb-0">
                    <b>{total}</b> Konten Ditemukan !
                  </p>
                </div>
                <div className="col-sm-12 col-md-8 mt-2 mt-lg-0 d-flex justify-content-end align-items-end flex-column flex-md-row">
                  <div className="d-flex justify-content-end">
                    <SearchInput onChangeForm={handleSearch} />
                    <Filter onChangeForm={handleQueryFilter} />
                  </div>
                  <ul
                    as="ul"
                    className="nav nav-tabs dzm-tabs ms-0 mt-lg-0 mt-2 ms-lg-2 "
                    id="myTab"
                    role="tablist"
                    style={{ width: "fit-content" }}
                  >
                    <li as="li" className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          showStyle == "card" ? "active" : ""
                        }`}
                        type="button"
                        onClick={() => setShowStyle("card")}
                      >
                        <i className="fas fa-address-card"></i>
                      </button>
                    </li>
                    <li as="li" className="nav-item">
                      <button
                        className={`nav-link ${
                          showStyle == "list" ? "active" : ""
                        }`}
                        type="button"
                        onClick={() => setShowStyle("list")}
                      >
                        <i className="fas fa-list"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body py-2">
                {showStyle === "card" ? (
                  <CardPaginate
                    data={konten}
                    onDetail={fetchDetail}
                    onBursa={loadData}
                    total={total}
                    perPage={perPage}
                    type="tryout"
                    onPaging={handlePaging}
                  ></CardPaginate>
                ) : (
                  <TablePaginate
                    data={konten}
                    onDetail={fetchDetail}
                    onBursa={loadData}
                    total={total}
                    perPage={perPage}
                    type="tryout"
                    onPaging={handlePaging}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {selBursaPaket != null && (
        <ModalDetailBursaTryout
          id={selBursaPaket}
          onClose={() => setSelBursaPaket(null)}
        />
      )}
      <FooterBursa />
    </>
  );
};

const BursaMiniTryout = (props) => {
  const [konten, setKonten] = useState([]);
  const { cart, dispatch } = useCart();
  const [selBursaPaket, setSelBursaPaket] = useState(null);

  useEffect(() => {
    prepData();
  }, []);

  const prepData = async () => {
    const rest = await loadData(1, 4, "", "");
    setKonten(rest.data);
  };

  const handleAddToCart = (product) => {
    dispatch({ type: "ADD_TO_CART", payload: { ...product, quantity: 1 } });
  };

  const isInCart = (productId) => {
    return cart.some((item) => item.id === productId);
  };

  const fetchGetId = (id) => {
    setSelBursaPaket(id);
  };

  return (
    <>
      <div className="col-12 mb-3">
        <center>
          <h3 className="text-white">Paket Soal terbaru</h3>
        </center>
      </div>
      <div className="row px-3 px-lg-0">
        {konten.slice(0, 4).map((paket, key) => (
          <div
            className="col-md-6 col-lg-3 col-sm-12 mt-2"
            onClick={() => fetchGetId(paket.id)}
            style={{ cursor: "pointer" }}
          >
            <CardBursa key={key} data={paket} type={"paket"} />
          </div>
        ))}
      </div>
      <div className="col-12 mt-2 text-center">
        <button className="btn btn-info btn-sm">
          Jelajahi lebih banyak paket soal disini
        </button>
      </div>
      {selBursaPaket != null && (
        <ModalDetailBursaTryout
          id={selBursaPaket}
          onClose={() => setSelBursaPaket(null)}
        />
      )}
    </>
  );
};

const ModalDetailBursaTryout = ({ id, onClose }) => {
  const [dataDetail, setDataDetail] = useState(null);
  const [soal, setSoal] = useState([]);
  const [loading, setLoading] = useState(false);

  const [bentukSoal, setBentukSoal] = useState([]);
  const [topikSoal, setTopikSoal] = useState([]);

  const [totalSoal, setTotalSoal] = useState(0);
  const [totalBobot, setTotalBobot] = useState(0);

  useEffect(() => {
    if (id) prepDataDetail(id);
  }, [id]);

  const prepDataDetail = async (id) => {
    showLoading();
    try {
      await handleDetail(id);
      await closeLoading(id);
    } catch (error) {
      closeLoading();
    }
  };

  const previewSoal = async (id) => {
    try {
      const res = await axiosInstance.get(`/bursa/paket-soal/preview/${id}`);
      var paketSoal = res.data.data.value;
      var tmp = [];

      for (var z = 0; z < paketSoal.length; z++) {
        tmp.push({
          type: getTypeName(paketSoal[z].type),
          ...paketSoal[z],
        });
      }
      setSoal(tmp);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDetail = async (id) => {
    showLoading();
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `bursa/paket-soal/${id}?with_content=true`
      );
      await previewSoal(id);

      var scoreSoal = Object.values(res.data.data.score);

      setDataDetail(res.data.data);
      var bentuk = [];

      const totalBobot = scoreSoal.reduce(
        (sum, soal) => sum + parseFloat(soal.bobot),
        0
      );
      const totalSoal = scoreSoal.reduce(
        (sum, soal) => sum + parseFloat(soal.jumlah_soal),
        0
      );
      setTotalSoal(totalSoal);
      setTotalBobot(totalBobot);

      const keys = Object.keys(res.data.data.score);
      for (var d = 0; d < scoreSoal.length; d++) {
        if (keys[d] != "MPG") {
          bentuk.push({
            type: keys[d],
            name: getTypeName(keys[d]),
            ...scoreSoal[d],
          });
        }
      }
      setBentukSoal(bentuk);

      setLoading(false);
      closeLoading();
    } catch (error) {
      setLoading(false);
      closeLoading();
    }
  };

  return (
    <Modal
      dialogClassName="modal-lg"
      show={!!id && !loading}
      size="xl"
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {dataDetail == null ? (
          ""
        ) : (
          <>
            <div className="row">
              <div className="col-12">
                <table className="w-100 nm">
                  <tr>
                    <td className="p-2 text-dark">
                      <b>Perangkai</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataDetail.created_by_user.name}</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>ID Paket Soal</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataDetail.code}</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>Jenjang</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataDetail.detail.jenjang}</b>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 text-dark">
                      <b>Kelas</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataDetail.detail.kelas}</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>Jurusan</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataDetail.detail.jurusan}</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>Mata Pelajaran</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataDetail.detail.mata_pelajaran}</b>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 text-dark">
                      <b>Nama Paket Ujian</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataDetail.detail.judul}</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>Durasi</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataDetail.detail.durasi / 60} (menit)</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>Platform</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataDetail.type}</b>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="col-8 mt-2 table-responsive">
                <table
                  className="table table-bordered table-stripped table-hover mt-2"
                  id="example5"
                  role="grid"
                  aria-describedby="example5_info"
                >
                  <thead>
                    <tr role="row">
                      <th className="px-2">Bentuk Soal</th>
                      <th className="px-2">N</th>
                      <th className="px-2">Bb</th>
                      <th className="px-2">NB</th>
                      <th className="px-2">NS</th>
                      <th className="px-2">NK</th>
                      <th className="px-2">SpS</th>
                      <th className="px-2">Bb%</th>
                      <th className="px-2">SpS %</th>
                    </tr>
                  </thead>

                  <tbody>
                    {bentukSoal.length > 0 ? (
                      <>
                        {bentukSoal.map((v, i) => (
                          <tr key={i}>
                            <td className="px-2">
                              {v.name.replaceAll("-", " ")}
                            </td>
                            <td className="px-2">{v.jumlah_soal}</td>
                            <td className="px-2">{v.bobot}</td>
                            <td className="px-2">{v.point_benar}</td>
                            <td className="px-2">{v.point_salah}</td>
                            <td className="px-2">{v.point_kosong}</td>
                            <td className="px-2">{v.sps}</td>
                            <td className="px-2">{v.bb}</td>
                            <td className="px-2">
                              {v.spsp === null ? "0" : v.spsp}
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td className="px-2">Jumlah Soal</td>
                          <td className="px-2">{totalSoal}</td>
                          <td colSpan={5} className="px-2">
                            {totalBobot}
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr className="text-center">
                        <td colSpan={9}>Tidak ada Soal</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-4">
                <ul className="mt-3">
                  <li className="mb-1">
                    <strong>N :</strong> Jumlah Kartu per Bentuk Soal
                  </li>
                  <li className="mb-1">
                    <strong>Bb :</strong> Nilai Bobot Skor untuk jenis butir
                    soal tertentu dalam paket soal ujian
                  </li>
                  <li className="mb-1">
                    <strong>TB :</strong> Total Jumlah Bobot jenis Butir Soal
                  </li>
                  <li className="mb-1">
                    <strong>NB :</strong> Skor nilai Butir Soal jika dijawab
                    benar
                  </li>
                  <li className="mb-1">
                    <strong>NS :</strong> Skor nilai Butir Soal jika dijawab
                    salah
                  </li>
                  <li className="mb-1">
                    <strong>NK :</strong> Skor nilai Butir Soal jika tidak
                    dijawab
                  </li>
                  <li className="mb-1">
                    <strong>SpS :</strong> Nilai skor per butir soal = Bb/N
                  </li>
                  <li className="mb-1">
                    <strong>Bb% :</strong> Nilai Bobot Skor untuk bentuk soal
                    tertentu dalam paket soal ujian dalam % = Bb1 / Total Bobot
                  </li>
                </ul>
              </div>
              <div className="row">
                <div className="col-sm-12 mt-4">
                  <div className="row">
                    <div className="col-5">
                      {" "}
                      <hr />
                    </div>
                    <div className="col-2 text-center">
                      <h5 className="text-primary mt-1">Preview Paket Soal</h5>
                    </div>
                    <div className="col-5">
                      {" "}
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <section style={{ userSelect: "none" }}>
                    {soal.map((v, i) => (
                      <div key={i} className="no-copy">
                        {/* Petunjuk untuk soal pilihan ganda hanya pada soal pilihan ganda pertama */}
                        {i === soal.findIndex((item) => item.type === "PG") &&
                          v.type === "PG" && (
                            <>
                              <b>I. Bentuk Soal Pilihan Ganda</b>
                              <p>
                                Pilihlah salah satu jawaban yang dianggap benar!
                              </p>
                            </>
                          )}

                        {/* Tampilkan soal pilihan ganda */}
                        {v.type === "PG" ||
                        v.type === "SA" ||
                        v.type === "PJ" ||
                        v.type === "BS" ? (
                          <div
                            className="row mt-3 d-flex"
                            style={{ alignItems: "start" }}
                          >
                            <div className="col-1 text-center">
                              <b>{i + 1}.</b>
                            </div>
                            <div className="col-11">
                              <div className="col-soal mb-3">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: v.value.pertanyaan,
                                  }}
                                ></div>
                              </div>
                              {v.type == "PJ" && (
                                <div className="col-option mt-2">
                                  <span>Pasangan : {v.value.pasangan}</span>
                                  <br />
                                  <span>Pengecoh : {v.value.pengecoh}</span>
                                </div>
                              )}
                              {v.type != "PJ" &&
                                Object.keys(v.value.pilihan).map(
                                  (val, index) => (
                                    <div
                                      className="col-option d-flex mt-2"
                                      key={index}
                                    >
                                      <input
                                        type="radio"
                                        className="me-3"
                                        readOnly
                                        checked={v.value.jawaban == val}
                                      />
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: v.value.pilihan[val],
                                        }}
                                      ></div>
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* {v.type === "MPG" && (
                            <>
                            <div className="row mt-2">
                              <div className="col-1 text-center">
                                <b></b>
                              </div>
                              <div className="col-11">
                                <div className="col-soal mb-3">
                                  <div dangerouslySetInnerHTML={{__html: v.value.content}}></div>
                                </div>
                              </div>
                            </div>
                            { v.value.soal.map((soal, soalIndex) => (
                              <div className="row mt-2" key={soalIndex}>
                                <div className="col-1 text-center">
                                  <b>{i + 1}.</b>
                                </div>
                                <div className="col-11">
                                  <div className="col-soal mb-3">
                                    <div dangerouslySetInnerHTML={{__html: soal.pertanyaan}}></div>
                                  </div>
                                  {Object.keys(soal.pilihan).map((val, index) => (
                                    <div
                                      className="col-option d-flex mt-2"
                                      key={index}
                                    >
                                      <input
                                        type="radio"
                                        className="me-3"
                                        readOnly
                                        checked={soal.jawaban == val}
                                      />
                                      <div dangerouslySetInnerHTML={{__html: soal.pilihan[val]}}></div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                            
                            </>
                          )} */}

                        {i === soal.findIndex((item) => item.type === "UR") &&
                          v.type === "UR" && (
                            <div className="row mt-3">
                              <div className="col-sm-12">
                                <div className="mt-5">
                                  <b>II. Bentuk Soal Uraian</b>
                                  <p>Jawablah pertanyaan di bawah ini!</p>
                                </div>
                              </div>
                            </div>
                          )}

                        {v.type === "UR" && (
                          <div className="row mt-2">
                            <div className="col-1 text-center">
                              <b>{i + 1}.</b>
                            </div>
                            <div className="col-11">
                              <div className="col-soal mb-3">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: v.value.pertanyaan,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    <div className="row">
                      <div className="col-12">
                        <hr />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-secondary" onClick={onClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export { BursaMiniTryout };
export default BursaTryout;
