import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { Modal, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import axiosInstance from "../../../../services/AxiosInstance";
import Pagination from "../../Pagination";
import { closeLoading, showLoading } from "../../../customs/loading/loading";
import Select from "react-select";

const getColorJenisSoal = (type) => {
  switch (type) {
    case "PG":
      return "primary";
    case "SA":
      return "warning";
    case "BS":
      return "success";
    case "UR":
      return "danger";
    case "PJ":
      return "info";
    default:
      return "primary";
  }
};

const TablePaketSoal = (props) => {
  const inputEl = useRef(null);
  const [show, setShow] = useState(false);

  const handleShow = (paketSoal) => {
    setShow(true);
    setSelectedPaketSoal(paketSoal);
  };
  const handleClose = () => setShow(false);

  const [selectedPaketSoal, setSelectedPaketSoal] = useState(null);
  const [price, setPrice] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [files, setFiles] = useState(null);

  const handleChangeFile = (fl) => {
    setFiles(fl.target.files[0]);
  };

  const uploadAdapter = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axiosInstance.post(`media/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return response.data.url;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Gagal Upload File",
      });
      throw error;
    }
  };

  const handleSubmitBursa = async () => {
    showLoading();
    const formData = {
      image: null,
      type: platform,
    };

    if (files != null) {
      const uploadedFileUrl = await uploadAdapter(files);
      formData.image = uploadedFileUrl;
    }

    var params = {
      price: price,
    };

    var resPrice = await axiosInstance.put(
      `paket-soal/price/${selectedPaketSoal.id}`,
      params
    );
    var resBursa = await axiosInstance.put(
      `paket-soal/activate-bursa/${selectedPaketSoal.id}`,
      formData
    );
    if (resPrice.data && resBursa.data) {
      props.onBursa(true);
      handleClose();
      await closeLoading();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Berhasil Mengubah data",
      });
    } else {
      await closeLoading();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Gagal Mengubah data",
      });
    }
  };

  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

  const sort = 10;
  const activePag = useRef(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
  }, []);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };

  const onPaging = (page) => {
    props.onPaging(page);
  };
  return (
    <div className="col-xl-12">
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable table-bordered"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc">No</th>
                    <th className="sorting">Waktu Buat</th>
                    <th className="sorting">ID Paket Soal</th>
                    <th className="sorting">Judul</th>
                    <th className="sorting">Jenis</th>
                    <th className="sorting">Status</th>
                    <th className="sorting">Platform</th>
                    <th className="sorting">Perangkai</th>
                    <th className="sorting">Action</th>
                    <th className="sorting">Statistik</th>
                  </tr>
                </thead>

                <tbody>
                  {props.contacts.length > 0 ? (
                    props.contacts.map((v, i) => (
                      <tr className={(i + 1) % 2 == 1 ? "odd" : "even"}>
                        <td>{i + 1}</td>
                        <td>{v.created_at}</td>
                        <td>{v.kode_paket}</td>
                        <td>{v.paket_soal_name}</td>
                        <td>
                          {v.original.score ? (
                            <>
                              {Object.entries(v.original.score).map(
                                ([key, value]) =>
                                  value.jumlah_soal >= 1 ? (
                                    <span
                                      key={key}
                                      className={`badge m-1 badge-${getColorJenisSoal(
                                        key
                                      )}`}
                                    >
                                      {key}
                                    </span>
                                  ) : null
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {v.is_draft ? (
                            <small className="badge badge-sm badge-secondary">
                              Draft
                            </small>
                          ) : v.is_bursa ? (
                            <small className="badge badge-sm badge-success">
                              Bursa
                            </small>
                          ) : (
                            <small className="badge badge-sm badge-dark">
                              Done
                            </small>
                          )}
                        </td>
                        <td>
                          <small className="mb-0 text-primary">
                            {v.is_bursa ? (
                              <b className="ms-2">
                                {v.original.type == "test-online/usbk"
                                  ? "(USBK / Online)"
                                  : "(Tryout)"}
                              </b>
                            ) : (
                              '-'
                            )}
                          </small>
                        </td>
                        <td>{v.author}</td>
                        <td className="d-flex">
                          <div
                            className="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              type="button"
                              className="btn btn-outline-primary text-danger"
                              onClick={() => props.onDelete(v.id)}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                            {/* <button
                              type="button"
                              className="btn btn-outline-primary"
                              onClick={() => props.onBobot(v)}
                            >
                              <i className="fas fa-pencil"></i> Bobot
                            </button> */}
                            {/* {v.is_draft && (
                              <>
                                <Link
                                  to={`/paket-soal/action/update?id=${v.id}`}
                                  className="btn btn-outline-primary text-warning"
                                >
                                  <i className="fas fa-pencil-alt me-1"></i> Lanjutkan
                                </Link>
                              </>
                            )} */}
  
                            {!v.is_draft && !v.is_bursa ? (
                              <button
                                type="button"
                                onClick={() => handleShow(v)}
                                className="btn btn-outline-primary"
                              >
                                <i className="fas fa-plus"></i> Bursa
                              </button>
                            ) : (
                              ""
                            )}
                            <Link
                              to={`/paket-soal/preview/${v.id}`}
                              className="btn btn-sm btn-outline-primary"
                            >
                              <i className="fas fa-eye"></i>
                            </Link>
                          </div>
                          {!v.is_used && v.is_draft && (
                            <Dropdown>
                              <Dropdown.Toggle
                                size="sm"
                                variant="outline-warning"
                                className="ms-2"
                                id="dropdown-basic"
                              >
                                <i className="fa fa-pencil me-1"></i>
                              </Dropdown.Toggle>
  
                              <Dropdown.Menu>
                                <Link
                                  to={`/paket-soal/edit/properti?id=${v.id}`}
                                  className="dropdown-item"
                                >
                                  Properti
                                </Link>
                                <Link
                                  to={`/paket-soal/edit/kartu?id=${v.id}`}
                                  className="dropdown-item"
                                >
                                  Kartu Soal
                                </Link>
                                <Link
                                  to={`/paket-soal/edit/topik?id=${v.id}`}
                                  className="dropdown-item"
                                >
                                  Topik
                                </Link>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </td>
                        <td>
                          <Link
                            to={`/paket-soal/statistik/${v.id}`}
                            className="btn btn-sm btn-outline-primary"
                          >
                            <i className="fas fa-chart-bar"></i>
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={10}>Tidak ada data</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                totalData={props.total}
                dataPerPage={props.perPage}
                onChange={onPaging}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal dialogClassName="modal-md" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bursa Paket Soal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <b className="mb-3">Sampul</b>
            <input
              type="file"
              className="form-control"
              onChange={handleChangeFile}
              accept="image/*,audio/*,video/*"
            />
          </div>
          <div className="form-group mt-4">
            <h5 className="text-primary">
              Platform <span className="text-danger">*</span>
            </h5>
            <Select
              className="basic-single"
              classNamePrefix="select"
              name="color"
              options={[
                { value: "test-online/usbk", label: "Test Online/USBK" },
                { value: "tryout", label: "Tryout" },
              ]}
              onChange={(e) => setPlatform(e.value)}
            />
          </div>
          <div className="form-group mt-4">
            <b className="mb-3">Harga</b>
            <input
              type="number"
              className="form-control mt-2"
              placeholder="Masukkan Harga Scoin"
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={handleSubmitBursa}
          >
            Masukkan Bursa
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TablePaketSoal;
