import React from "react";
import HeaderBursa from "./HeaderBursa";
import BG from "../../../images/background/background_landing.svg";
import FooterBursa from "./FooterBursa";
import { BursaMiniKartuSoal } from "./BursaKartuSoal";
import { BursaMiniPaketSoal } from "./BursaPaketSoal";
import gearBg from "../../../images/background/gear_bg.svg";

const LandingPage = () => {
  const removeCookie = () => {
    document.cookie =
      "SSO_TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=login.schoolmedia.id";
  };

  return (
    <>
      <HeaderBursa />
      <section className="container">
        <div className="row mt-2 mb-2 mb-lg-0">
          <div style={{ position: "relative" }}>
            <img
              src={BG}
              style={{
                maxWidth: "93%",
                minWidth: "243px",
                zIndex: "-2",
                position: "absolute",
                opacity: "0.1",
              }}
              alt=""
              className="d-block d-lg-none right-0 left-0"
            />
          </div>
          <div className="col-md-8 col-lg-5 d-flex align-items-center">
            <div className="p-3">
              <h2 className="text-dark mb-3" style={{ lineHeight: "36px" }}>
                Platform berbagi materi dan ujian untuk meningkatkan kualitas
                pembelajaran
              </h2>
              <p className="text-primary mb-0">
                <b>
                  Temukan paket dan kartu soal untuk PAUD/RA, SD/MI, SMP/MTs,
                  SMA/MA, dan SMK/MAK di sini!
                </b>
              </p>
            </div>
          </div>
          <div className="col-md-4 col-lg-7">
            <img
              src={BG}
              style={{
                maxWidth: "100%",
                minWidth: "243px",
              }}
              alt=""
              className="d-none d-lg-block"
            />
          </div>
        </div>
      </section>

      <section className="bg-gray p-2 p-lg-5">
        <div className="container">
          <BursaMiniKartuSoal />
        </div>
      </section>

      <section className="bg-primary position-relative overflow-hidden p-lg-5 py-5">
        <div
          className="bottom-0 position-absolute w-100 h-100"
          style={{
            backgroundImage: `url(${gearBg})`,
            backgroundSize: "cover",
            top: "60px",
            left: 0,
            opacity: 0.1,
          }}
        ></div>
        <div className="container p-2">
          <BursaMiniPaketSoal />
        </div>
      </section>
      <FooterBursa />
    </>
  );
};
export default LandingPage;
