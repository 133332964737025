import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../services/AxiosInstance";
import { BAHANAJAR_HOSTNAME, TESTONLINE_HOSTNAME } from "../../../constant";
import Swal from "sweetalert2";
import Pagination from "../Pagination";
import { formatDateTime } from "../../utils/dateFormat";
import axiosBahanAjar from "../../../services/axiosBahanAjar";
import CustomSwitch from "../CustomSwitch";
import { Button, Modal } from "react-bootstrap";

const Pembelajaran = () => {
  const { type } = useParams();
  
  const { typeujian } = useParams();
  const inputEl = useRef(null);

  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

  const [exData, setExData] = useState([
    
  ]);

  console.log("data");
  console.log(data);

  const sort = 10;
  const activePag = useRef(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  const [perPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  
  // use effect
  useEffect(() => {
    loadData();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    loadData();
  }, [type]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    //settest(i);
  };

  
  const loadData = async () => {
    try {
      const response = await axiosBahanAjar.get(`pembelajaran?per_page=${perPage}&page=${currentPage}&type=${type}`);
      console.log(response);
      var data = response.data.data;
      setExData(data.items);
      setTotalData(data.meta.totalItems);
    } catch (error) {
      console.log(error);
    }
  }

  const goTestOnline = (test) => {
    console.log(test);
    window.open(`${BAHANAJAR_HOSTNAME}/pembelajaran/${test.id}/${test.id_paket_ajar}`, '_blank');
  }

  const navigate = useNavigate();
  const handleLink = (e) => {
    navigate(e);
  };

  const handleDelete = async (test) => {
    console.log(test);
    Swal.fire({
      title: 'Anda Yakin?',
      text: "Anda tidak dapat mengembalikan data ini!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Ya, Hapus!'
    }).then((result) => {
      if (result.isConfirmed) {
        actionDelete(test.id);
        // Perform deletion action here
        Swal.fire(
          'Deleted!',
          'Data berhasil dihapus.',
          'success'
        );
      }
    });
  }

  const actionDelete = async (id) => {
    try {
      const response = await axiosBahanAjar.delete(`pembelajaran/${id}`);
      console.log(response);
      loadData();
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!\n'+error.response.data.error,
      })
    }
  }

  const handlePaging = (page) => {
    console.log("handlePaging");
    console.log("page:", page);
    setCurrentPage(page);
  }

  const handleChangeStatus = async (id, status) => {
    console.log(id, status);
    await axiosBahanAjar.put(`pembelajaran/${id}`, {
      status: status ? 'publish' : 'draft'
    })

    loadData();
  };

  const [selectedId, setSelectedId] = useState(null);
  const [thumbnail, setThumbnail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSave = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        var res = await axiosInstance.post('/media/upload', formData);
        console.log('url', res.data.url);

        var update = await axiosBahanAjar.put(`pembelajaran/${selectedId}`, {
          thumbnail: res.data.url
        })

        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Thumbnail berhasil diubah',
        })
        loadData();
      } catch (error) {
        console.error("Upload failed", error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Gagal Upload File',
        })
      }
    }
    setShowModal(false);
  };

  const [showBursa, setShowBursa] = useState(false);

  const handleShow = (soal) => {
    setShowBursa(true);
    setSelectedId(soal.id);
    setStatus(soal.status);
  };
  const handleClose = () => {
    setShowBursa(false)
    setSelectedId(null)
    loadData();
  };

  const [price, setPrice] = useState(0);

  const handleSubmitBursa = async () => {
    if (!price) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Harga harus di isi'
      })
      return
    }
    console.log("price:", price);
    var params = {
      is_bursa: true,
      price: price
    }
    var resPrice = await axiosBahanAjar.put(`pembelajaran/${selectedId}`, params);
    // var resBursa = await axiosInstance.put(`soal/activate-bursa/${selectedSoal.id}`, {});
    if (resPrice.data) {
      handleClose();
    }
  };
  
  const [status, setStatus] = useState(false);
  const onStatusChange = async (id, status) => {
    try {
      const response = await axiosBahanAjar.put(`pembelajaran/${id}`, {
        status: status ? 'publish' : 'draft'
      })
      console.log(response);
      setStatus(status ? 'publish' : 'draft');
      Swal.fire({
        icon: 'success',
        title: 'Status berhasil diubah',
        showConfirmButton: false,
        timer: 1500
      })
      if (type == "pbm") {
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header d-flex justify-content-between flex-wrap">
          <div className="input-group search-area mb-md-0">
            <input
              type="text"
              className="form-control"
              placeholder="Search here..."
            />
            <span className="input-group-text">
              <Link to={"#"}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                    fill="#01A3FF"
                  />
                </svg>
              </Link>
            </span>
          </div>
          <div className="d-flex">
            <Link
              type="button"
              className="btn btn-primary"
              to={"/pembelajaran/" + type + "/create"}
            >
              + Buat {type == "pbm" ? "PBM" : "Belajar Mandiri"}
            </Link>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable table-bordered"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th>No</th>
                    <th>Waktu dibuat</th>
                    {/* <th className="sorting">Tipe Ujian</th>
                    <th className="sorting">ID Ujian</th>
                    <th className="sorting">Paket Soal</th> */}
                    <th>Nama</th>
                    <th>Kelas</th>
                    {type == "pbm" && <th>Rombel</th>} 
                    {type == "pbm" && <th>Tgl Belajar</th>}
                    <th>Thumbnail</th>
                    <th>Status</th>
                    {/* <th className="sorting text-center">Detail</th> */}
                    {type == "pbm" && <th className="text-center">Mulai</th>}
                    {/* <th className="sorting text-center">Preview</th> */}
                    <th className="text-center">Aksi</th>
                  </tr>
                </thead>

                <tbody>
                  {exData.map((v, i) => (
                    <tr className={(i + 1) % 2 == 1 ? "odd" : "even"}>
                      <td>{i + 1}</td>
                      <td>{v.createdAt.split("T")[0]}</td>
                      {/* <td>{v.type}</td>
                      <td>{v.id_ujian}</td>
                      <td>{v.id_paket}</td> */}
                      <td>{v.title}</td>
                      <td>{v.detail.kelas}</td>
                      {type == "pbm" && <td>{v.detail.rombel}</td>}
                      {type == "pbm" && <td dangerouslySetInnerHTML={{ __html: formatDateTime(v.started_at) }} ></td>}
                      <td>
                      <div
                          className="position-relative d-inline-block"
                          onClick={() => {
                            setShowModal(true);
                            setSelectedId(v.id);
                          }}
                        >
                          <img src={v.thumbnail} width="100" alt="Thumbnail" />
                          <div className="overlay position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center text-white fw-bold" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", opacity: 0, transition: "opacity 0.3s" }}>
                            Ganti/Ubah
                          </div>
                        </div>
                      </td>
                      <td className="text-center" onClick={() => handleShow(v)}><div className="badge badge-success ml-1">
                        {v.status == "draft"
                        ? "Draft" : !v.is_bursa
                        ? "No Bursa" : v.id_creator == v.id_owned
                        ? "Bursa" : "Dari Bursa"}
                        </div></td>
                      {/* <td className="text-center">
                        <Link
                          to={`/`}
                          className="btn btn-sm btn-outline-primary"
                        >
                          <i className="fas fa-eye"></i>
                        </Link>
                      </td> */}
                      {type == "pbm" && <td className="text-center">
                        {v.status == "publish" && <div
                          className="btn btn-sm btn-outline-danger"
                          onClick={() => v.status == "finished" ? null : goTestOnline(v)}
                        >
                          {v.status == "finished" ? <i className="fas fa-xmark"></i> : v.status == "started" ? <i className="fas fa-play"></i> : <i className="fas fa-play"></i>} 
                        </div>}
                        {v.status == "draft" && <span>Belum Publish</span>}
                      </td>}
                      {/* <td className="text-center">
                        <span
                          className={`badge ${
                            v.status == "finished" ? "bg-success" : "bg-secondary"
                          }`}
                        >
                          {v.status == "finished" ? "Selesai" : v.status == "started" ? "Mulai" : "Belum Mulai"}
                        </span>
                      </td> */}
                      
                      {/* <td className="text-center">
                        <div
                          className="btn btn-sm btn-outline-danger"
                          // onClick={() => handleDelete(v)}
                        >
                          <i className="fas fa-eye"></i>
                        </div>
                      </td> */}
                      <td className="d-flex text-center gap-2">
                        <div
                          className="btn btn-sm btn-outline-danger"
                          onClick={() => handleLink(`/pembelajaran/preview/${v.id_paket_ajar}`)}
                        >
                          <i className="fas fa-eye"></i>
                        </div>
                        <div
                          className="btn btn-sm btn-outline-danger"
                          onClick={() => handleDelete(v)}
                        >
                          <i className="fas fa-trash"></i>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination totalData={totalData} dataPerPage={perPage} onChange={handlePaging} />
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Ubah Thumbnail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" accept="image/*" onChange={handleFileChange} />
          {preview && <img src={preview} alt="Preview" className="img-fluid mt-3" />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Batal
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal dialogClassName="modal-lg" show={showBursa} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{type == "mandiri" ? "Bursa" : "Publish"} {type == "pbm" ? "PBM" : "Belajar Mandiri"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mt-4">
            <b className="mb-3">Status</b>
            <CustomSwitch id={selectedId} value={status == 'publish' ? true : false} onChange={onStatusChange} />
          </div>
          {status == 'draft' && type == 'mandiri' && <span className="text-danger mt-5">Untuk bisa memasukkan harga scoin dan bursa, silahkan publish terlebih dahulu</span>}
          {status == 'publish' && type == 'mandiri' && <div className="form-group mt-4">
            <b className="mb-3">Harga</b>
            <input type="number" className="form-control mt-2" placeholder="Masukkan Harga Scoin" value={price} onChange={(e) => setPrice(e.target.value)}/>
          </div>}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-secondary" onClick={handleClose}>
            Close
          </button>
          {type == "mandiri" && <button className="btn btn-outline-primary" onClick={handleSubmitBursa} disabled={status == 'draft' ? true : false}>
            Masukkan Bursa
          </button>}
        </Modal.Footer>
      </Modal>

      <style>
        {`
          .position-relative:hover .overlay {
            opacity: 1;
          }
        `}
      </style>
    </div>
  );
};

export default Pembelajaran;
