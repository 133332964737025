import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import { IMAGES } from "../Dashboard/Content";
import CardKartuSoal from "./ShowPage/Card";
import TableKartuSoal from "./ShowPage/Table";
import axiosInstance from "../../../services/AxiosInstance";
import { connect } from "react-redux";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
import Swal from "sweetalert2";
import Filter from "../../customs/Filter/filter";
import { showLoading, closeLoading } from "../../customs/loading/loading";
import Select from "react-select";
import SearchInput from "../../customs/Filter/searchInput";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const ButirSoal = (props) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [soal, setSoal] = useState([]);
  const [showStyle, setShowStyle] = useState("card");
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(16);
  const [query, setQuery] = useState(null);
  const [filterTypeKartu, setFilterTypeKartu] = useState(null);
  const [typeKartuOpt, setTypeKartuOpt] = useState([
    { value: "PG", label: "Pilihan Ganda" },
    { value: "PJ", label: "Penjodohan" },
    { value: "UR", label: "Uraian" },
    { value: "BS", label: "Benar Salah" },
    { value: "SA", label: "Sebab Akibat" },
  ]);

  const getColorByTypeSoal = (type) => {
    switch (type) {
      case "PG":
        return "primary";
      case "SA":
        return "warning";
      case "BS":
        return "success";
      case "UR":
        return "danger";
      default:
        return "primary";
    }
  };

  const typeByJenisSoal = (type) => {
    switch (type) {
      case "PG":
        return "pilihan-ganda";
      case "SA":
        return "sebab-akibat";
      case "BS":
        return "benar-salah";
      case "UR":
        return "uraian";
      case "PJ":
        return "penjodohan";
      case "MPG":
        return "pilihan-ganda";
      default:
        return "PG";
    }
  };

  const loadButirSoal = async () => {
    try {
      showLoading();
      const response = await axiosInstance.get(
        `soal?per_page=${perPage}&page=${currentPage}${
          filterTypeKartu != null ? "&type=" + filterTypeKartu.value : ""
        }${searchKeyword != null ? "&materi=" + searchKeyword : ""}${
          query != null ? query : ""
        }`
      );
      var data = response.data.data;
      var tmp = [];
      for (var i = 0; i < data.length; i++) {
        var date = new Date(data[i].created_at);
        const formattedDate = `${String(date.getDate()).padStart(
          2,
          "0"
        )}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${date.getFullYear()}`;

        tmp.push({
          id: data[i].id,
          kode_paket: data[i].code,
          paket_soal_name: data[i].detail.materi,
          author: props.user.name,
          is_draft: data[i].is_draft,
          created_at: formattedDate,
          is_bursa: data[i].is_bursa,
          jenis_soal: [
            {
              name: data[i].type,
              color: getColorByTypeSoal(data[i].type),
            },
          ],
          type: data[i].type,
          type_style: typeByJenisSoal(data[i].type),
        });
      }

      setSoal(tmp);
      setTotal(response.data.total);
      setCurrentPage(response.data.current_page);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  useEffect(() => {
    prepData();
  }, [query, currentPage, searchKeyword, filterTypeKartu]);

  const prepData = async () => {
    try {
      showLoading();
      await loadButirSoal();
    } catch (error) {
    } finally {
      closeLoading();
    }
  };

  const onDelete = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus",
      text: "Apakah anda yakin ingin menghapus?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`soal/${id}`);

          Swal.fire("Terhapus!", "Data telah terhapus.", "success");

          loadButirSoal();
        } catch (error) {
          let errorMessage = "An error occurred.";
          if (error.response) {
            errorMessage = error.response.data.message || error.response.data;
          } else if (error.request) {
            errorMessage =
              "No response from server. Please check your connection.";
          } else {
            errorMessage = error.message;
          }

          Swal.fire({
            title: "Oops",
            text: errorMessage,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const handlePaging = (page) => {
    setCurrentPage(page);
  };

  const handleQueryFilter = (query) => {
    const params = Object.entries(query)
      .filter(([_, value]) => value !== null)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    const queryString = params ? `&${params}` : "";
    setQuery(queryString);
  };
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="page-title flex-wrap">
          <div>
            <SearchInput
              type="text"
              className="form-control"
              placeholder="Search here..."
              onChangeForm={(e) => setSearchKeyword(e)}
            />
          </div>
          <div className="d-flex align-items-ceter">
            <Select
              className="basic-single me-2 mt-1"
              classNamePrefix="select"
              value={filterTypeKartu}
              isRtl={false}
              name="color"
              options={typeKartuOpt}
              placeholder={"Pilih Bentuk Soal"}
              isClearable={true}
              onChange={(e) => setFilterTypeKartu(e ?? null)}
            />
            <ul
              as="ul"
              className="nav nav-tabs dzm-tabs me-2"
              id="myTab"
              role="tablist"
            >
              <li as="li" className="nav-item" role="presentation">
                <button
                  className={`nav-link ${showStyle == "card" ? "active" : ""}`}
                  type="button"
                  onClick={() => setShowStyle("card")}
                >
                  <i className="fas fa-address-card"></i>
                </button>
              </li>
              <li as="li" className="nav-item">
                <button
                  className={`nav-link ${showStyle == "list" ? "active" : ""}`}
                  type="button"
                  onClick={() => setShowStyle("list")}
                >
                  <i className="fas fa-list"></i>
                </button>
              </li>
            </ul>
            <Filter onChangeForm={handleQueryFilter} />
            <Link
              type="button"
              className="btn btn-primary"
              to={"/kartu-soal/add/pilihan-ganda"}
            >
              + Buat Butir Soal
            </Link>
          </div>
        </div>
      </div>
      {showStyle == "card" ? (
        <CardKartuSoal
          contacts={soal}
          onBursa={loadButirSoal}
          onDelete={onDelete}
          total={total}
          perPage={perPage}
          onPaging={handlePaging}
        />
      ) : (
        <TableKartuSoal
          contacts={soal}
          onBursa={loadButirSoal}
          onDelete={onDelete}
          total={total}
          perPage={perPage}
          onPaging={handlePaging}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    user: state.auth.auth,
  };
};

export default withRouter(connect(mapStateToProps)(ButirSoal));
