import React, { useEffect, useRef, useState } from "react";

const SearchInput = (props) => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isSearched, setIsSearched] = useState(false);

  const handleSearch = async (value) => {
    setIsSearched(value != '' ? true : false);

    setSearchKeyword(value);
    props.onChangeForm(value);
  };

  const removeSearch = () => {
    setSearchKeyword('');
    setIsSearched(false)
    props.onChangeForm('');
  }

  return (
    <>
      <div className="input-group search-area me-2 w-100 w-lg-75">
        <input
          type="text"
          className="form-control"
          placeholder="Cari disini..."
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)} 
          onKeyDown={(e) => {
            clearTimeout(window.searchTimer);
            window.searchTimer = setTimeout(() => {
              handleSearch(e.target.value);
            }, 500);
          }}
        />
        <span className="input-group-text">
          <i className="fa fa-solid fa-search text-primary"></i>
        </span>
        {isSearched && (
          <span onClick={removeSearch} className="position-absolute me-3" style={{top: '25%', cursor: 'pointer'}}>
            <small className="fa text-primary fa-solid fa-x"></small>
          </span>
        )}
      </div>
    </>
  );
};

export default SearchInput;
