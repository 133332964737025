export const MenuList = [
  {
    title: "Dashboard",
    iconStyle: <i className="material-icons">home</i>,
    to: "dashboard",
  },
  {
    title: "Referensi",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-symbols-outlined">bookmark</i>,
    content: [
      {
        title: "Struktur Kurikulum",
        to: "referensi/struktur-kurikulum",
      },
      {
        title: "Struktur Rombel",
        to: "referensi/struktur-rombel",
      },
    ],
  },
  {
    title: "Kartu Soal",
    iconStyle: <i className="material-icons">book</i>,
    to: "kartu-soal",
  },
  {
    title: "Paket Soal",
    iconStyle: <i className="material-icons">library_books</i>,
    to: "paket-soal",
  },

  {
    title: "Daftar Ujian",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-symbols-outlined">list</i>,
    content: [
      {
        title: "Test Online",
        to: "daftar-ujian/test-online",
      },
      {
        title: "USBK",
        to: "daftar-ujian/usbk",
      },
      {
        title: "Tryout",
        to: "daftar-ujian/tryout",
      },
    ],
  },
  {
    title: "Hasil Ujian",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-symbols-outlined">poll</i>,
    content: [
      {
        title: "Test Online",
        to: "hasil-ujian/test-online",
      },
      {
        title: "USBK",
        to: "hasil-ujian/usbk",
      },
      {
        title: "Tryout",
        to: "hasil-ujian/tryout",
      },
    ],
  },
  {
    title: "Bahan Ajar",
    // classsChange: "mm-collapse",
    iconStyle: <i className="material-icons">assignment</i>,
    to: "bahan-ajar/list"
    // content: [
    //   {
    //     title: "List Bahan Ajar",
    //     to: "bahan-ajar/list",
    //   },
    // ],
  },
  {
    title: "Paket Ajar",
    // classsChange: "mm-collapse",
    iconStyle: <i className="material-icons">menu_book</i>,
    to: "paket-ajar/list",
    // content: [
    //   {
    //     title: "List Paket Ajar",
    //     to: "paket-ajar/list",
    //   },
    // ],
  },
  {
    title: "Daftar Mengajar",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-icons">library_books</i>,
    content: [
      {
        title: "PBM",
        to: "pembelajaran/pbm/list",
      },
      {
        title: "Belajar Mandiri",
        to: "pembelajaran/mandiri/list",
      },
    ],
  },
  {
    title: "Hasil Mengajar",
    classsChange: "mm-collapse",
    iconStyle: <i className="material-symbols-outlined">poll</i>,
    content: [
      {
        title: "PBM",
        to: "hasil-pembelajaran/pbm/list",
      },
      {
        title: "Belajar Mandiri",
        to: "hasil-pembelajaran/mandiri/list",
      },
    ],
  },
];
