import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import { Badge, Modal, Button, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import "./DetailHasil.css";
import { closeLoading, showLoading } from "../../../customs/loading/loading";
import BackButton from "../../../customs/Button/BackButton";

const JawabanPeserta = (props) => {
  const inputEl = useRef(null);
  const [activeTab, setActiveTab] = useState("pg");
  const [tab, setTab] = useState(["pg", "mpg", "sa", "bs", "pj", "ur"]);
  const [dtJawaban, setDtJawaban] = useState([]);
  const { id_ujian } = useParams();

  const [showKomposisi, setShowKomposisi] = useState(false);

  useEffect(() => {
    prepData();
  }, []);

  const prepData = async () => {
    showLoading();
    try {
      await loadDataJawaban();
      await closeLoading();
    } catch (error) {
      await closeLoading();
    }
  };

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [dataButir, setDataButir] = useState(null);

  const detailButirSoal = async (id) => {
    showLoading();
    try {
      const response = await axiosInstance.get(`soal/${id}?with_jawaban=true`);
      var data = response.data.data;
      await setDataButir(data);
      await setShowDetailModal(true);
      await closeLoading();
    } catch (error) {
      await closeLoading();
    }
  };

  const loadDataJawaban = async () => {
    const res = await axiosInstance.get(`ujian/${id_ujian}`);
    const data = res.data.data;
    const paketSoal = await loadPaketSoalDetail(data.paket_soal_id);
    var dtSoal = {
      pg: {
        soal: [],
        jawaban: [],
      },
      mpg: {
        soal: [],
        jawaban: [],
      },
      bs: {
        soal: [],
        jawaban: [],
      },
      ur: {
        soal: [],
        jawaban: [],
      },
      pj: {
        soal: [],
        jawaban: [],
      },
      sa: {
        soal: [],
        jawaban: [],
      },
    };

    paketSoal.forEach((item) => {
      if (item.type === "PG") {
        dtSoal["pg"].soal.push(item);
      }
      if (item.type === "MPG") {
        dtSoal["mpg"].soal.push(item);
      }
      if (item.type === "SA") {
        dtSoal["sa"].soal.push(item);
      }
      if (item.type === "BS") {
        dtSoal["bs"].soal.push(item);
      }
      if (item.type === "UR") {
        dtSoal["ur"].soal.push(item);
      }
      if (item.type === "PJ") {
        dtSoal["pj"].soal.push(item);
      }
    });

    for (const key in dtSoal) {
      if (dtSoal.hasOwnProperty(key)) {
        if (dtSoal[key].soal.length > 0) {
          for (let i = 0; i < data.peserta.length; i++) {
            // Initialize 'jawaban' if it doesn't already exist
            if (!dtSoal[key]["jawaban"][i]) {
              dtSoal[key]["jawaban"][i] = [];
            }

            // Add the participant's details
            dtSoal[key]["jawaban"][i].push({
              id: data.peserta[i].id,
              kelas: data.peserta[i].detail.kelas,
              rombel: data.peserta[i].detail.rombel,
              nama_siswa: data.peserta[i].user.name,
              status: data.peserta[i].status,
              score: data.peserta[i].score,
              nilai_bobot: data.peserta[i].nilai_bobot,
              presentase_nilai_bobot: data.peserta[i].presentase_nilai_bobot,
              ranking: data.peserta[i].ranking,
            });

            // Process the responses
            let response = [];
            for (
              let keyIndex = 0;
              keyIndex < dtSoal[key]["soal"].length;
              keyIndex++
            ) {
              let soalId = dtSoal[key]["soal"][keyIndex].no_urut; // Get the specific soal ID
              console.log("rosid ngapain?", soalId);
              // Find the corresponding jawaban
              let jawaban = data.peserta[i].value.find(
                (jawaban) => soalId === jawaban.no_urut
              );

              console.log("rosid serius", data.peserta[i]);
              // Push the jawaban into the specific soal ID
              response.push(jawaban);
            }

            // Update 'jawaban' with responses
            dtSoal[key]["jawaban"][i] = {
              ...dtSoal[key]["jawaban"][i][0], // Use the first item in the 'jawaban' array
              response: response,
            };
          }
        }
      }
    }

    setDtJawaban(dtSoal);
  };

  const loadPaketSoalDetail = async (id_paket_soal) => {
    var res = await axiosInstance.get(
      `paket-soal/${id_paket_soal}?with_content=true`
    );
    console.log("res : ", res);
    return res.data.data.value;
  };
  const [detailJawaban, setDetailJawaban] = useState(null);

  const getTypeCode = (type) => {
    if (type == "pg") {
      return "Pilihan Ganda";
    } else if (type == "mpg") {
      return "Multiple Pilihan Ganda";
    } else if (type == "bs") {
      return "Benar Salah";
    } else if (type == "ur") {
      return "Uraian";
    } else if (type == "pj") {
      return "Penjodohan";
    } else if (type == "sa") {
      return "Sebab Akibat";
    }
  };

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="col-12">
      <div className="card dz-card">
        <div className="card-header">
          <div className="card-title">Jawaban Siswa</div>
          <div className="card-toolbar d-flex">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {tab.map(
                (item, index) =>
                  dtJawaban?.[item]?.soal.length > 0 && (
                    <li className="nav-item" role="presentation" key={index}>
                      <button
                        className={`nav-link ${
                          activeTab == item ? "active" : ""
                        }`}
                        onClick={() => handleTabChange(item)}
                      >
                        {getTypeCode(item)}
                      </button>
                    </li>
                  )
              )}
            </ul>
            <BackButton className="ms-2" />
          </div>
        </div>
        <div className="card-body">
          <div className="tab-content" id="myTabContent">
            {tab.map(
              (item, index) =>
                dtJawaban?.[item]?.soal.length > 0 && (
                  <div
                    className={`tab-pane fade ${
                      activeTab == item ? "show active" : ""
                    }`}
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="table-responsive">
                      <table className="table table-bordered table-stripped table-hover">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Kelas</th>
                            <th>Rombel</th>
                            <th>Nama Peserta</th>
                            {dtJawaban[item].soal.map((soal, no) => (
                              <th key={`${no}`} className="">
                                <b
                                  className="text-decoration-underline"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => detailButirSoal(soal.id)}
                                >
                                  {soal.no_urut}
                                </b>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {dtJawaban[item].jawaban.map((peserta, no) => (
                            <tr>
                              <td>{no + 1}</td>
                              <td>{peserta.kelas}</td>
                              <td>{peserta.rombel}</td>
                              <td>{peserta.nama_siswa}</td>
                              {/* {dtJawaban[item].jawaban[no].response.map((jawaban, num) => (
                                <td>{jawaban.jawaban}</td>
                              ))} */}
                              {dtJawaban[item].jawaban[no].response.map(
                                (jawaban, num) => (
                                  <td key={num}>
                                    {item == "ur" &&
                                      jawaban.jawaban != null && (
                                        <Badge as="a" bg={"dark"} onClick={() => setDetailJawaban(jawaban.jawaban)} style={{ cursor: 'pointer' }}>
                                          <p className="text-decoration-underline mb-0 text-white">{jawaban.nilai}</p>
                                        </Badge>
                                      )}

                                    {item == "pj" &&
                                      jawaban.jawaban != null && (
                                        <Badge
                                          as="a"
                                          bg={
                                            jawaban.status == "benar"
                                              ? "success"
                                              : "danger"
                                          }
                                        >
                                          <p
                                            className="text-white text-jawaban text-truncate mb-0"
                                            dangerouslySetInnerHTML={{
                                              __html: jawaban.jawaban,
                                            }}
                                          />
                                        </Badge>
                                      )}

                                    {item != "pj" && item != "ur" && (
                                      <Badge
                                        as="a"
                                        bg={
                                          item == "UR" && jawaban.status == null
                                            ? "warning"
                                            : item == "UR" &&
                                              jawaban.status != null
                                            ? "success"
                                            : jawaban.jawaban == "ragu"
                                            ? "warning"
                                            : jawaban.status == "benar"
                                            ? "success"
                                            : "danger"
                                        }
                                      >
                                        {item == "UR" &&
                                        jawaban.status == null ? (
                                          <i className="fas fa-eye"></i>
                                        ) : item == "UR" &&
                                          jawaban.status != null ? (
                                          "Sudah dikoreksi"
                                        ) : (
                                          jawaban.jawaban
                                        )}
                                      </Badge>
                                    )}
                                    {/* {(item == "ur") && <i className="fa fa-pencil" aria-hidden="true" onClick={() => handleShowKoreksiModal(student.id, response)}></i>} */}
                                  </td>
                                )
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>

      <Modal
        dialogClassName="modal-lg"
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detail Butir Soal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dataButir != null ? (
            <>
              <h3>Informasi</h3>
              <div className="d-flex justify-content-between">
                <p>
                  <strong>Code : </strong> {dataButir.code}
                </p>
                <p>
                  <strong>Type : </strong> {dataButir.type}
                </p>
                <p>
                  <strong>Judul : </strong> {dataButir.detail.materi}
                </p>
              </div>

              {dataButir.type == "PG" ||
              dataButir.type == "BS" ||
              dataButir.type == "SA" ? (
                <>
                  <h3>Soal</h3>
                  <h5>
                    <strong>Pertanyaan : </strong>
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: dataButir.value.pertanyaan,
                    }}
                  ></p>

                  <h5>
                    <strong>Pilihan : </strong>
                  </h5>
                  <table class="">
                    {Object.entries(dataButir.value.pilihan).map(
                      ([key, value], index) => (
                        <tr
                          key={index}
                          className="d-flex justify-content-start align-items-start"
                        >
                          <td className="pe-2">{key.toUpperCase()}. </td>
                          <td className="pe-2">
                            <span dangerouslySetInnerHTML={{ __html: value }} />
                          </td>
                        </tr>
                      )
                    )}
                  </table>

                  <p>
                    <strong>Jawaban : </strong>{" "}
                    {dataButir.value.jawaban.toUpperCase()}
                  </p>
                </>
              ) : (
                ""
              )}
              {dataButir.type == "UR" ? (
                <>
                  <h3>Soal</h3>
                  <h5>
                    <strong>Pertanyaan : </strong>
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: dataButir.value.pertanyaan,
                    }}
                  ></p>
                  <h5>
                    <strong>Pembahasan : </strong>
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: dataButir.value.pembahasan,
                    }}
                  ></p>
                </>
              ) : (
                ""
              )}
              {dataButir.type == "PJ" ? (
                <>
                  <h3>Soal</h3>
                  <h5>
                    <strong>Pertanyaan : </strong>
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: dataButir.value.pertanyaan,
                    }}
                  ></p>
                  <h5>
                    <strong>Pasangan : </strong>
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: dataButir.value.pasangan,
                    }}
                  ></p>
                  <h5>
                    <strong>Pengecoh : </strong>
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: dataButir.value.pengecoh,
                    }}
                  ></p>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => setShowDetailModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={detailJawaban != null} onHide={() => setDetailJawaban(null)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Detail Jawaban</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            dangerouslySetInnerHTML={{
              __html: detailJawaban,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDetailJawaban(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default JawabanPeserta;
