import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import { closeLoading, showLoading } from "../../../customs/loading/loading";
import BackButton from "../../../customs/Button/BackButton";

const HasilUjianAnalisis = () => {
  const { id_ujian } = useParams();
  const [dataTopik, setDataTopik] = useState(null);
  const [dataStatistik, setDataStatistik] = useState(null);

  const navigate = useNavigate();

  const [ujianData, setUjianData] = useState(null);

  const detailUjian = async () => {
    var res = await axiosInstance.get(`ujian/${id_ujian}`);
    var data = res.data.data;
    setUjianData({
      kartu_soal_code: data.paket_soal.code,
      ujian_code: data.code,
      kelas: data.paket_soal.detail.kelas,
      pemilik: data.paket_soal.created_by,
      jurusan: data.paket_soal.detail.jurusan,
      jenjang: data.paket_soal.detail.jenjang,
      mata_pelajaran: data.paket_soal.detail.jenis_kelompok_mata_pelajaran,
      waktu_pengerjaan: data.started_at,
      rombel: data.detail.rombel,
      kkm: data.detail.kkm,
      topik: data.paket_soal.topik,
    });
    setDataStatistik({
      topik: data.paket_soal.topik,
      statistik: data.peserta,
    });
  };

  // use effect
  useEffect(() => {
    prepData();
  }, []);

  const prepData = async () => {
    showLoading();
    try {
      await detailUjian();
      await closeLoading();
    } catch (error) {
      await closeLoading();
    }
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Detail Ujian</h3>
          <div className="card-toolbar">
            <BackButton />
          </div>
        </div>
        <div className="card-body">
          <div className="col-11">
            {ujianData != null && (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="mb-1">
                    <b className="mb-0">ID Kartu Soal :</b>
                    <p className="mb-0">{ujianData.kartu_soal_code}</p>
                  </div>
                  <div className="mb-1">
                    <b className="mb-0">ID Paket Soal :</b>
                    <p className="mb-0">{"-"}</p>
                  </div>
                  <div className="mb-1">
                    <b className="mb-0">ID Daftar Ujian :</b>
                    <p className="mb-0">{ujianData.ujian_code}</p>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="mb-1">
                    <b className="mb-0">Jenjang :</b>
                    <p className="mb-0">{ujianData.jenjang}</p>
                  </div>
                  <div className="mb-1">
                    <b className="mb-0">Kelas :</b>
                    <p className="mb-0">{ujianData.kelas}</p>
                  </div>
                  <div className="mb-1">
                    <b className="mb-0">Mata Pelajaran :</b>
                    <p className="mb-0">{ujianData.mata_pelajaran}</p>
                  </div>
                </div>
                <div>
                  <div className="mb-1">
                    <b className="mb-0">Pemilik :</b>
                    <p className="mb-0">{ujianData.pemilik}</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header d-flex justify-content-between flex-wrap">
          <h3 className="card-title">Daftar Nilai dan Analisis Hasil Ujian</h3>
        </div>
        <div className="card-body table-responsive">
          {dataStatistik != null ? (
            <table className="table table-bordered table-stripped table-hover">
              <thead>
                <tr className="text-center">
                  <th rowSpan="2">No.</th>
                  {/* <th rowSpan="2">NISN</th> */}
                  <th rowSpan="2">Nama Siswa</th>
                  <th rowSpan="2">Skor Nilai</th>
                  <th rowSpan="2">Nbot</th>
                  <th rowSpan="2">Nbot%</th>
                  <th rowSpan="2">Rank</th>
                  <th rowSpan="2">KKM</th>
                  <th
                    colSpan={
                      dataStatistik.topik
                        ? Object.entries(dataStatistik.topik).length
                        : 1
                    }
                  >
                    Skor Nilai Per Topik
                  </th>
                  <th rowSpan="2">Skor Nilai</th>
                  <th
                    colSpan={
                      dataStatistik.topik
                        ? Object.entries(dataStatistik.topik).length
                        : 1
                    }
                  >
                    Nilai Bobot per Topik
                  </th>
                  <th rowSpan="2">Nilai Bobot</th>
                  <th
                    colSpan={
                      dataStatistik.topik
                        ? Object.entries(dataStatistik.topik).length
                        : 1
                    }
                  >
                    Skor Nilai per topik dalam %
                  </th>
                  <th rowSpan="2">Nbot %</th>
                  <th
                    colSpan={
                      dataStatistik.topik
                        ? Object.entries(dataStatistik.topik).length
                        : 1
                    }
                  >
                    Capain nilai per topik dalam %
                  </th>
                  <th
                    colSpan={
                      dataStatistik.topik
                        ? Object.entries(dataStatistik.topik).length
                        : 1
                    }
                  >
                    Katagori mencapai KKM per Topik
                  </th>
                </tr>
                <tr>
                  {dataStatistik.topik &&
                  Object.entries(dataStatistik.topik).length > 0 ? (
                    Object.entries(dataStatistik.topik).map(
                      ([key, value], index) => <th>{value.code}</th>
                    )
                  ) : (
                    <th>Tidak ada topik</th>
                  )}
                  {dataStatistik.topik &&
                  Object.entries(dataStatistik.topik).length > 0 ? (
                    Object.entries(dataStatistik.topik).map(
                      ([key, value], index) => <th>{value.code}</th>
                    )
                  ) : (
                    <th>Tidak ada topik</th>
                  )}
                  {dataStatistik.topik &&
                  Object.entries(dataStatistik.topik).length > 0 ? (
                    Object.entries(dataStatistik.topik).map(
                      ([key, value], index) => <th>{value.code}</th>
                    )
                  ) : (
                    <th>Tidak ada topik</th>
                  )}
                  {dataStatistik.topik &&
                  Object.entries(dataStatistik.topik).length > 0 ? (
                    Object.entries(dataStatistik.topik).map(
                      ([key, value], index) => <th>{value.code}</th>
                    )
                  ) : (
                    <th>Tidak ada topik</th>
                  )}
                  {dataStatistik.topik &&
                  Object.entries(dataStatistik.topik).length > 0 ? (
                    Object.entries(dataStatistik.topik).map(
                      ([key, value], index) => <th>{value.code}</th>
                    )
                  ) : (
                    <th>Tidak ada topik</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {dataStatistik.statistik.map((value, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{value.user.name}</td>
                    <td>
                      {Object.entries(value.score_topik).length > 0 ? (
                        <span>
                          {Object.entries(value.score_topik).reduce((total, [_, row]) => total + row.nilai, 0).toFixed(2)}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td>{value.nilai_bobot}</td>
                    <td>{value.presentase_nilai_bobot}</td>
                    <td>{value.ranking}</td>
                    <td>{ujianData.kkm}</td>
                    {Object.entries(value.score_topik).length > 0 ? (
                      Object.entries(value.score_topik).map(
                        ([key1, row], index) => <td key={key1}>{row.nilai}</td>
                      )
                    ) : (
                      <td>Tidak ada topik</td>
                    )}
                    <td>
                      {Object.entries(value.score_topik).length > 0 ? (
                        <span>
                          {Object.entries(value.score_topik)
                            .reduce((total, [_, row]) => total + row.nilai, 0)
                            .toFixed(2)}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    {Object.entries(value.score_topik).length > 0 ? (
                      Object.entries(value.score_topik).map(
                        ([key1, row], index) => (
                          <td key={key1}>{row.nilai_bobot}</td>
                        )
                      )
                    ) : (
                      <td>Tidak ada topik</td>
                    )}
                    <td>
                      {Object.entries(value.score_topik).length > 0 ? (
                        <span>
                          {Object.entries(value.score_topik)
                            .reduce(
                              (total, [_, row]) => total + row.nilai_bobot,
                              0
                            )
                            .toFixed(2)}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    {Object.entries(value.score_topik).length > 0 ? (
                      Object.entries(value.score_topik).map(
                        ([key1, row], index) => (
                          <td key={key1}>{row.presentase_nilai_bobot}</td>
                        )
                      )
                    ) : (
                      <td>Tidak ada topik</td>
                    )}
                    <td>
                      {Object.entries(value.score_topik).length > 0 ? (
                        <span>
                          {Object.entries(value.score_topik)
                            .reduce(
                              (total, [_, row]) =>
                                total + row.presentase_nilai_bobot,
                              0
                            )
                            .toFixed(2)}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    {Object.entries(value.score_topik).length > 0 ? (
                      Object.entries(value.score_topik).map(
                        ([key1, row], index) => (
                          <td key={key1}>{row.capaian_nilai}</td>
                        )
                      )
                    ) : (
                      <td>Tidak ada topik</td>
                    )}
                    {Object.entries(value.score_topik).length > 0 ? (
                      Object.entries(value.score_topik).map(
                        ([key1, row], index) => (
                          <td key={key1}>{row.is_pass_kkm ? "OK" : "Tidak"}</td>
                        )
                      )
                    ) : (
                      <td>Tidak ada topik</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="col-12 text-center">
              <p>Tidak ada data</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HasilUjianAnalisis;
