import React, { useRef, useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import Select from "react-select";
import Swal from "sweetalert2";
import StepOne from "../../Forms/Wizard/StepOne";
import StepTwo from "../../Forms/Wizard/StepTwo";
import StepThree from "../../Forms/Wizard/StepThree";
import StepFour from "../../Forms/Wizard/StepFour";
import PilihPaketSoal from "../Steps/PilihPaketSoal";
import PilihPesertaUjian from "../Steps/PilihPesertaUjian";
import SettingUjian from "../Steps/SettingUjian";
import Preview from "../Steps/Preview";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isAuthenticated } from "../../../../store/selectors/AuthSelectors";
import { connect } from "react-redux";
import axiosInstance from "../../../../services/AxiosInstance";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const CreateUjianTestOnline = (props) => {
  const navigate = useNavigate();
  const { typeujian } = useParams();

  const paketSoalRef = useRef();
  const pesertaRef = useRef();
  const settingRef = useRef();

  const [goSteps, setGoSteps] = useState(0);

  const [paketSoal, setPaketSoal] = useState({
    title: null,
    selectedPackage: null,
    description: null
  });

  const [pesertaUjian, setPesertaUjian] = useState({
    tahun_ajaran: null,
    jenjang: null,
    kelas: null,
    rombel: {},
    rombel_member: []});

  const [settingUjian, setSettingUjian] = useState({
    started_at: null,
    ruangan: null});

  const onPaketSoal = (e) => {
    console.log(e);
  };

  const onPesertaUjian = (e) => {
    console.log(e);
  };

  const onSettingUjian = (e) => {
    console.log(e);
  };

  const handleNext = async (to) => {
    if (goSteps == 0) {
      var res = await paketSoalRef.current.getData();
      
      if(res == null){
        return;
      }else{
        setPaketSoal(res);
      }
    }

    if (goSteps == 1) {
      var res = await pesertaRef.current.getData();
      console.log("res : ", res);
      
      if(res == null){
        return;
      }else{
        setPesertaUjian(res);
      }
    }

    if (goSteps == 2) {
      var res = await settingRef.current.getData();
      console.log("res settingRef : ", res);
      
      if(res == null){
        return;
      }else{
        setSettingUjian(res);
      }
    }

    setGoSteps(to);
  };

  const handleSave = async () => {
    var pesertaEmailList = []
    for (var i = 0; i < pesertaUjian.rombel_member.length; i++) {
      pesertaEmailList.push(pesertaUjian.rombel_member[i].value);
      // pesertaEmailList.push(pesertaUjian.rombel_member[i].value);
    }
    var date = new Date(settingUjian.started_at)
    var params = {
      "type": typeujian,
      "paket_soal_id": paketSoal.selectedPackage.id,
      "detail": {
          "kkm": paketSoal.kkm,
          "nama": paketSoal.title,
          "deskripsi": paketSoal.description,
          "ruangan": settingUjian.ruangan,
          "kurikulum": pesertaUjian.kurikulum,
          "rombel": pesertaUjian.rombel.label,
          "kelas": pesertaUjian.kelas.value,
          "tahun_ajaran": pesertaUjian.tahun_ajaran.value,
          "wali_kelas": pesertaUjian.rombel.value.wali_kelas
      },
      "peserta": pesertaEmailList,
      "started_at": date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2) + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":" + ("0" + date.getSeconds()).slice(-2)
    }


    var res = await axiosInstance.post("ujian", params);
    if (res) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Buat Ujian Berhasil',
      })
      navigate('/daftar-ujian/test-online');
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Oops !',
        text: 'Gagal membuat ujian',
      })
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          {goSteps == 3 ? (
            <div className="card">
              <div className="card-header card-header-komposisi d-flex justify-content-between flex-wrap">
                <div>
                  <div className="card-title">Komposisi Soal</div>
                </div>
                <div>
                  <span>
                    <i className={`fas fa-chevron-down`}></i>
                  </span>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-4">
                    <table className="w-100 nm">
                      <tr>
                        <td className="p-2 text-dark">
                          <b>Nama Ujian</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {paketSoal.title}</b>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2 text-dark">
                          <b>Deskripsi</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {paketSoal.description}</b>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2 text-dark">
                          <b>Kode Paket Soal</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {paketSoal.selectedPackage.kode_paket}</b>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2 text-dark">
                          <b>Tahun Ajaran</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {pesertaUjian.tahun_ajaran.value}</b>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2 text-dark">
                          <b>Jenjang</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {pesertaUjian.jenjang.value}</b>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2 text-dark">
                          <b>Kelas</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {pesertaUjian.kelas.value}</b>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2 text-dark">
                          <b>Rombel</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {pesertaUjian.rombel.label}</b>
                        </td>
                      </tr>
                      
                      <tr>
                        <td className="p-2 text-dark">
                          <b>Platform</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: Test Online</b>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h4 className="mt-2 mb-0">
                {goSteps == 0
                  ? "Pilih Paket Soal"
                  : goSteps == 1
                  ? "Pilih Peserta Ujian"
                  : goSteps == 2
                  ? "Setting Ujian"
                  : goSteps == 3
                  ? "Preview"
                  : ""}
              </h4>
            </div>
            <div className="card-body">
              <div className="form-wizard ">
                <Stepper
                  className="nav-wizard"
                  activeStep={goSteps}
                  label={false}
                >
                  <Step className="nav-link" onClick={() => setGoSteps(0)} />
                  <Step className="nav-link" onClick={() => setGoSteps(1)} />
                  <Step className="nav-link" onClick={() => setGoSteps(2)} />
                  <Step className="nav-link" onClick={() => setGoSteps(3)} />
                </Stepper>
                {goSteps === 0 ? (
                  <>
                    <PilihPaketSoal ref={paketSoalRef} defaultData={paketSoal} onPaketSoal={onPaketSoal} />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-primary sw-btn-next"
                        onClick={() => handleNext(1)}
                      >
                        Next
                      </button>
                    </div>
                  </>
                ) : ""}
                {goSteps === 1 && (
                  <>
                    <PilihPesertaUjian ref={pesertaRef} defaultData={pesertaUjian} onPesertaUjian={onPesertaUjian} />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(0)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => handleNext(2)}
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
                {goSteps === 2 && (
                  <>
                    <SettingUjian ref={settingRef} defaultData={settingUjian} onSettingUjian={onSettingUjian} />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(1)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => handleNext(3)}
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
                {goSteps === 3 && (
                  <>
                    <Preview paketSoal={paketSoal} pesertaUjian={pesertaUjian} settingUjian={settingUjian}  />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(2)}
                      >
                        Prev
                      </button>
                      <button className="btn btn-primary sw-btn-next ms-1" onClick={() => handleSave()}>
                        Simpan
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps create ujian test online', state);
  return {
    isAuthenticated: isAuthenticated(state),
    user: state.auth.auth,
  };
};

export default withRouter(connect(mapStateToProps)(CreateUjianTestOnline));
