import React, { useEffect, useRef, useState } from "react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import axiosInstance from "../../../services/AxiosInstance";

const Filter = (props) => {
  const [query, setQuery] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [jenjang, setJenjang] = useState([]);
  const [jurusan, setJurusan] = useState([]);
  const [kelas, setKelas] = useState([]);
  const [semester, setSemester] = useState([]);
  const [kurikulum, setKurikulum] = useState([]);
  const [kelMapel, setKelMapel] = useState([]);
  const [jenMapel, setJenMapel] = useState([]);
  const [mapel, setMapel] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  const [selKurikulum, setSelKurikulum] = useState(null);
  const [selJenjang, setSelJenjang] = useState(null);
  const [selKelas, setSelKelas] = useState(null);
  const [selKelMapel, setSelKelMapel] = useState(null);
  const [selJenMapel, setSelJenMapel] = useState(null);
  const [selMapel, setSelMapel] = useState(null);

  useEffect(() => {
    prepData();
  }, []);

  const prepData = async () => {
    await loadKurikulum();
    await loadJenjang();
    await fetchLocalStorage();
  };

  const fetchLocalStorage = async () => {
    const locQuery = JSON.parse(localStorage.getItem("filterQuery") || "{}");
    setQuery(locQuery);

    if (locQuery?.kurikulum) setSelKurikulum(locQuery.kurikulum);
    if (locQuery?.jenjang) {
      setSelJenjang(locQuery.jenjang);
      loadKelas(locQuery.jenjang.value);
      loadJurusan(locQuery.jenjang.value);
      loadKelompokMapel(locQuery.jenjang.id, locQuery.kurikulum.value);
    }
    if (locQuery?.kelas) {
      setSelKelas(locQuery.kelas);
    }
    if (locQuery?.kelompok_mata_pelajaran) {
      setSelKelMapel(locQuery.kelompok_mata_pelajaran);
      loadJenisKelompokMapel(locQuery.kelompok_mata_pelajaran.value, locQuery.kurikulum.value);
    }
    if (locQuery?.jenis_kelompok_mata_pelajaran) {
      setSelJenMapel(locQuery.jenis_kelompok_mata_pelajaran);
      loadMapel(locQuery.jenis_kelompok_mata_pelajaran.value, locQuery.kurikulum.value);
    }
    if (locQuery?.mata_pelajaran) {
      setSelMapel(locQuery.mata_pelajaran);
    }
  };

  const loadKurikulum = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await axiosInstance.get("/select/kurikulum");
        var tmp = [];
        var data = response.data.data;
        for (var i = 0; i < data.length; i++) {
          tmp.push({ value: data[i].value, label: data[i].label });
        }
        setKurikulum(tmp);
        resolve(true);
      } catch (error) {
        reject();
      }
    });
  };

  const loadJenjang = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await axiosInstance.get("select/jenjang");
        if (response.data) {
          var tmp = [];
          var data = response.data.data;
          for (var i = 0; i < data.length; i++) {
            tmp.push({
              id: data[i].id,
              value: data[i].jenjang,
              label: data[i].jenjang,
            });
          }
          setJenjang(tmp);
        }
        resolve(true);
      } catch (error) {
        reject();
      }
    });
  };

  const loadKelas = async (jenjang) => {
    var response = await axiosInstance.get("/select/kelas?jenjang=" + jenjang);
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].text });
      }
      setKelas(tmp);
    }
  };

  const loadJurusan = async (jenjang) => {
    var response = await axiosInstance.get(
      "/select/jurusan?jenjang=" + jenjang
    );
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].text });
      }
      setJurusan(tmp);
    }
  };

  const loadKelompokMapel = async (jenjang, krm = null) => {
    var response = await axiosInstance.get(
      "select/kelompok-mata-pelajaran?jenjang_id=" +
        jenjang +
        "&kurikulum=" +
        ((krm == null) ? selKurikulum.value : krm)
    );
    if (response.data) {
      var tmp = [];
      var data = response.data.data;

      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setKelMapel(tmp);
    }
  };

  const loadJenisKelompokMapel = async (kelompok, krm = null) => {
    var response = await axiosInstance.get(
      "select/jenis-kelompok-mata-pelajaran?kelompok_mata_pelajaran_id=" +
        kelompok +
        "&kurikulum=" +
        ((krm == null) ? selKurikulum.value : krm)
    );
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setJenMapel(tmp);
    }
  };

  const loadMapel = async (jenis, krm = null) => {
    var response = await axiosInstance.get(
      "select/mata-pelajaran?jenis_kelompok_mata_pelajaran_id=" +
        jenis +
        "&kurikulum=" +
        ((krm == null) ? selKurikulum.value : krm)
    );
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setMapel(tmp);
    }
  };

  const handleFormChange = async (value, type) => {
    const updatedQuery = {
      kurikulum: selKurikulum?.value ?? null,
      jenjang: selJenjang?.value.split("/")[0] ?? null,
      kelas: selKelas?.value ?? null,
      kelompok_mata_pelajaran_id: selKelMapel?.value ?? null,
      jenis_kelompok_mata_pelajaran_id: selJenMapel?.value ?? null,
      mata_pelajaran: selMapel?.value ?? null,
    };

    switch (type) {
      case "kurikulum":
        setSelKurikulum(value);
        break;
      case "jenjang":
        updatedQuery.jenjang = value?.value.split("/")[0] ?? null;
        setSelJenjang(value);
        if (value != null) {
          loadKelas(value?.value);
          loadJurusan(value?.value);
          loadKelompokMapel(value.id);
        }
        break;
      case "kelas":
        setSelKelas(value);
        break;
      case "kelompok":
        setSelKelMapel(value);
        if (value) {
          loadJenisKelompokMapel(value?.value);
        }
        break;
      case "jenis":
        setSelJenMapel(value);
        if (value) {
          loadMapel(value?.value ?? null);
        }
        break;
      case "mapel":
        setSelMapel(value);
        break;
      default:
        console.warn("Unknown type:", type);
    }
  };

  const resetFilter = () => {
    const updatedQuery = {
      kurikulum: null,
      jenjang: null,
      kelas: null,
      kelompok_mata_pelajaran_id: null,
      jenis_kelompok_mata_pelajaran_id: null,
      mata_pelajaran: null,
    };

    setSelKurikulum(null);
    setSelJenjang(null);
    setSelKelas(null);
    setSelKelMapel(null);
    setSelJenMapel(null);
    setSelMapel(null);
    setQuery(null);
    props.onChangeForm(updatedQuery);
    setIsFiltered(false);
  };

  const setFilter = async () => {
    const updatedQuery = {
      kurikulum: selKurikulum?.value ?? null,
      jenjang: selJenjang?.value.split("/")[0] ?? null,
      kelas: selKelas?.value ?? null,
      kelompok_mata_pelajaran_id: selKelMapel?.value ?? null,
      jenis_kelompok_mata_pelajaran_id: selJenMapel?.value ?? null,
      mata_pelajaran: selMapel?.value ?? null,
    };

    const filterQuery = {
      kurikulum: selKurikulum,
      jenjang: selJenjang,
      kelas: selKelas,
      kelompok_mata_pelajaran: selKelMapel,
      jenis_kelompok_mata_pelajaran: selJenMapel,
      mata_pelajaran: selMapel,
    };
    
    const cleanedQuery = Object.fromEntries(
      Object.entries(filterQuery).filter(([_, v]) => v != null)
    );
    
    localStorage.setItem("filterQuery", JSON.stringify(cleanedQuery));
    if (Object.keys(cleanedQuery).length > 0)
      setIsFiltered(true);
      
    setQuery(updatedQuery);
    props.onChangeForm(updatedQuery);
  };

  return (
    <>
      <div style={{ marginRight: "8px" }}>
        <div
          onClick={() => setShowFilter(!showFilter)}
          className={`btn btn-sm btn-icon ${
            isFiltered ? "btn-primary" : "btn-outline btn-outline-primary"
          }`}
          style={{ maxWidth: "200px", cursor: "pointer" }}
        >
          <div style={{ position: "relative", display: "inline-block" }}>
            <h4 className={`mb-0 ${isFiltered ? "text-light" : ""}`}>
              <i className="fas fa-filter"></i>
            </h4>
            {isFiltered && (
              <span
                style={{
                  position: "absolute",
                  top: "-15px",
                  right: "-20px",
                  background: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "0px 6px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                !
              </span>
            )}
          </div>
        </div>
      </div>
      {showFilter ? (
        <div
          className="card border shadow-sm position-absolute right-0 w-80 w-lg-40"
          style={{
            minHeight: "fit-content",
            right: "0",
            top: "80%",
            zIndex: 20,
          }}
        >
          <div className="card-body">
            <div className="form-group mt-3">
              <h5 className="text-primary">
                Kurikulum <span className="text-danger">*</span>
              </h5>
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={selKurikulum != null ? selKurikulum : null}
                isDisabled={kurikulum.length > 0 ? false : true}
                isRtl={false}
                name="color"
                options={kurikulum}
                isClearable={true}
                onChange={(e) => handleFormChange(e, "kurikulum")}
              />
            </div>

            <div className="form-group mt-3">
              <h5 className="text-primary">
                Jenjang <span className="text-danger">*</span>
              </h5>
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={selJenjang != null ? selJenjang : null}
                isDisabled={
                  jenjang.length > 0 && selKurikulum != null ? false : true
                }
                isRtl={false}
                name="color"
                options={jenjang}
                isClearable={true}
                onChange={(e) => handleFormChange(e, "jenjang")}
              />
            </div>

            <div className="form-group mt-3">
              <h5 className="text-primary">
                Kelompok Mata Pelajaran <span className="text-danger">*</span>
              </h5>
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={selKelMapel != null ? selKelMapel : null}
                isDisabled={
                  kelMapel.length > 0 && selJenjang != null ? false : true
                }
                isRtl={false}
                name="color"
                options={kelMapel}
                isClearable={true}
                onChange={(e) => handleFormChange(e, "kelompok")}
              />
            </div>

            <div className="form-group mt-3">
              <h5 className="text-primary">
                Jenis Kelompok Mata Pelajaran{" "}
                <span className="text-danger">*</span>
              </h5>
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={selJenMapel != null ? selJenMapel : null}
                isDisabled={
                  jenMapel.length > 0 && selKelMapel != null ? false : true
                }
                isRtl={false}
                name="color"
                options={jenMapel}
                isClearable={true}
                onChange={(e) => handleFormChange(e, "jenis")}
              />
            </div>

            <div className="form-group mt-3">
              <h5 className="text-primary">
                Mata Pelajaran <span className="text-danger">*</span>
              </h5>
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={selMapel != null ? selMapel : null}
                isDisabled={
                  mapel.length > 0 && selJenMapel != null ? false : true
                }
                isRtl={false}
                name="color"
                options={mapel}
                isClearable={true}
                onChange={(e) => handleFormChange(e, "mapel")}
              />
            </div>

            <div className="form-group mt-3">
              <h5 className="text-primary">
                Kelas <span className="text-danger">*</span>
              </h5>
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={selKelas != null ? selKelas : null}
                isDisabled={kelas.length > 0 && selMapel != null ? false : true}
                isRtl={false}
                name="color"
                options={kelas}
                isClearable={true}
                onChange={(e) => handleFormChange(e, "kelas")}
              />
            </div>

            <div className="form-group  text-end mt-3">
              <button onClick={resetFilter} className="btn btn-danger btn-sm">
                <i className="fa fa-refresh me-1"></i> Set Filter
              </button>
              <button
                onClick={setFilter}
                className="btn btn-primary btn-sm ms-2"
              >
                <i className="fa fa-filter me-1"></i> Cari
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const FilterKartuSoal = (props) => {
  const [query, setQuery] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [jenisSoal, setJenisSoal] = useState(props.jenisSoal);
  const [isFiltered, setIsFiltered] = useState(false);

  const [selJenisSoal, setSelJenisSoal] = useState(null);

  useEffect(() => {}, []);

  const handleFormChange = async (value, type) => {
    switch (type) {
      case "jenis":
        setSelJenisSoal(value);
        break;
      default:
        console.warn("Unknown type:", type);
    }
  };

  const resetFilter = () => {
    const updatedQuery = {
      type: null,
    };

    setSelJenisSoal(null);
    setQuery(null);
    props.onChangeForm(updatedQuery);
    setIsFiltered(false);
  };

  const setFilter = () => {
    let jn = null;
    if (selJenisSoal != null)
      jn = selJenisSoal.map((option) => option.value).join(",");

    const updatedQuery = {
      type: jn ?? null,
    };

    setIsFiltered(true);
    setQuery(updatedQuery);
    props.onChangeForm(updatedQuery);
  };

  return (
    <>
      <div style={{ marginRight: "8px" }}>
        <div
          onClick={() => setShowFilter(!showFilter)}
          className={`btn btn-sm btn-icon ${
            isFiltered ? "btn-primary" : "btn-outline btn-outline-primary"
          }`}
          style={{ maxWidth: "200px", cursor: "pointer" }}
        >
          <div style={{ position: "relative", display: "inline-block" }}>
            <h4 className={`mb-0 ${isFiltered ? "text-light" : ""}`}>
              <i className="fas fa-filter"></i>
            </h4>
            {isFiltered && (
              <span
                style={{
                  position: "absolute",
                  top: "-15px",
                  right: "-20px",
                  background: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "0px 6px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                !
              </span>
            )}
          </div>
        </div>
      </div>
      {showFilter ? (
        <div
          className="card border shadow-sm position-absolute right-0 w-80 w-lg-40"
          style={{
            minHeight: "max-content",
            right: "0",
            top: "80%",
            zIndex: 20,
          }}
        >
          <div className="card-body">
            <div className="form-group mt-3">
              <h5 className="text-primary">Jenis Soal</h5>
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={selJenisSoal != null ? selJenisSoal : null}
                isDisabled={jenisSoal.length > 0 ? false : true}
                isRtl={false}
                isMulti={true}
                name="color"
                options={jenisSoal}
                isClearable={true}
                onChange={(e) => handleFormChange(e, "jenis")}
              />
            </div>

            <div className="form-group text-end mt-3">
              <button onClick={resetFilter} className="btn btn-danger btn-sm">
                <i className="fa fa-refresh me-1"></i> Reset Filter
              </button>
              <button
                onClick={setFilter}
                className="btn btn-primary btn-sm ms-2"
              >
                <i className="fa fa-filter me-1"></i> Cari
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export { FilterKartuSoal };
export default Filter;
