import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import axiosInstance from "../../../../services/AxiosInstance";
import Pagination from "../../Pagination";
import { useCart } from "../../../../services/CartContext";

const getColorJenisSoal = (type) => {
  switch (type) {
    case "PG":
      return "primary";
    case "SA":
      return "warning";
    case "BS":
      return "success";
    case "UR":
      return "danger";
    case "PJ":
      return "info";
    default:
      return "primary";
  }
};

const TablePaginate = (props) => {
  const inputEl = useRef(null);
  const { cart, dispatch } = useCart();

  const onPaging = (page) => {
    props.onPaging(page);
  };

  const handleAddToCart = (product) => {
    dispatch({ type: "ADD_TO_CART", payload: { ...product, quantity: 1 } });
  };

  const isInCart = (productId) => {
    return cart.some((item) => item.id === productId);
  };

  const fetchGetId = (id) => {
    props.onDetail(id);
  }

  return (
    <div className="col-xl-12">
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            {props.data.length > 0 ? (
              <div id="job_data" className="dataTables_wrapper">
                {props.type == "kartu-soal" && (
                  <table
                    className="display w-100 dataTable table-bordered"
                    id="example5"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting_asc">No</th>
                        <th className="sorting">ID Kartu Soal</th>
                        <th className="sorting">Judul</th>
                        <th className="sorting">Jenis</th>
                        <th className="sorting">Pembuat</th>
                        <th className="sorting">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.data.map((v, i) => (
                        <tr keys={i} className={(i + 1) % 2 == 1 ? "odd" : "even"}>
                          <td>{i + 1}</td>
                          <td>{v.code}</td>
                          <td>{v.judul}</td>
                          <td>{v.type}</td>
                          <td>{v.created_by}</td>
                          <td className="d-flex">
                            <div
                              className="btn-group btn-group-sm"
                              role="group"
                              aria-label="Basic example"
                            >
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => fetchGetId(v.id)}
                              >
                                <i className="fas fa-eye"></i>
                              </button>
                              <button
                                className="btn btn-primary btn-sm btn-icon"
                                onClick={() => handleAddToCart(v)}
                                disabled={isInCart(v.id)}
                              >
                                <i className="fa fa-shopping-cart me-1"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {props.type == "paket-soal" && (
                  <table
                    className="display w-100 dataTable table-bordered"
                    id="example5"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting_asc">No</th>
                        <th className="sorting">ID Kartu Soal</th>
                        <th className="sorting">Judul</th>
                        <th className="sorting">Jenis</th>
                        <th className="sorting">Kelas</th>
                        <th className="sorting">Mapel</th>
                        <th className="sorting">Perangkai</th>
                        <th className="sorting">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.data.map((v, i) => (
                        <tr className={(i + 1) % 2 == 1 ? "odd" : "even"}>
                          <td>{i + 1}</td>
                          <td>{v.code}</td>
                          <td>{v.judul}</td>
                          <td>
                            {Object.entries(v.data.score).map(([key, value]) =>
                              value.jumlah_soal >= 1 ? (
                                <span
                                  key={key}
                                  className={`badge mx-1 badge-sm badge-${getColorJenisSoal(
                                    key
                                  )}`}
                                >
                                  {key}
                                </span>
                              ) : null
                            )}
                          </td>
                          <td>{v.data.detail.kelas}</td>
                          <td>{v.data.detail.mata_pelajaran}</td>
                          <td>{v.created_by}</td>
                          <td className="d-flex">
                            <div
                              className="btn-group btn-group-sm"
                              role="group"
                              aria-label="Basic example"
                            >
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => fetchGetId(v.id)}
                              >
                                <i className="fas fa-eye"></i>
                              </button>
                              <button
                                className="btn btn-primary btn-sm btn-icon"
                                onClick={() => handleAddToCart(v)}
                                disabled={isInCart(v.id)}
                              >
                                <i className="fa fa-shopping-cart me-1"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {props.type === "tryout" && (
                  <table
                    className="display w-100 dataTable table-bordered"
                    id="example5"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting_asc">No</th>
                        <th className="sorting">ID Tryout</th>
                        <th className="sorting">Judul</th>
                        <th className="sorting">Jenis</th>
                        <th className="sorting">Jenjang</th>
                        <th className="sorting">Kelas</th>
                        <th className="sorting">Jurusan</th>
                        <th className="sorting">Mata Pelajaran</th>
                        <th className="sorting">Pembuat</th>
                        <th className="sorting">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.data.map((v, i) => (
                        <tr className={(i + 1) % 2 == 1 ? "odd" : "even"}>
                          <td>{i + 1}</td>
                          <td>{v.code}</td>
                          <td>{v.judul}</td>
                          <td>
                            {Object.entries(v.data.score).map(([key, value]) =>
                              value.jumlah_soal >= 1 ? (
                                <span
                                  key={key}
                                  className={`badge mx-1 badge-sm badge-${getColorJenisSoal(
                                    key
                                  )}`}
                                >
                                  {key}
                                </span>
                              ) : null
                            )}
                          </td>
                          <td>{v.data.detail.jenjang}</td>
                          <td>{v.data.detail.kelas}</td>
                          <td>{v.data.detail.jurusan}</td>
                          <td>{v.data.detail.mata_pelajaran}</td>
                          <td>{v.created_by}</td>
                          <td className="d-flex">
                            <div
                              className="btn-group btn-group-sm"
                              role="group"
                              aria-label="Basic example"
                            >
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => fetchGetId(v.id)}
                              >
                                <i className="fas fa-eye"></i>
                              </button>
                              <button
                                className="btn btn-primary btn-sm btn-icon"
                                onClick={() => handleAddToCart(v)}
                                disabled={isInCart(v.id)}
                              >
                                <i className="fa fa-shopping-cart me-1"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {props.type === "bahan-ajar" && (
                  <table
                    className="display w-100 dataTable table-bordered"
                    id="example5"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting_asc">No</th>
                        <th className="sorting">ID Bahan Ajar</th>
                        <th className="sorting">Jenjang</th>
                        <th className="sorting">Kelas</th>
                        <th className="sorting">Jurusan</th>
                        <th className="sorting">Mata Pelajaran</th>
                        <th className="sorting">Topik</th>
                        <th className="sorting">Level</th>
                        <th className="sorting">Bentuk</th>
                        <th className="sorting">Pembuat</th>
                        <th className="sorting">Scoin</th>
                        <th className="sorting">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.data.map((v, i) => (
                        <tr className={(i + 1) % 2 == 1 ? "odd" : "even"}>
                          <td>{i + 1}</td>
                          <td>{v.data.id_bahan_ajar}</td>
                          <td>{v.data.detail.jenjang}</td>
                          <td>{v.data.detail.kelas}</td>
                          <td>{v.data.detail.jurusan}</td>
                          <td>{v.data.detail.mata_pelajaran}</td>
                          <td>{v.data.topic}</td>
                          <td>{v.data.level}</td>
                          <td>{v.data.media}</td>
                          <td>{v.created_by}</td>
                          <td>{v.price}</td>
                          <td className="d-flex">
                            <div
                              className="btn-group btn-group-sm"
                              role="group"
                              aria-label="Basic example"
                            >
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => fetchGetId(v.id)}
                              >
                                <i className="fas fa-eye"></i>
                              </button>
                              <button
                                className="btn btn-primary btn-sm btn-icon"
                                onClick={() => handleAddToCart(v)}
                                disabled={isInCart(v.id)}
                              >
                                <i className="fa fa-shopping-cart me-1"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {props.type === "paket-ajar" && (
                  <table
                    className="display w-100 dataTable table-bordered"
                    id="example5"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting_asc">No</th>
                        <th className="sorting">ID Paket Ajar</th>
                        <th className="sorting">Jenjang</th>
                        <th className="sorting">Kelas</th>
                        <th className="sorting">Jurusan</th>
                        <th className="sorting">Mata Pelajaran</th>
                        <th className="sorting">Topik</th>
                        <th className="sorting">Pembuat</th>
                        <th className="sorting">Scoin</th>
                        <th className="sorting">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.data.map((v, i) => (
                        <tr className={(i + 1) % 2 == 1 ? "odd" : "even"}>
                          <td>{i + 1}</td>
                          <td>{v.data.id_paket_ajar}</td>
                          <td>{v.data.detail.jenjang}</td>
                          <td>{v.data.detail.kelas}</td>
                          <td>{v.data.detail.jurusan}</td>
                          <td>{v.data.detail.mata_pelajaran}</td>
                          <td>{v.data.topic}</td>
                          <td>{v.created_by}</td>
                          <td>{v.price}</td>
                          <td className="d-flex">
                            <div
                              className="btn-group btn-group-sm"
                              role="group"
                              aria-label="Basic example"
                            >
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => fetchGetId(v.id)}
                              >
                                <i className="fas fa-eye"></i>
                              </button>
                              <button
                                className="btn btn-primary btn-sm btn-icon"
                                onClick={() => handleAddToCart(v)}
                                disabled={isInCart(v.id)}
                              >
                                <i className="fa fa-shopping-cart me-1"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {props.type === "pembelajaran" && (
                  <table
                    className="display w-100 dataTable table-bordered"
                    id="example5"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting_asc">No</th>
                        <th className="sorting">ID Pembelajaran</th>
                        <th className="sorting">Judul</th>
                        <th className="sorting">Jenjang</th>
                        <th className="sorting">Kelas</th>
                        <th className="sorting">Jurusan</th>
                        <th className="sorting">Mata Pelajaran</th>
                        <th className="sorting">Pembuat</th>
                        <th className="sorting">Scoin</th>
                        <th className="sorting">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.data.map((v, i) => (
                        <tr className={(i + 1) % 2 == 1 ? "odd" : "even"}>
                          <td>{i + 1}</td>
                          <td>{v.code}</td>
                          <td>{v.judul}</td>
                          <td>{v.data.detail.jenjang}</td>
                          <td>{v.data.detail.kelas}</td>
                          <td>{v.data.detail.jurusan}</td>
                          <td>{v.data.detail.mata_pelajaran}</td>
                          <td>{v.created_by}</td>
                          <td>{v.price}</td>
                          <td className="d-flex">
                            <div
                              className="btn-group btn-group-sm"
                              role="group"
                              aria-label="Basic example"
                            >
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => fetchGetId(v.id)}
                              >
                                <i className="fas fa-eye"></i>
                              </button>
                              <button
                                className="btn btn-primary btn-sm btn-icon"
                                onClick={() => handleAddToCart(v)}
                                disabled={isInCart(v.id)}
                              >
                                <i className="fa fa-shopping-cart me-1"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            ) : (
              <div className="col-12 text-center my-4">
                <p>Tidak ada data</p>
              </div>
            )}
          </div>
          {props.data.length > 0 && (
            <Pagination
              totalData={props.total}
              dataPerPage={props.perPage}
              onChange={onPaging}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TablePaginate;
