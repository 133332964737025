import React, { useState, useEffect, useRef, useContext } from "react";

import { Link } from "react-router-dom";
/// Scroll
import { Dropdown } from "react-bootstrap";

import LogoutPage from "./Logout";

/// Image
import ProfileDefault from "../../../images/user.jpg";
import avatar from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";
import avatar4 from "../../../images/avatar/4.jpg";

import { ThemeContext } from "../../../context/ThemeContext";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
import { connect, useDispatch } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { checkAutoLogin } from "../../../services/AuthService";
import axiosInstance from "../../../services/AxiosInstance";
import { HOSTNAME } from "../../../constant";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const searchList = [
  { image: avatar, title: "Benjamin" },
  { image: avatar2, title: "Oliver" },
  { image: avatar3, title: "Lucas" },
  { image: avatar4, title: "Harry" },
  { image: avatar2, title: "Oliver" },
  { image: avatar4, title: "Harry" },
  { image: avatar, title: "Benjamin" },
  { image: avatar3, title: "Lucas" },
];

const Header = (props) => {

  const profile = props.user.detail.profile;
  const group_code = props.user.group_code;
  const photoUrl = profile.foto_profil ? `${HOSTNAME}/storage/00-universal/${profile.institusi.website}/${group_code}/${profile.foto_profil}` : {ProfileDefault};

  const location = useLocation();
  const path = location.pathname.split('/').pop();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //   checkAutoLogin(dispatch, navigate);
  // }, []);

  //For header fixed
  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  const { background, changeBackground } = useContext(ThemeContext);
  const handleThemeMode = () => {
    if (background.value === "dark") {
      changeBackground({ value: "light", label: "Light" });
    } else {
      changeBackground({ value: "dark", label: "Dark" });
    }
  };
  const [changeScreen, setChangeScreen] = useState();
  const fullscreenRef = useRef(null);
  const EnterFullScreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    } else {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    }
  };
  
  return (
    <div className={`header ${headerFix ? "sticky" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                Materi Ajar dan Ujian
              </div>
            </div>
            <ul className="navbar-nav header-right">
              <li className="nav-item dropdown notification_dropdown">
                <Link
                  to={"#"}
                  className={`nav-link bell dz-theme-mode ${
                    background.value === "dark" ? "active" : ""
                  }`}
                  onClick={() => handleThemeMode()}
                >
                  <i id="icon-light-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-sun"
                    >
                      <circle cx="12" cy="12" r="5"></circle>
                      <line x1="12" y1="1" x2="12" y2="3"></line>
                      <line x1="12" y1="21" x2="12" y2="23"></line>
                      <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                      <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                      <line x1="1" y1="12" x2="3" y2="12"></line>
                      <line x1="21" y1="12" x2="23" y2="12"></line>
                      <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                      <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                    </svg>
                  </i>
                  <i id="icon-dark-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-moon"
                    >
                      <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                    </svg>
                  </i>
                </Link>
              </li>
              <li className="nav-item dropdown notification_dropdown">
                <Link
                  to={"#"}
                  className={`nav-link bell dz-fullscreen ${
                    changeScreen ? "active" : ""
                  }`}
                  ref={fullscreenRef}
                  onClick={() => {
                    EnterFullScreen();
                    setChangeScreen(!changeScreen);
                  }}
                >
                  <svg
                    id="icon-full-1"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="css-i6dzq1"
                  >
                    <path
                      d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"
                      style={{
                        strokeDasharray: "37, 57",
                        strokeDashoffset: "0",
                      }}
                    ></path>
                  </svg>
                  <svg
                    id="icon-minimize-1"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="A098AE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-minimize"
                  >
                    <path
                      d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"
                      style={{
                        strokeDasharray: "37, 57",
                        strokeDashoffset: "0",
                      }}
                    ></path>
                  </svg>
                </Link>
              </li>
              <li className="nav-item dropdown notification_dropdown">
                <Link
                  to={"/bursa/kartu-soal"}
                  className={`nav-link`}
                >
                  <i className="fas fa-shopping-cart"></i>
                </Link>
              </li>

              <li className="nav-item ">
                <Dropdown className="dropdown header-profile2">
                  <Dropdown.Toggle
                    variant=""
                    as="a"
                    className="nav-link i-false c-pointer ms-0"
                  >
                    <div className="header-info2 d-flex align-items-center">
                      <img src={photoUrl} alt="" />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    align="end"
                    className="mt-1 dropdown-menu dropdown-menu-end"
                  >
                    <div className="card mb-0">
                      <div className="card-header p-3">
                        <ul className="d-flex align-items-center">
                          <li>
                            <img src={photoUrl} className="ms-0" alt="" />
                          </li>
                          <li className="ms-2">
                            <h4 className="mb-0">{ props.user.name }</h4>
                            <span>{props.user.group_code}</span>
                          </li>
                        </ul>
                      </div>
                      
                      <div className="card-footer text-center p-3">
                        <LogoutPage />
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    user: state.auth.auth,
  };
};

export default withRouter(connect(mapStateToProps)(Header));
