import { useNavigate } from "react-router-dom";

const BackButton = (props) => {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate(-1)} className={`btn btn-sm btn-outline btn-outline-info ${props.className}`}>
      Kembali
    </button>
  );
};

export default BackButton;
