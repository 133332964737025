import React, { useState, useRef, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import { Badge, Modal, Button, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import "./DetailHasil.css";
import { closeLoading, showLoading } from "../../../customs/loading/loading";
import BackButton from "../../../customs/Button/BackButton";

const DetailHasilTestOnline = (props) => {
  const inputEl = useRef(null);

  const { id_ujian } = useParams();
  const [searchParams] = useSearchParams();
  const type_page = searchParams.get("type_page");

  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const sort = 100;
  const activePag = useRef(0);
  const [showPage, setShowPage] = useState(type_page ?? "nilai");
  const [showKomposisi, setShowKomposisi] = useState(false);

  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  const handleShow = (param) => {
    setLocalStorage("showPage", param);
    setShowPage(param);
  };

  //LOAD DATA
  const [studentsData, setStudentsData] = useState(null);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [soalUr, setSoalUr] = useState(0);
  const [showKoreksiModal, setShowKoreksiModal] = useState(false);

  useEffect(() => {
    prepData();
  }, []);

  const prepData = async () => {
    showLoading();
    try {
      await loadDataJawaban();
      await closeLoading();
    } catch (error) {
      await closeLoading();
    }
  };
  const loadDataJawaban = async () => {
    var res = await axiosInstance.get(`ujian/${id_ujian}`);
    var data = res.data.data;
    await loadPaketSoalDetail(data.paket_soal_id);
    var tmp = [];
    for (var i = 0; i < data.peserta.length; i++) {
      var response = [];
      for (var a = 0; a < data.peserta[i].value.length; a++) {
        response.push(data.peserta[i].value[a]);
      }
      tmp.push({
        id: data.peserta[i].id,
        kelas: data.peserta[i].detail.kelas,
        rombel: data.peserta[i].detail.rombel,
        nama_siswa: data.peserta[i].user.name,
        status: data.peserta[i].status,
        responses: response,
        score: data.peserta[i].score,
        nilai_bobot: data.peserta[i].nilai_bobot,
        presentase_nilai_bobot: data.peserta[i].presentase_nilai_bobot,
        ranking: data.peserta[i].ranking,
      });
    }
    let collectSoalUr = [];
    tmp[0]?.responses.forEach((soal) => {
      if (soal.type === "UR") {
        collectSoalUr.push(soal);
      }
    });

    setSoalUr(collectSoalUr);
    setNumberOfQuestions(tmp[0]?.responses.length || 0);
    setStudentsData(tmp);
  };

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [dataButir, setDataButir] = useState(null);

  const detailButirSoal = async (id) => {
    showLoading();
    try {
      const response = await axiosInstance.get(`soal/${id}?with_jawaban=true`);
      var data = response.data.data;
      await setDataButir(data);
      await setShowDetailModal(true);
      await closeLoading();
    } catch (error) {
      await closeLoading();
    }
  };

  const [selectedPaketSoal, setSelectedPaketSoal] = useState(null);
  const loadPaketSoalDetail = async (id_paket_soal) => {
    var res = await axiosInstance.get(
      `paket-soal/${id_paket_soal}?with_content=true`
    );
    setSelectedPaketSoal(res.data.data);
  };

  const [selectedResponse, setSelectedResponse] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const handleShowKoreksiModal = (studentId, response) => {
    setSelectedStudentId(studentId);
    setSelectedResponse(response);
    setShowKoreksiModal(true);
  };

  const handleCloseKoreksiModal = () => {
    setSelectedStudentId(null);
    setSelectedResponse(null);
    setShowKoreksiModal(false);
  };

  const handleKirimKoreksi = async (result, code) => {
    try {
      var params = {
        code: code,
        result: result,
      };
      var res = await axiosInstance.put(
        `test-online/guru/correcting-answer/${selectedStudentId}`,
        params
      );

      handleCloseKoreksiModal();
      await prepData();

      Swal.fire({
        icon: "success",
        title: "Koreksi Berhasil",
        showConfirmButton: true,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Koreksi Gagal",
        text: error.message,
        showConfirmButton: true,
      });
    }
  };

  const getTypeCode = (type) => {
    if (type == "PG") {
      return "Pilihan Ganda";
    } else if (type == "MPG") {
      return "Multiple Pilihan Ganda";
    } else if (type == "BS") {
      return "Benar Salah";
    } else if (type == "UR") {
      return "Uraian";
    } else if (type == "PJ") {
      return "Penjodohan";
    } else if (type == "SA") {
      return "Sebab Akibat";
    }
  };

  const [nilaiValue, setNilaiValue] = useState(5);
  const handleChangeNilai = (e) => {
    setNilaiValue(e.target.value);
  };
  const handleExportToExcel = async (tipe) => {
    try {
      var urlAPI = "";

      if (tipe == "nilai") {
        //ujian/export/jawaban/id ujian
        urlAPI = `ujian/export/jawaban/${id_ujian}`;
      }

      if (tipe == "rekap") {
        //ujian/export/hasil-ujian/id ujian
        urlAPI = `ujian/export/hasil-ujian/${id_ujian}`;
      }

      // Meminta file Excel dari backend
      const response = await axiosInstance.get(urlAPI, {
        responseType: "arraybuffer", // Pastikan response diterima dalam format arraybuffer
      });

      // Membuat Blob dari data response
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Membuat URL object untuk file Blob
      const url = window.URL.createObjectURL(blob);

      // Membuat elemen anchor untuk memicu unduhan
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", tipe + "_" + id_ujian + ".xlsx"); // Nama file yang akan diunduh
      document.body.appendChild(link);

      // Memicu klik untuk mengunduh file
      link.click();

      // Membersihkan URL object setelah digunakan
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading Excel file", error);
    }
  };

  const [isAscending, setIsAscending] = useState(true);

  const handleSort = (type) => {
    if (type == "nilai") {
      const sorted = [...studentsData].sort((a, b) =>
        isAscending
          ? a.nilai_bobot - b.nilai_bobot
          : b.nilai_bobot - a.nilai_bobot
      );
      setStudentsData(sorted);
      setIsAscending(!isAscending);
    } else if (type == "nilai_persen") {
      const sorted = [...studentsData].sort((a, b) =>
        isAscending
          ? a.presentase_nilai_bobot - b.presentase_nilai_bobot
          : b.presentase_nilai_bobot - a.presentase_nilai_bobot
      );
      setStudentsData(sorted);
      setIsAscending(!isAscending);
    } else if (type == "ranking") {
      const sorted = [...studentsData].sort((a, b) =>
        isAscending ? a.ranking - b.ranking : b.ranking - a.ranking
      );
      setStudentsData(sorted);
      setIsAscending(!isAscending);
    }
  };

  return (
    <div className="col-12">
      <div className="card dz-card">
        <div className="card-header">
          <div className="card-title">
            {showPage == "jawaban"
              ? "Penilaian Soal Uraian"
              : "Daftar Nilai dan analisis Hasil Ujian"}
          </div>
          <div className="card-toolbar d-flex">
            <ul
              as="ul"
              className={`nav nav-tabs dzm-tabs d-none`}
              id="myTab"
              role="tablist"
            >
              <li as="li" className="nav-item">
                <button
                  className={`nav-link ${showPage == "nilai" ? "active" : ""}`}
                  type="button"
                  onClick={() => handleShow("nilai")}
                >
                  Hasil Jawaban Siswa
                </button>
              </li>
              <li as="li" className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    showPage == "jawaban" ? "active" : ""
                  }`}
                  type="button"
                  onClick={() => handleShow("jawaban")}
                >
                  Nilai Per Butir
                </button>
              </li>
              <li as="li" className="nav-item" role="presentation">
                <button
                  className={`nav-link ${showPage == "bobot" ? "active" : ""}`}
                  type="button"
                  onClick={() => handleShow("bobot")}
                >
                  Hasil Nilai Bobot
                </button>
              </li>
              <li as="li" className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    showPage == "bobot_persen" ? "active" : ""
                  }`}
                  type="button"
                  onClick={() => handleShow("bobot_persen")}
                >
                  Hasil Nilai Persen
                </button>
              </li>
              <li as="li" className="nav-item" role="presentation">
                <button
                  className={`nav-link ${showPage == "rekap" ? "active" : ""}`}
                  type="button"
                  onClick={() => handleShow("rekap")}
                >
                  Rekap Hasil Nilai
                </button>
              </li>
            </ul>
            <BackButton className="ms-2" />
          </div>
        </div>
        <div className="card-body">
          <div className="col-12 text-end">
            {showPage == "nilai" && (
              <button
                className="btn btn-primary btn-sm"
                onClick={() => handleExportToExcel("nilai")}
              >
                Export to Excel
              </button>
            )}
            {showPage == "rekap" && (
              <button
                className="btn btn-primary btn-sm"
                onClick={() => handleExportToExcel("rekap")}
              >
                Export to Excel
              </button>
            )}
          </div>
          {showPage == "nilai" && (
            <div className="card-body">
              <div className="table-responsive">
                <div id="job_data" className="dataTables_wrapper">
                  <table
                    className="display w-100 dataTable table-bordered"
                    id="example5"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th className="sorting_asc ">No</th>
                        <th className="sorting ">Kelas</th>
                        <th className="sorting ">Rombel</th>
                        <th className="sorting ">Nama Peserta</th>
                        {/* <th className="text-center ">Nilai Akhir</th> */}
                        {Array.from(
                          { length: numberOfQuestions },
                          (_, index) => (
                            <th key={index}>{index + 1}</th>
                          )
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {studentsData?.map((student, index) => (
                        <tr key={index + 1}>
                          <td>
                            <strong>{index + 1}</strong>
                          </td>
                          <td>{student.kelas}</td>
                          <td>{student.rombel}</td>
                          <td>{student.nama_siswa}</td>
                          {/* Render responses for each question */}
                          {/* {JSON.stringify(student.responses[0])} */}
                          {student.responses?.map((response, index) => (
                            <td key={index}>
                              {/* {typeof response.jawaban} */}
                              {/* {response.jawaban == null ? 'null' : 'ada'} */}
                              {response.jawaban != null ? (
                                <Badge
                                  onClick={
                                    response.type == "UR"
                                      ? () =>
                                          handleShowKoreksiModal(
                                            student.id,
                                            response
                                          )
                                      : null
                                  }
                                  as="a"
                                  bg="badge-lg"
                                  // bg={
                                  //   response.type == "UR" &&
                                  //   response.status == null
                                  //     ? "warning"
                                  //     : response.type == "UR" &&
                                  //       response.status != null
                                  //     ? "success"
                                  //     : response.jawaban == "ragu"
                                  //     ? "warning"
                                  //     : response.status == "benar"
                                  //     ? "success"
                                  //     : "danger"
                                  // }
                                >
                                  {response.type == "UR" &&
                                  response.status == null ? (
                                    <i className="fas fa-eye"></i>
                                  ) : response.type == "UR" &&
                                    response.status != null ? (
                                    "Sudah dikoreksi"
                                  ) : (
                                    response.jawaban
                                  )}
                                </Badge>
                              ) : (
                                ""
                              )}
                            </td>
                          ))}
                          <td>
                            {student.status == "selesai" ? (
                              <Badge bg="success">{student.status}</Badge>
                            ) : (
                              <Badge bg="danger">{student.status}</Badge>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {(showPage == "bobot" ||
            showPage == "bobot_persen" ||
            showPage == "rekap") && (
            <>
              {true ? (
                <div className="table-responsive">
                  <div id="job_data" className="dataTables_wrapper">
                    <table
                      className="display w-100 dataTable table-bordered"
                      id="example5"
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <thead>
                        <tr role="row">
                          <th className="sorting_asc">No</th>
                          <th className="sorting ">Kelas</th>
                          <th className="sorting ">Rombel</th>
                          <th className="sorting ">Nama Peserta</th>
                          {/* <th className="text-center ">Nilai Akhir</th> */}
                          {Array.from(
                            {
                              length:
                                showPage == "jawaban"
                                  ? soalUr
                                  : numberOfQuestions,
                            },
                            (_, index) => (
                              <th key={index}>{index + 1}</th>
                            )
                          )}
                          {(showPage == "bobot" ||
                            showPage == "bobot_persen") && (
                            <th className="sorting ">Nilai Akhir</th>
                          )}
                          {showPage == "rekap" && (
                            <th
                              className="sorting "
                              onClick={() => {
                                handleSort("nilai");
                              }}
                            >
                              Nilai
                            </th>
                          )}
                          {showPage == "rekap" && (
                            <th
                              className="sorting "
                              onClick={() => {
                                handleSort("nilai_persen");
                              }}
                            >
                              Nilai %
                            </th>
                          )}
                          {showPage == "rekap" && (
                            <th
                              className="sorting "
                              onClick={() => {
                                handleSort("ranking");
                              }}
                            >
                              Ranking
                            </th>
                          )}
                        </tr>
                      </thead>

                      <tbody>
                        {studentsData?.map((student, index) => (
                          <tr key={index + 1}>
                            <td>
                              <strong>{index + 1}</strong>
                            </td>
                            <td>{student.kelas}</td>
                            <td>{student.rombel}</td>
                            <td>{student.nama_siswa}</td>
                            {/* Render responses for each question */}
                            {/* {JSON.stringify(student.responses[0])} */}
                            {student.responses?.map(
                              (response, index) =>
                                showPage != "jawaban" ||
                                (response.type == "UR" && (
                                  <td key={index}>
                                    {parseFloat(
                                      showPage == "jawaban" ||
                                        showPage == "rekap"
                                        ? response.nilai
                                        : (showPage == "bobot"
                                            ? response.nilai_bobot
                                            : response.presentase_nilai_bobot
                                          ).toFixed(2)
                                    )}
                                    {showPage == "jawaban" &&
                                      response.type == "UR" && (
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                          onClick={() =>
                                            handleShowKoreksiModal(
                                              student.id,
                                              response
                                            )
                                          }
                                        ></i>
                                      )}
                                  </td>
                                ))
                            )}
                            {(showPage == "bobot" ||
                              showPage == "bobot_persen") && (
                              <td>
                                {parseFloat(
                                  showPage == "bobot"
                                    ? student.nilai_bobot
                                    : student.presentase_nilai_bobot
                                ).toFixed(2)}
                              </td>
                            )}
                            {showPage == "rekap" && (
                              <td>
                                {parseFloat(student.nilai_bobot).toFixed(2)}
                              </td>
                            )}
                            {showPage == "rekap" && (
                              <td>
                                {parseFloat(
                                  student.presentase_nilai_bobot
                                ).toFixed(2)}
                              </td>
                            )}
                            {showPage == "rekap" && (
                              <td> {student.ranking} </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <p>Tidak ada data {showPage == "jawaban" && "soal uraian"}</p>
                </div>
              )}
            </>
          )}

          {showPage == "jawaban" && (
            <>
              {soalUr.length > 0 ? (
                <div className="table-responsive">
                  <div id="job_data" className="dataTables_wrapper">
                    <table
                      className="display w-100 dataTable table-bordered"
                      id="example5"
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <thead>
                        <tr role="row">
                          <th className="sorting_asc">No</th>
                          <th className="sorting ">Kelas</th>
                          <th className="sorting ">Rombel</th>
                          <th className="sorting ">Nama Peserta</th>
                          {soalUr.map((value, index) => (
                            <th key={`${index}`} className="">
                              <b
                                className="text-decoration-underline"
                                style={{ cursor: "pointer" }}
                                onClick={() => detailButirSoal(value.id)}
                              >
                                {value.no_urut}
                              </b>
                            </th>
                          ))}
                        </tr>
                      </thead>

                      <tbody>
                        {studentsData?.map((student, index) => (
                          <tr key={index + 1}>
                            <td>
                              <strong>{index + 1}</strong>
                            </td>
                            <td>{student.kelas}</td>
                            <td>{student.rombel}</td>
                            <td>{student.nama_siswa}</td>
                            {/* Render responses for each question */}
                            {/* {JSON.stringify(student.responses[0])} */}
                            {student.responses?.map(
                              (response, index) =>
                                showPage != "jawaban" ||
                                (response.type == "UR" && (
                                  <td key={index}>
                                    {parseFloat(
                                      showPage == "jawaban" ||
                                        showPage == "rekap"
                                        ? response.nilai
                                        : (showPage == "bobot"
                                            ? response.nilai_bobot
                                            : response.presentase_nilai_bobot
                                          ).toFixed(2)
                                    )}
                                    {showPage == "jawaban" &&
                                      response.type == "UR" && (
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                          onClick={() =>
                                            handleShowKoreksiModal(
                                              student.id,
                                              response
                                            )
                                          }
                                        ></i>
                                      )}
                                  </td>
                                ))
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <p>Tidak ada data Soal Uraian</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Modal
        dialogClassName="modal-lg"
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detail Butir Soal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dataButir != null ? (
            <>
              <h3>Informasi</h3>
              <div className="d-flex justify-content-between">
                <p>
                  <strong>Code : </strong> {dataButir.code}
                </p>
                <p>
                  <strong>Type : </strong> {dataButir.type}
                </p>
                <p>
                  <strong>Judul : </strong> {dataButir.detail.materi}
                </p>
              </div>

              {dataButir.type == "PG" ||
              dataButir.type == "BS" ||
              dataButir.type == "SA" ? (
                <>
                  <h3>Soal</h3>
                  <h5>
                    <strong>Pertanyaan : </strong>
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: dataButir.value.pertanyaan,
                    }}
                  ></p>

                  <h5>
                    <strong>Pilihan : </strong>
                  </h5>
                  <table class="">
                    {Object.entries(dataButir.value.pilihan).map(
                      ([key, value], index) => (
                        <tr
                          key={index}
                          className="d-flex justify-content-start align-items-start"
                        >
                          <td className="pe-2">{key.toUpperCase()}. </td>
                          <td className="pe-2">
                            <span dangerouslySetInnerHTML={{ __html: value }} />
                          </td>
                        </tr>
                      )
                    )}
                  </table>

                  <p>
                    <strong>Jawaban : </strong>{" "}
                    {dataButir.value.jawaban.toUpperCase()}
                  </p>
                </>
              ) : (
                ""
              )}
              {dataButir.type == "UR" ? (
                <>
                  <h3>Soal</h3>
                  <h5>
                    <strong>Pertanyaan : </strong>
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: dataButir.value.pertanyaan,
                    }}
                  ></p>
                  <h5>
                    <strong>Pembahasan : </strong>
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: dataButir.value.pembahasan,
                    }}
                  ></p>
                </>
              ) : (
                ""
              )}
              {dataButir.type == "PJ" ? (
                <>
                  <h3>Soal</h3>
                  <h5>
                    <strong>Pertanyaan : </strong>
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: dataButir.value.pertanyaan,
                    }}
                  ></p>
                  <h5>
                    <strong>Pasangan : </strong>
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: dataButir.value.pasangan,
                    }}
                  ></p>
                  <h5>
                    <strong>Pengecoh : </strong>
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: dataButir.value.pengecoh,
                    }}
                  ></p>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => setShowDetailModal(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="fade bd-example-modal-lg"
        size="lg"
        show={showKoreksiModal}
      >
        <Modal.Header>
          <Modal.Title>
            {showPage == "nilai" ? "Jawaban" : "Koreksi Jawaban"}
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={handleCloseKoreksiModal}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={3}>Kunci</Col>
            <Col style={{ display: "inline-flex" }}>
              : &nbsp;{" "}
              {selectedPaketSoal && selectedResponse && (
                 <span
                  dangerouslySetInnerHTML={{
                    __html:
                      selectedPaketSoal.value.filter(
                        (v) => v.code == selectedResponse.code
                      )[0].value.pembahasan ?? "Tidak ada kunci",
                  }}
                ></span>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={3}>Jawaban</Col>
            {selectedResponse?.jawaban == null && (
              <Col>: Tidak ada Jawaban</Col>
            )}
            {selectedResponse?.jawaban != null && (
              <Col style={{ display: "inline-flex" }}>
                : &nbsp;{" "}
                <div>
                {selectedResponse?.jawaban.includes("|") && selectedResponse?.jawaban.split("|")[0] != "" && 
                (
                  <div>
                    <img src={selectedResponse?.jawaban.split('|')[0]} alt="" className="w-25 h-auto border rounded-md"/>
                    <br/>
                  </div>
                )
                }
                {selectedResponse?.jawaban.includes("|") && selectedResponse?.jawaban.split("|")[1] != "" && (
                  <span
                  dangerouslySetInnerHTML={{
                    __html: selectedResponse?.jawaban.split("|")[1],
                  }}
                ></span>
                )}
                </div>
              </Col>
            )}
          </Row>
          {showPage == "jawaban" && (
            <Row>
              <Col lg={3}>Nilai</Col>
              <Col>: {nilaiValue}</Col>
            </Row>
          )}
          {showPage == "jawaban" && (
            <Row>
              <Col>
                <input
                  type="range"
                  className="form-range"
                  id="rangeInput"
                  min="0"
                  max="10"
                  value={nilaiValue}
                  onChange={handleChangeNilai}
                />
              </Col>
            </Row>
          )}
        </Modal.Body>
        {showPage == "jawaban" && (
          <Modal.Footer>
            {/* <Button variant="danger" onClick={()=>handleKirimKoreksi(nilaiValue, selectedResponse.code)}>Salah</Button> */}
            <Button
              variant="primary"
              onClick={() =>
                handleKirimKoreksi(nilaiValue, selectedResponse.code)
              }
            >
              Simpan
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default DetailHasilTestOnline;
