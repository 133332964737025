import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import Preview from "../DaftarUjian/Steps/Preview";
import axiosInstance from "../../../services/AxiosInstance";
import "./preview.css";
import { closeLoading, showLoading } from "../../customs/loading/loading";
import BackButton from "../../customs/Button/BackButton";

const PreviewPaketSoal = () => {
  const { id_paket } = useParams();
  const [soal, setSoal] = useState([]);

  const [bentukSoal, setBentukSoal] = useState([]);
  const [topikSoal, setTopikSoal] = useState([]);

  const [totalSoal, setTotalSoal] = useState(0);
  const [totalBobot, setTotalBobot] = useState(0);

  const [detailPaketSoal, setDetailPaketSoal] = useState(null);

  const getTypeName = (type) => {
    if (type == "PG") {
      return "pilihan-ganda";
    } else if (type == "BS") {
      return "benar-salah";
    } else if (type == "UR") {
      return "uraian";
    } else if (type == "PJ") {
      return "penjodohan";
    } else if (type == "SA") {
      return "sebab-akibat";
    }
  };

  const getPaketSoal = async () => {
    var res = await axiosInstance.get(
      `paket-soal/${id_paket}?with_content=true`
    );
    var paketSoal = res.data.data.value;
    var topikSoal = res.data.data.topik;
    var scoreSoal = Object.values(res.data.data.score);

    var tmp = [];
    var topik = [];
    var bentuk = [];

    const totalBobot = scoreSoal.reduce(
      (sum, soal) => sum + parseFloat(soal.bobot),
      0
    );
    const totalSoal = scoreSoal.reduce(
      (sum, soal) => sum + parseFloat(soal.jumlah_soal),
      0
    );
    setTotalSoal(totalSoal);
    setTotalBobot(totalBobot);

    const keys = Object.keys(res.data.data.score);
    for (var i = 0; i < scoreSoal.length; i++) {
      if (keys[i] != "MPG") {
        bentuk.push({
          type: keys[i],
          name: getTypeName(keys[i]),
          ...scoreSoal[i],
        });
      }
    }

    for (var i = 0; i < paketSoal.length; i++) {
      tmp.push({
        type: getTypeName(paketSoal[i].type),
        ...paketSoal[i],
      });
    }

    if (topikSoal != null && topikSoal.length > 0) {
      for (var i = 0; i < topikSoal.length; i++) {
        const soalIds = topikSoal[i].soal_ids;
        const filteredSoal = paketSoal.filter((soal) =>
          soalIds.includes(soal.id)
        );

        let typeCounts = {
          PG: {
            data: [],
            total: 0,
          },
          BS: {
            data: [],
            total: 0,
          },
          PJ: {
            data: [],
            total: 0,
          },
          SA: {
            data: [],
            total: 0,
          },
          UR: {
            data: [],
            total: 0,
          },
        };

        filteredSoal.forEach((soal) => {
          if (typeCounts[soal.type]?.total !== undefined) {
            typeCounts[soal.type].data.push(soal);
            typeCounts[soal.type].total++;
          }
        });

        topik.push({
          code: topikSoal[i].code,
          name: topikSoal[i].name,
          no_urut: topikSoal[i].no_urut,
          pg: {
            total: typeCounts.PG.total,
            data: typeCounts.PG.data,
          },
          bs: {
            total: typeCounts.BS.total,
            data: typeCounts.BS.data,
          },
          pj: {
            total: typeCounts.PJ.total,
            data: typeCounts.PJ.data,
          },
          sa: {
            total: typeCounts.SA.total,
            data: typeCounts.SA.data,
          },
          ur: {
            total: typeCounts.UR.total,
            data: typeCounts.UR.data,
          },
          total: topikSoal[i].soal_ids.length,
        });
      }
      setTopikSoal(topik);
    }
    setBentukSoal(bentuk);
    setDetailPaketSoal(res.data.data);
    setSoal(tmp);
  };

  useEffect(() => {
    prepData();
  }, []);

  const prepData = async () => {
    showLoading();
    try {
      await getPaketSoal();
      await closeLoading();
    } catch (error) {
      await closeLoading();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header card-header-komposisi">
              <div className="card-title">Komposisi Soal</div>
              <div className="card-tool-bar">
                <BackButton />
              </div>
            </div>
            {detailPaketSoal == null ? (
              ""
            ) : (
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <table className="w-100 nm">
                      <tr>
                        <td className="p-2 text-dark">
                          <b>Perangkai</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {detailPaketSoal.created_by_user.name}</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>ID Paket Soal</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {detailPaketSoal.code}</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>Jenjang</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {detailPaketSoal.detail.jenjang}</b>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2 text-dark">
                          <b>Kelas</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {detailPaketSoal.detail.kelas}</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>Jurusan</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {detailPaketSoal.detail.jurusan}</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>Mata Pelajaran</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {detailPaketSoal.detail.mata_pelajaran}</b>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-2 text-dark">
                          <b>Nama Paket Ujian</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {detailPaketSoal.detail.judul}</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>Durasi</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {detailPaketSoal.detail.durasi / 60} (menit)</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>Platform</b>
                        </td>
                        <td className="p-2 text-dark">
                          <b>: {detailPaketSoal.type}</b>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-8 mt-2 table-responsive">
                    <table
                      className="table table-bordered table-stripped table-hover mt-2"
                      id="example5"
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <thead>
                        <tr role="row">
                          <th className="px-2">Bentuk Soal</th>
                          <th className="px-2">N</th>
                          <th className="px-2">Bb</th>
                          <th className="px-2">NB</th>
                          <th className="px-2">NS</th>
                          <th className="px-2">NK</th>
                          <th className="px-2">SpS</th>
                          <th className="px-2">Bb%</th>
                          <th className="px-2">SpS %</th>
                        </tr>
                      </thead>

                      <tbody>
                        {bentukSoal.length > 0 ? (
                          <>
                            {bentukSoal.map((v, i) => (
                              <tr key={i}>
                                <td className="px-2">
                                  {v.name.replaceAll("-", " ")}
                                </td>
                                <td className="px-2">{v.jumlah_soal}</td>
                                <td className="px-2">{v.bobot}</td>
                                <td className="px-2">{v.point_benar}</td>
                                <td className="px-2">{v.point_salah}</td>
                                <td className="px-2">{v.point_kosong}</td>
                                <td className="px-2">{v.sps}</td>
                                <td className="px-2">{v.bb}</td>
                                <td className="px-2">
                                  {v.spsp === null ?   "0"  : v.spsp}
                                </td>
                              </tr>
                            ))}

                            <tr>
                              <td className="px-2">Jumlah Soal</td>
                              <td className="px-2">{totalSoal}</td>
                              <td colSpan={5} className="px-2">
                                {totalBobot}
                              </td>
                            </tr>
                          </>
                        ) : (
                          <tr className="text-center">
                            <td colSpan={9}>Tidak ada Soal</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-4">
                    <ul className="mt-3">
                      <li className="mb-1">
                        <strong>N :</strong> Jumlah Kartu per Bentuk Soal
                      </li>
                      <li className="mb-1">
                        <strong>Bb :</strong> Nilai Bobot Skor untuk jenis butir
                        soal tertentu dalam paket soal ujian
                      </li>
                      <li className="mb-1">
                        <strong>TB :</strong> Total Jumlah Bobot jenis Butir
                        Soal
                      </li>
                      <li className="mb-1">
                        <strong>NB :</strong> Skor nilai Butir Soal jika dijawab
                        benar
                      </li>
                      <li className="mb-1">
                        <strong>NS :</strong> Skor nilai Butir Soal jika dijawab
                        salah
                      </li>
                      <li className="mb-1">
                        <strong>NK :</strong> Skor nilai Butir Soal jika tidak
                        dijawab
                      </li>
                      <li className="mb-1">
                        <strong>SpS :</strong> Nilai skor per butir soal = Bb/N
                      </li>
                      <li className="mb-1">
                        <strong>Bb% :</strong> Nilai Bobot Skor untuk bentuk
                        soal tertentu dalam paket soal ujian dalam % = Bb1 /
                        Total Bobot
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 mt-2 table-responsive">
                    <table
                      className="table table-bordered table-stripped table-hover mt-2"
                      id="example5"
                      role="grid"
                      aria-describedby="example5_info"
                    >
                      <thead>
                        <tr role="row">
                          <th className="px-2">Nama Topik</th>
                          <th className="px-2">Kode</th>
                          <th className="px-2">PG</th>
                          <th className="px-2">SA</th>
                          <th className="px-2">PJ</th>
                          <th className="px-2">BS</th>
                          <th className="px-2">UR</th>
                          <th className="px-2">Total</th>
                        </tr>
                      </thead>

                      <tbody>
                        {topikSoal.length > 0 ? (
                          topikSoal.map((v, i) => (
                            <tr key={i}>
                              <td className="px-2">{v.name}</td>
                              <td className="px-2">{v.code}</td>
                              <td className="px-2">{v.pg.total}</td>
                              <td className="px-2">{v.sa.total}</td>
                              <td className="px-2">{v.pj.total}</td>
                              <td className="px-2">{v.bs.total}</td>
                              <td className="px-2">{v.ur.total}</td>
                              <td className="px-2">{v.total}</td>
                            </tr>
                          ))
                        ) : (
                          <>
                            <tr className="text-center">
                              <td colSpan="9">Tidak ada Topik</td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-4">
                    <div className="row">
                      <div className="col-5">
                        {" "}
                        <hr />
                      </div>
                      <div className="col-2 text-center">
                        <h5 className="text-primary mt-1">
                          Preview Paket Soal
                        </h5>
                      </div>
                      <div className="col-5">
                        {" "}
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <section>
                      {soal.map((v, i) => (
                        <div key={i}>
                          {/* Petunjuk untuk soal pilihan ganda hanya pada soal pilihan ganda pertama */}
                          {i === soal.findIndex((item) => item.type === "PG") &&
                            v.type === "PG" && (
                              <>
                                <b>I. Bentuk Soal Pilihan Ganda</b>
                                <p>
                                  Pilihlah salah satu jawaban yang dianggap
                                  benar!
                                </p>
                              </>
                            )}

                          {/* Tampilkan soal pilihan ganda */}
                          {v.type === "PG" ||
                          v.type === "SA" ||
                          v.type === "PJ" ||
                          v.type === "BS" ? (
                            <div
                              className="row mt-3 d-flex"
                              style={{ alignItems: "start" }}
                            >
                              <div className="col-1 text-center">
                                <b>{i + 1}.</b>
                              </div>
                              <div className="col-11">
                                <div className="col-soal mb-3">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: v.value.pertanyaan,
                                    }}
                                  ></div>
                                </div>
                                {v.type == "PJ" && (
                                  <div className="col-option mt-2">
                                    <span>Pasangan : {v.value.pasangan}</span>
                                    <br />
                                    <span>Pengecoh : {v.value.pengecoh}</span>
                                  </div>
                                )}
                                {v.type != "PJ" &&
                                  Object.keys(v.value.pilihan).map(
                                    (val, index) => (
                                      <div
                                        className="col-option d-flex mt-2"
                                        key={index}
                                      >
                                        <input
                                          type="radio"
                                          className="me-3"
                                          readOnly
                                          checked={v.value.jawaban == val}
                                        />
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: v.value.pilihan[val],
                                          }}
                                        ></div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {/* {v.type === "MPG" && (
                            <>
                            <div className="row mt-2">
                              <div className="col-1 text-center">
                                <b></b>
                              </div>
                              <div className="col-11">
                                <div className="col-soal mb-3">
                                  <div dangerouslySetInnerHTML={{__html: v.value.content}}></div>
                                </div>
                              </div>
                            </div>
                            { v.value.soal.map((soal, soalIndex) => (
                              <div className="row mt-2" key={soalIndex}>
                                <div className="col-1 text-center">
                                  <b>{i + 1}.</b>
                                </div>
                                <div className="col-11">
                                  <div className="col-soal mb-3">
                                    <div dangerouslySetInnerHTML={{__html: soal.pertanyaan}}></div>
                                  </div>
                                  {Object.keys(soal.pilihan).map((val, index) => (
                                    <div
                                      className="col-option d-flex mt-2"
                                      key={index}
                                    >
                                      <input
                                        type="radio"
                                        className="me-3"
                                        readOnly
                                        checked={soal.jawaban == val}
                                      />
                                      <div dangerouslySetInnerHTML={{__html: soal.pilihan[val]}}></div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                            
                            </>
                          )} */}

                          {i === soal.findIndex((item) => item.type === "UR") &&
                            v.type === "UR" && (
                              <div className="row mt-3">
                                <div className="col-sm-12">
                                  <div className="mt-5">
                                    <b>II. Bentuk Soal Uraian</b>
                                    <p>Jawablah pertanyaan di bawah ini!</p>
                                  </div>
                                </div>
                              </div>
                            )}

                          {v.type === "UR" && (
                            <div className="row mt-2">
                              <div className="col-1 text-center">
                                <b>{i + 1}.</b>
                              </div>
                              <div className="col-11">
                                <div className="col-soal mb-3">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: v.value.pertanyaan,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      <div className="row">
                        <div className="col-12">
                          <hr />
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewPaketSoal;
