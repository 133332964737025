import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import ProfileDefault from "../../../images/user.jpg";
import avatar from "../../../images/avatar/1.jpg";
import LogoutPage from "../../layouts/nav/Logout";
import LogoSite from "../../../images/site-logo.png";
import { useSelector } from "react-redux";
import { BAHANAJAR_HOSTNAME, TRYOUT_HOSTNAME } from "../../../constant";

import { connect, useDispatch } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// action
import { checkAutoLogin, isLogin } from "../../../services/AuthService";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
import Swal from "sweetalert2";
import { useCart } from "../../../services/CartContext";
import { HOSTNAME } from "../../../constant";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const HeaderBursa = (props) => {
  const location = useLocation();
  const path = location.pathname.split("/").pop();

  const [itemCount, setItemCount] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart } = useCart();
  const auth = useSelector((state) => state.auth);

  const navItems = [
    { label: "Kartu Soal", path: "kartu-soal" },
    { label: "Paket Soal", path: "paket-soal" },
    { label: "TryOut", path: "tryout" },
    { label: "Bahan Ajar", path: "bahan-ajar" },
    { label: "Paket Ajar", path: "paket-ajar" },
    { label: "Pembelajaran", path: "pembelajaran" },
  ];

  // Function to toggle navbar collapse state
  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    setItemCount(cart.reduce((acc, item) => acc + item.quantity, 0));
  }, [cart]);

  const handleLogin = () => {
    checkAutoLogin(dispatch, navigate, true);
  };

  const handleButtonPersonal = () => {
    if (props.user.group_code == "GU") {
      navigate("/dashboard");
    } else {
      Swal.fire({
        title: "Oops !",
        text: "Mohon maaf, anda tidak bisa masuk ke pembuatan Paket Soal dan Bahan Ajar dikarenakan hanya Guru (Gu) yang bisa mengaksesnya, Silahkan ganti dengan akun guru untuk mengaksesnya",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      });
    }
  };

  const handleButtonMyTryout = () => {
    if (props.user.group_code == "SI") {
      navigate("/my-tryout");
    } else {
      Swal.fire({
        title: "Oops !",
        text: "Mohon maaf, anda tidak bisa masuk karena bukan siswa",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      });
    }
  };

  return (
    <>
      <nav className="header-bursa navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={LogoSite} alt="" width={"90%"} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            aria-controls="navbarNav"
            aria-expanded={!isCollapsed ? "true" : "false"}
            aria-label="Toggle navigation"
          >
            <span
              className="navbar-toggler-icon"
              style={{ filter: "invert(1)" }}
            ></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isCollapsed ? "" : "show"}`}
            id="navbarNav"
          >
            <ul className="navbar-nav ms-auto align-items-end align-items-lg-center">
              {navItems.map((item) => (
                <li className="nav-item" key={item.path}>
                  <Link
                    className={`nav-link fs-6 ${
                      path === item.path ? "text-danger" : "fw-light text-light"
                    } p-2 p-lg-0`}
                    to={`/bursa/${item.path}`}
                  >
                    <b>{item.label}</b>
                  </Link>
                </li>
              ))}
              <li class="nav-item">
                {" "}
                <Link className="nav-link" to={"/bursa/keranjang"}>
                  {" "}
                  <b
                    className="text-light"
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <i className="fas fa-shopping-cart fa-2x"></i>
                    {itemCount > 0 && (
                      <span
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          background: "red",
                          color: "white",
                          borderRadius: "50%",
                          padding: "0px 6px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {itemCount}
                      </span>
                    )}
                  </b>{" "}
                </Link>{" "}
              </li>

              {props.isAuthenticated ? (
                <li className="nav-item ">
                  <Dropdown className="dropdown header-profile2">
                    <Dropdown.Toggle
                      variant=""
                      as="a"
                      className="nav-link i-false c-pointer ms-0"
                    >
                      <div className="header-info2 d-flex align-items-center">
                        {props.user?.detail?.profile ? (
                          <img
                            src={
                              props.user.detail.profile.foto_profil
                                ? `${HOSTNAME}/storage/00-universal/${props.user.detail.profile.institusi.website}/${props.user.group_code}/${props.user.detail.profile.foto_profil}`
                                : ProfileDefault
                            }
                            className="ms-0"
                            alt=""
                          />
                        ) : (
                          <img src={ProfileDefault} className="ms-0" alt="" />
                        )}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      align="end"
                      className="mt-1 dropdown-menu dropdown-menu-end"
                    >
                      <div className="card mb-0">
                        <div className="card-header p-3">
                          <ul className="d-flex align-items-center">
                            <li>
                              {props.user?.detail?.profile ? (
                                <img
                                  src={
                                    props.user.detail.profile.foto_profil
                                      ? `${HOSTNAME}/storage/00-universal/${props.user.detail.profile.institusi.website}/${props.user.group_code}/${props.user.detail.profile.foto_profil}`
                                      : ProfileDefault
                                  }
                                  className="ms-0"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={ProfileDefault}
                                  className="ms-0"
                                  alt=""
                                />
                              )}
                            </li>
                            <li className="ms-2">
                              <h4 className="mb-0">{props.user.name}</h4>
                              <span>{props.user.group_code}</span>
                            </li>
                          </ul>
                        </div>
                        <div className="card-body p-3">
                          {props.user.group_code == "GU" && (
                            <div
                              onClick={handleButtonPersonal}
                              style={{ cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                                className="svg-main-icon"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <polygon points="0 0 24 0 24 24 0 24" />
                                  <path
                                    d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    opacity="0.3"
                                  />
                                  <path
                                    d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                    fill="var(--primary)"
                                    fillRule="nonzero"
                                  />
                                </g>
                              </svg>
                              <span className="ms-2">Personal </span>
                            </div>
                          )}
                          {props.user.group_code == "SI" && (
                            <div
                              onClick={() =>
                                window.open(
                                  `${TRYOUT_HOSTNAME}/ujian/list`,
                                  "_blank"
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                                className="svg-main-icon"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <polygon points="0 0 24 0 24 24 0 24" />
                                  <path
                                    d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    opacity="0.3"
                                  />
                                  <path
                                    d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                    fill="var(--primary)"
                                    fillRule="nonzero"
                                  />
                                </g>
                              </svg>
                              <span className="ms-2">Tryout Saya </span>
                            </div>
                          )}
                          {props.user.group_code == "SI" && ( <hr/> )}
                          {props.user.group_code == "SI" && (
                            <div
                              onClick={() =>
                                window.open(
                                  `${BAHANAJAR_HOSTNAME}`,
                                  "_blank"
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                version="1.1"
                                className="svg-main-icon"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <polygon points="0 0 24 0 24 24 0 24" />
                                  <path
                                    d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    opacity="0.3"
                                  />
                                  <path
                                    d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                    fill="var(--primary)"
                                    fillRule="nonzero"
                                  />
                                </g>
                              </svg> */}
                              <span className="ms-2">Media Belajar</span>
                            </div>
                          )}
                        </div>
                        <div className="card-footer text-center p-3">
                          <LogoutPage />
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              ) : (
                <li class="nav-item" onClick={() => handleLogin()}>
                  {" "}
                  <b className="btn btn-outline-light text-light">
                    Log In
                  </b>{" "}
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    user: state.auth.auth,
  };
};

export default withRouter(connect(mapStateToProps)(HeaderBursa));
