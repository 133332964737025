import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Dropdown,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import swal from "sweetalert";
import { nanoid } from "nanoid";
import { IMAGES } from "../Dashboard/Content";
import CardPaketSoal from "./ShowPage/Card";
import TablePaketSoal from "./ShowPage/Table";
import axiosInstance from "../../../services/AxiosInstance";
import Filter from "../../customs/Filter/filter";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { showLoading, closeLoading } from "../../customs/loading/loading";
import Select from "react-select";
import SearchInput from "../../customs/Filter/searchInput";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const PaketSoal = (props) => {
  const [contacts, setContacts] = useState([]);
  const [showStyle, setShowStyle] = useState("card");
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(16);
  const [query, setQuery] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filterTypePlatform, setFilterTypePlatform] = useState(null);
  const [typeOpt, setTypeOpt] = useState([
    { value: "test-online/usbk", label: "Test Online/USBK" },
    { value: "tryout", label: "Tryout" },
  ]);

  useEffect(() => {
    prepData();
  }, [query, currentPage, filterTypePlatform, searchKeyword]);

  const prepData = async () => {
    try {
      showLoading();
      await loadData();
    } catch (error) {
      console.log(error);
    } finally {
      closeLoading();
    }
  };

  const loadData = async () => {
    try {
      const response = await axiosInstance.get(
        `paket-soal?page=${currentPage}&per_page=${perPage}
        ${
          filterTypePlatform != null
            ? "&is_bursa=1&type=" + filterTypePlatform.value
            : ""
        }
        ${query != null ? query : ""}
        ${searchKeyword != '' ? '&search='+searchKeyword : ""}`
      );
      var data = response.data.data;
      var tmp = [];
      for (var i = 0; i < data.length; i++) {
        var date = new Date(data[i].created_at);
        const formattedDate = `${String(date.getDate()).padStart(
          2,
          "0"
        )}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${date.getFullYear()}`;
        var type = getNonZeroKeys(data[i].score);
        var tmpType = [];
        for (var j = 0; j < type.length; j++) {
          tmpType.push({ name: type[j], color: getColorByTypeSoal(type[j]) });
        }
        tmp.push({
          id: data[i].id,
          kode_paket: data[i].code,
          paket_soal_name: data[i].detail.judul,
          author: props.user.name,
          jenis_soal: tmpType,
          is_draft: data[i].is_draft,
          is_bursa: data[i].is_bursa,
          created_at: formattedDate,
          original: data[i],
        });
      }
      setContacts(tmp);
      setTotal(response.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const getNonZeroKeys = (score) => {
    return Object.keys(score).filter((key) => {
      return Object.values(score[key]).every((value) => value !== 0);
    });
  };

  const getColorByTypeSoal = (type) => {
    switch (type) {
      case "PG":
        return "primary";
      case "SA":
        return "warning";
      case "BS":
        return "success";
      case "UR":
        return "danger";
      default:
        return "primary";
    }
  };

  const onDelete = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus",
      text: "Apakah anda yakin ingin menghapus?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`paket-soal/${id}`);

          Swal.fire("Terhapus!", "Data telah terhapus.", "success");

          loadData();
        } catch (error) {
          let errorMessage = "An error occurred.";
          if (error.response) {
            errorMessage = error.response.data.message || error.response.data;
          } else if (error.request) {
            errorMessage =
              "No response from server. Please check your connection.";
          } else {
            errorMessage = error.message;
          }

          Swal.fire({
            title: "Oops",
            text: errorMessage,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const handlePaging = (page) => {
    setCurrentPage(page);
  };

  // handle edit bobot soal
  const [showModal, setShowModal] = useState(false); // Kontrol modal
  const [selectedPaketSoal, setSelectedPaketSoal] = useState(null);

  const handleEditBobot = (item) => {
    console.log("item:", item);
    setSelectedPaketSoal(item.original);
    Object.keys(item.original.score).forEach((key) => {
      if (item.original.score[key].jumlah_soal !== 0) {
        handleActive(key);
      }
    });
    setFormValues(item.original.score);
    setShowModal(true);
  };

  const [activeTabs, setActiveTabs] = useState([]); // Menggunakan array untuk menyimpan tab aktif
  const [formValues, setFormValues] = useState({
    PG: {
      jumlah_soal: 0,
      bobot: 0,
      point_benar: 0,
      point_salah: 0,
      point_kosong: 0,
    },
    SA: {
      jumlah_soal: 0,
      bobot: 0,
      point_benar: 0,
      point_salah: 0,
      point_kosong: 0,
    },
    UR: {
      jumlah_soal: 0,
      bobot: 0,
      point_benar: 0,
      point_salah: 0,
      point_kosong: 0,
    },
    PJ: {
      jumlah_soal: 0,
      bobot: 0,
      point_benar: 0,
      point_salah: 0,
      point_kosong: 0,
    },
    BS: {
      jumlah_soal: 0,
      bobot: 0,
      point_benar: 0,
      point_salah: 0,
      point_kosong: 0,
    },
  });

  const getTypeName = (type) => {
    if (type == "PG") {
      return "Pilihan Ganda";
    } else if (type == "BS") {
      return "Benar Salah";
    } else if (type == "UR") {
      return "Uraian";
    } else if (type == "PJ") {
      return "Penjodohan";
    } else if (type == "SA") {
      return "Sebab Akibat";
    }
  };

  const handleActive = (tab) => {
    setActiveTabs(
      (prevActiveTabs) =>
        prevActiveTabs.includes(tab)
          ? prevActiveTabs.filter((t) => t !== tab) // Jika sudah aktif, nonaktifkan
          : [...prevActiveTabs, tab] // Jika belum aktif, tambahkan ke tab aktif
    );
  };

  const handleInput = (e, tab, key) => {
    const { value } = e.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [tab]: {
        ...prevFormValues[tab],
        [key]: value,
      },
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    console.log("validate form tab :", activeTabs);
    activeTabs.forEach((tab) => {
      newErrors[tab] = {};
      var formValue = formValuesRef.current;
      console.log("tengah validate form :", tab, formValue[tab]);
      Object.keys(formValue[tab]).forEach((key) => {
        console.log(
          "validate form :",
          key,
          formValue[tab],
          formValue[tab][key]
        );
        if (!nullableField.includes(key) && formValue[tab][key] == 0) {
          isValid = false;
          newErrors[tab][key] = `${key} is required`;
        }
      });
    });

    setErrors(newErrors);
    return {
      isValid: isValid,
      errors: newErrors,
    };
  };

  const [errors, setErrors] = useState({
    PG: {},
    SA: {},
    UR: {},
    PJ: {},
    BS: {},
  });

  useEffect(() => {
    formValuesRef.current = formValues;
  }, [activeTabs, formValues, errors]);

  const formValuesRef = useRef(formValues);

  const nullableField = ["point_salah", "point_kosong"];

  const handleQueryFilter = (query) => {
    const params = Object.entries(query)
      .filter(([_, value]) => value !== null)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    const queryString = params ? `&${params}` : "";
    setQuery(queryString);
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="page-title flex-wrap">
          <div>
            <SearchInput
              type="text"
              className="form-control"
              placeholder="Search here..."
              onChangeForm={(e) => setSearchKeyword(e)}
            />
          </div>
          <div className="d-flex align-items-center">
            <Select
              className="basic-single me-2 mt-1"
              classNamePrefix="select"
              value={filterTypePlatform}
              isRtl={false}
              name="color"
              options={typeOpt}
              placeholder={"Pilih Filter Platform"}
              isClearable={true}
              onChange={(e) => setFilterTypePlatform(e ?? null)}
            />
            <ul
              as="ul"
              className="nav nav-tabs dzm-tabs me-2"
              id="myTab"
              role="tablist"
            >
              <li as="li" className="nav-item" role="presentation">
                <button
                  className={`nav-link ${showStyle == "card" ? "active" : ""}`}
                  type="button"
                  onClick={() => setShowStyle("card")}
                >
                  <i className="fas fa-address-card"></i>
                </button>
              </li>
              <li as="li" className="nav-item">
                <button
                  className={`nav-link ${showStyle == "list" ? "active" : ""}`}
                  type="button"
                  onClick={() => setShowStyle("list")}
                >
                  <i className="fas fa-list"></i>
                </button>
              </li>
            </ul>

            <Filter onChangeForm={handleQueryFilter} />
            <Link
              type="button"
              className="btn btn-primary"
              to={"/paket-soal/action/add"}
            >
              + Buat Paket Soal
            </Link>
          </div>
        </div>
      </div>
      {/* {contacts} */}
      {showStyle == "card" ? (
        <CardPaketSoal
          contacts={contacts}
          onBobot={handleEditBobot}
          onBursa={loadData}
          onDelete={onDelete}
          total={total}
          perPage={perPage}
          onPaging={handlePaging}
        />
      ) : (
        <TablePaketSoal
          contacts={contacts}
          onBobot={handleEditBobot}
          onBursa={loadData}
          onDelete={onDelete}
          total={total}
          perPage={perPage}
          onPaging={handlePaging}
        />
      )}

    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("mapStateToProps butirsoal", state);
  return {
    isAuthenticated: isAuthenticated(state),
    user: state.auth.auth,
  };
};

export default withRouter(connect(mapStateToProps)(PaketSoal));
