import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import { closeLoading, showLoading } from "../../../customs/loading/loading";
import Pagination from "../../Pagination";

const StatistikPaketSoal = () => {
  const { id_paket } = useParams();
  const [dataStatistik, setDataStatistik] = useState([]);
  const [dataPaketSoal, setDataPaketSoal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(16);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  const detailUjian = async () => {
    var res = await axiosInstance.get(
      `paket-soal/${id_paket}?with_content=true`
    );
    var data = res.data.data;
    setDataPaketSoal(data);
    return data;
  };

  // use effect
  useEffect(() => {
    prepData();
  }, []);

  const prepData = async () => {
    showLoading();
    try {
      const rest = await detailUjian();
      await getStatistik(rest.code);
      await closeLoading();
    } catch (error) {
      await closeLoading();
    }
  };

  const getStatistik = async (code) => {
    try {
      const response = await axiosInstance.get(
        `statistik/paket-soal/${code}?page=${currentPage}&per_page=${perPage}`
      );
      const data = response.data.data;
      setDataStatistik(data);
      setTotal(response.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const onPaging = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Detail Paket</h3>
        </div>
        <div className="card-body">
          <div className="col-12">
            {dataPaketSoal != null && (
              <div className="col-12">
                <table className="w-100 nm">
                  <tr>
                    <td className="p-2 text-dark">
                      <b>Perangkai</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataPaketSoal.created_by_user.name}</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>ID Paket Soal</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataPaketSoal.code}</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>Jenjang</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataPaketSoal.detail.jenjang}</b>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 text-dark">
                      <b>Kelas</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataPaketSoal.detail.kelas}</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>Jurusan</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataPaketSoal.detail.jurusan}</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>Mata Pelajaran</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataPaketSoal.detail.mata_pelajaran}</b>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2 text-dark">
                      <b>Nama Paket Ujian</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataPaketSoal.detail.judul}</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>Durasi</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataPaketSoal.detail.durasi / 60} (menit)</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>Platform</b>
                    </td>
                    <td className="p-2 text-dark">
                      <b>: {dataPaketSoal.type}</b>
                    </td>
                  </tr>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            {dataPaketSoal != null ? (
              <>
                <table className="table table-bordered table-stripped table-hover">
                  <thead>
                    <tr className="text-center">
                      <th rowSpan="2">No.</th>
                      <th rowSpan="2">ID Daftar Ujian</th>
                      <th rowSpan="2">ID Paket Soal</th>
                      <th rowSpan="2">NPSN</th>
                      <th rowSpan="2">Nama Sekolah</th>
                      <th rowSpan="2">Jen</th>
                      <th rowSpan="2">Jur</th>
                      <th rowSpan="2">Kls</th>
                      <th rowSpan="2">Jur</th>
                      <th rowSpan="2">Rombel</th>
                      <th rowSpan="2">Waktu Ujian</th>
                      <th rowSpan={2}>Jml</th>
                      <th colSpan="10">Sebaran Nilai</th>
                      <th
                        colSpan={
                          dataPaketSoal.topik != null && dataPaketSoal.topik
                            ? dataPaketSoal.topik.length
                            : 1
                        }
                      >
                        Skor rerata nilai per topik
                      </th>
                      <th
                        colSpan={
                          dataPaketSoal.topik != null && dataPaketSoal.topik
                            ? dataPaketSoal.topik.length
                            : 1
                        }
                      >
                        Capain rerata nilai dalam % per topik
                      </th>
                      <th
                        colSpan={
                          dataPaketSoal.topik != null && dataPaketSoal.topik
                            ? dataPaketSoal.topik.length
                            : 1
                        }
                      >
                        Capain rerata memenuhi KKM Per Topik
                      </th>
                      <th rowSpan="2">KKM</th>
                      <th rowSpan="2">Status</th>
                      {dataPaketSoal.score &&
                        Object.entries(dataPaketSoal.score).length > 0 &&
                        Object.entries(dataPaketSoal.score).map(
                          ([key, value], index) =>
                            value.jumlah_soal > 0 && (
                              <>
                                <th colSpan={6}>{key}</th>
                              </>
                            )
                        )}
                    </tr>
                    <tr>
                      <th>10%</th>
                      <th>20%</th>
                      <th>30%</th>
                      <th>40%</th>
                      <th>50%</th>
                      <th>60%</th>
                      <th>70%</th>
                      <th>80%</th>
                      <th>90%</th>
                      <th>100%</th>
                      {dataPaketSoal.topik != null &&
                      dataPaketSoal.topik.length > 0 ? (
                        dataPaketSoal.topik.map((value, index) => (
                          <th>{value.code}</th>
                        ))
                      ) : (
                        <th>Tidak ada topik</th>
                      )}
                      {dataPaketSoal.topik != null &&
                      dataPaketSoal.topik.length > 0 ? (
                        dataPaketSoal.topik.map((value, index) => (
                          <th>{value.code}</th>
                        ))
                      ) : (
                        <th>Tidak ada topik</th>
                      )}
                      {dataPaketSoal.topik != null &&
                      dataPaketSoal.topik.length > 0 ? (
                        dataPaketSoal.topik.map((value, index) => (
                          <th>{value.code}</th>
                        ))
                      ) : (
                        <th>Tidak ada topik</th>
                      )}
                      {dataPaketSoal.score &&
                        Object.entries(dataPaketSoal.score).length > 0 &&
                        Object.entries(dataPaketSoal.score).map(
                          ([key, value], index) =>
                            value.jumlah_soal > 0 && (
                              <>
                                <th>KR.20</th>
                                <th>Status</th>
                                <th>KR.21</th>
                                <th>Status</th>
                                <th>Valid</th>
                                <th>Invalid</th>
                              </>
                            )
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {dataStatistik.length > 0 ? (
                      dataStatistik.map((value, index) => (
                        <tr>
                          <td>{value.id}</td>
                          <td>{value.code}</td>
                          <td>{value.ujian.paket_soal_code}</td>
                          <td>{value.ujian.code}</td>
                          <td>{value.ujian.created_by_user.detail.npsn}</td>
                          <td>
                            {
                              value.ujian.created_by_user.detail.profile
                                .institusi.nama_institusi
                            }
                          </td>
                          <td>
                            {
                              value.ujian.created_by_user.detail.profile
                                .institusi.jenjang.jenjang
                            }
                          </td>
                          <td>{value.ujian.detail.kelas}</td>
                          <td>{"-"}</td>
                          <td>{value.ujian.detail.rombel}</td>
                          <td>{value.ujian.started_at}</td>
                          <td>{dataPaketSoal.value.length}</td>
                          <td>{value.value.sebaran_nilai["10"] ?? 0}</td>
                          <td>{value.value.sebaran_nilai["20"] ?? 0}</td>
                          <td>{value.value.sebaran_nilai["30"] ?? 0}</td>
                          <td>{value.value.sebaran_nilai["40"] ?? 0}</td>
                          <td>{value.value.sebaran_nilai["50"] ?? 0}</td>
                          <td>{value.value.sebaran_nilai["60"] ?? 0}</td>
                          <td>{value.value.sebaran_nilai["70"] ?? 0}</td>
                          <td>{value.value.sebaran_nilai["80"] ?? 0}</td>
                          <td>{value.value.sebaran_nilai["90"] ?? 0}</td>
                          <td>{value.value.sebaran_nilai["100"] ?? 0}</td>
                          {value.value.score_rerata_nilai_per_topik &&
                          Object.entries(
                            value.value.score_rerata_nilai_per_topik
                          ).length > 0 ? (
                            Object.entries(
                              value.value.score_rerata_nilai_per_topik
                            ).map(([key, value], index) => <td>{value}</td>)
                          ) : (
                            <td>-</td>
                          )}
                          {value.value
                            .score_rerata_presentase_nilai_per_topik &&
                          Object.entries(
                            value.value.score_rerata_nilai_per_topik
                          ).length > 0 ? (
                            Object.entries(
                              value.value.score_rerata_nilai_per_topik
                            ).map(([key, value], index) => <td>{value}</td>)
                          ) : (
                            <td>-</td>
                          )}
                          {value.value.capain_rerata_memenuhi_kkm_per_topik &&
                          Object.entries(
                            value.value.capain_rerata_memenuhi_kkm_per_topik
                          ).length > 0 ? (
                            Object.entries(
                              value.value.capain_rerata_memenuhi_kkm_per_topik
                            ).map(([key, value], index) => <td>{value}</td>)
                          ) : (
                            <td>-</td>
                          )}
                          <td>{value.value.kkm}</td>
                          <td>{value.value.status}</td>
                          {value.value.reabilitas &&
                            Object.entries(value.value.reabilitas).length > 0 &&
                            Object.entries(value.value.reabilitas).map(
                              ([key, value], index) => (
                                <>
                                  <td>{value["kr20"]}</td>
                                  <td>{value["kr20_kategori"]}</td>
                                  <td>{value["kr21"]}</td>
                                  <td>{value["kr20_kategori"]}</td>
                                  <td>{value["jumlah_valid"]}</td>
                                  <td>{value["jumlah_invalid"]}</td>
                                </>
                              )
                            )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={28}>Tidak ada data</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  totalData={total}
                  dataPerPage={perPage}
                  onChange={onPaging}
                />
              </>
            ) : (
              <div className="col-12 text-center">
                <p>Tidak ada data</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatistikPaketSoal;
