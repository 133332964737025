import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import Preview from "../DaftarUjian/Steps/Preview";
import axiosInstance from "../../../services/AxiosInstance";
import './preview.css';
import axiosBahanAjar from "../../../services/axiosBahanAjar";
const PreviewPaketAjar = () => {
  const { id_paket } = useParams();
  const [soal, setSoal] = useState([]);

  const [bentukSoal, setBentukSoal] = useState([]);

  const [totalSoal, setTotalSoal] = useState(0);
  const [totalBobot, setTotalBobot] = useState(0);

  const [detailPaket, setDetailPaket] = useState(null);

  const getTypeName = (type) => {
    if (type == "PG") {
      return "pilihan-ganda";
    } else if (type == "MPG") {
      return "multiple-pilihan-ganda";
    } else if (type == "BS") {
      return "benar-salah";
    } else if (type == "UR") {
      return "uraian";
    } else if (type == "PJ") {
      return "penjodohan";
    } else if (type == "SA") {
      return "sebab-akibat";
    }
  };

  const getDataById = async () => {
    var res = await axiosBahanAjar.get(`paket-ajar/${id_paket}?with_content=true`);
    console.log("res getDataById : ", res);

    setDetailPaket(res.data);
    var paketAjar = res.data.bahan_ajar_id_list;
    var tmp = []
    for (var i = 0; i < paketAjar.length; i++) {
      var items = paketAjar[i].items;
      for (var j = 0; j < items.length; j++) {
        tmp.push(items[j]); 
      }
    }

    console.log("tmp:", tmp);
    setSoal(tmp);
  };

  useEffect(() => {
    getDataById();
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header card-header-komposisi d-flex justify-content-between flex-wrap">
              <div>
                <div className="card-title">Komposisi Bahan Ajar</div>
              </div>
              <div>
                <span>
                  <i className={`fas fa-chevron-down`}></i>
                </span>
              </div>
            </div>
            { detailPaket == null ? "" : (
              
            
            <div className="card-body">
              <div className="row">
                <div className="col-4">
                  <table className="w-100 nm">
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Perangkai</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.creator}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Kode Ujian</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.id_paket_ajar}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Jenjang</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.detail.jenjang}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Kelas</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.detail.kelas}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Jurusan</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.detail.jurusan}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Mata Pelajaran</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.detail.mata_pelajaran}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Nama Paket Ajar</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.topic}</b>
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="p-2 text-dark">
                        <b>Durasi</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.detail.durasi / 60} (menit)</b>
                      </td>
                    </tr> */}
                    {/* <tr>
                      <td className="p-2 text-dark">
                        <b>Platform</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {detailPaket.type}</b>
                      </td>
                    </tr> */}
                  </table>
                </div>
                {/* <div className="col-8">
                  <div className="table">
                    <div id="job_data" className="dataTables_wrapper">
                      <table
                        className="display w-100 dataTable table-bordered"
                        id="example5"
                        role="grid"
                        aria-describedby="example5_info"
                      >
                        <thead>
                          <tr role="row">
                            <th>Bentuk Soal</th>
                            <th>Jumlah Soal</th>
                            <th>Bobot</th>
                            <th>Benar</th>
                            <th>Salah</th>
                            <th>Tidak Jawab</th>
                          </tr>
                        </thead>

                        <tbody>
                          {bentukSoal.map((v, i) => (
                            <tr key={i}>
                              <td>{v.type}</td>
                              <td>{v.jumlah_soal}</td>
                              <td>{v.bobot}</td>
                              <td>{v.point_benar}</td>
                              <td>{v.point_salah}</td>
                              <td>{v.point_kosong}</td>
                            </tr>
                          ))}

                          <tr>
                            <td>Jumlah Soal</td>
                            <td>{totalSoal}</td>
                            <td colSpan={5}>{totalBobot}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-sm-12 mt-4">
                  <div className="row">
                    <div className="col-5">
                      {" "}
                      <hr />
                    </div>
                    <div className="col-2 text-center">
                      <h5 className="text-primary mt-1">Preview Paket Ajar</h5>
                    </div>
                    <div className="col-5">
                      {" "}
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <section>
                    {soal.map((v, i) => (
                      <div key={i}>
                        <div className="row mt-5">
                          <div className="col-1 text-center">
                            <b>{i + 1}.</b>
                          </div>
                          <div className="col-11">
                            <div className="col-soal mb-3">
                              <div dangerouslySetInnerHTML={{__html: v.topic}}></div>
                            </div>
                            {v.media == 'video' && (
                              <div className="col-soal mb-3">
                                <video width={"350px"} controls>
                                  <source src={v.media_url} type="video/mp4" />
                                </video>
                              </div>
                            )}

                            {v.media == 'audio' && (
                              <div className="col-soal mb-3">
                                <audio width={"350px"} controls>
                                  <source src={v.media_url} type="audio/mpeg" />
                                </audio>
                              </div>
                            )}

                            <span dangerouslySetInnerHTML={{__html: v.description}}></span>
                          </div>
                      </div>
                      </div>
                    ))}
                    <div className="row">
                      <div className="col-12">
                        <hr />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewPaketAjar;
