import React from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useCart } from "../../../services/CartContext";
import HeaderBursa from "./HeaderBursa";
import Swal from "sweetalert2";
import { useLoading } from "../Global/LoadingProvider";
import axiosInstance from "../../../services/AxiosInstance";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
import { connect } from "react-redux";
import "./Cart.css"; // Jika ingin menambahkan CSS kustom untuk spinner

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
const Cart = (props) => {
  const { cart, dispatch } = useCart();
  const { setLoading } = useLoading();

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleRemove = (id) => {
    dispatch({ type: "REMOVE_FROM_CART", payload: id });
  };

  const checkoutTransaction = async (data) => {
    try {
      const response = await axiosInstance.post("/transaction", data);

      Swal.fire({
        title: "Berhasil",
        text: "Checkout berhasil",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      let errorMessage = "An error occurred.";
      if (error.response) {
        errorMessage = `${error.response.data.message || error.response.data}`;
      } else if (error.request) {
        errorMessage = "No response from server. Please check your connection.";
      } else {
        errorMessage = error.message;
      }

      // Display the error message in a SweetAlert2 popup
      Swal.fire({
        title: "Oops",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const checkoutTransactionBa = async (data) => {
    try {
      const response = await axiosInstance.post("/api-ba/pembelian", [data]);

      Swal.fire({
        title: "Berhasil",
        text: "Checkout berhasil",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      let errorMessage = "An error occurred.";
      if (error.response) {
        errorMessage = `${error.response.data.message || error.response.data}`;
      } else if (error.request) {
        errorMessage = "No response from server. Please check your connection.";
      } else {
        errorMessage = error.message;
      }

      // Display the error message in a SweetAlert2 popup
      Swal.fire({
        title: "Oops",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const getTypePembelian = (jenis) => {
    switch (jenis) {
      case "kartu-soal":
        return "soal";
      case "paket-ajar":
        return "paketAjar";
      case "bahan-ajar":
        return "bahanAjar";
      default:
        return jenis;
    }
  };

  const handleCheckout = () => {
    if (!props.isAuthenticated) {
      Swal.fire({
        title: "Oops",
        text: "Silahkan login terlebih dahulu",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    setLoading(true);
    cart.forEach(async (e) => {
      var jenisCart = getTypePembelian(e.jenis);
      if (jenisCart == "paket-soal" || jenisCart == "soal") {
        var params = {
          type: jenisCart,
          content_id: e.id,
        };
        await checkoutTransaction(params);
      } else {
        var paramsBa = {
          target_type: jenisCart,
          target_id: e.id,
        };
        await checkoutTransactionBa(paramsBa);
      }
    });
    setLoading(false);

    dispatch({ type: "CLEAR_CART" });
    // if(calculateTotal() > 0){
    //   Swal.fire({
    //     title: 'Oops',
    //     text: 'Saat ini metode pembayaran sedang dalam proses pengembangan',
    //     icon: 'error',
    //     confirmButtonText: 'OK'
    //   })
    // }
  };

  return (
    <>
      <HeaderBursa />
      <Container>
        <div className="card mt-4">
          <div className="card-header">
            <h4 className="card-title">Shopping Cart</h4>
          </div>
          <div className="card-body">
            <Row>
              <Col lg={8} md={8}>
                <div className="table-responsive">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Tipe</th>
                        <th>Item</th>
                        <th>Harga</th>
                        <th>Qty</th>
                        <th>Subtotal</th>
                        <th>Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.length > 0 ? (
                        cart.map((item, index) => (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.jenis}</td>
                            <td className="text-truncate">{item.judul}</td>
                            <td>{item.price}</td>
                            <td>{item.quantity}</td>
                            <td>{item.price * item.quantity}</td>
                            <td>
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => handleRemove(item.id)}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            Keranjang kosong
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="p-3 border rounded">
                  <h4>Ringkasan Keranjang</h4>
                  <p>Total Items: {cart.length}</p>
                  <h5>Total: {calculateTotal()}</h5>
                  <Button
                    variant="primary"
                    className="w-100 mt-3"
                    onClick={handleCheckout}
                  >
                    Checkout
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  console.log("mapStateToProps cart", state);
  return {
    isAuthenticated: isAuthenticated(state),
    user: state.auth.auth,
  };
};

export default withRouter(connect(mapStateToProps)(Cart));
