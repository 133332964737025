import React, { useEffect, useRef, useState } from "react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCart } from "../../../../services/CartContext";
const getColorJenisSoal = (type) => {
  switch (type) {
    case "PG":
      return "primary";
    case "SA":
      return "warning";
    case "BS":
      return "success";
    case "UR":
      return "danger";
    case "PJ":
      return "info";
    default:
      return "primary";
  }
};

const CardBursa = (props) => {
  const prevSoalValue = useRef();
  const { cart, dispatch } = useCart();

  useEffect(() => {}, []);

  const TitleTooltip = ({ title, el }) => {
    const renderTooltip = (props) => (
      <Tooltip id="dynamic-tooltip" {...props}>
        <p className="mb-0" dangerouslySetInnerHTML={{ __html: title }} />
      </Tooltip>
    );

    return (
      <>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <>
            <h5
              className="text-dark text-truncate mb-0"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </>
        </OverlayTrigger>
      </>
    );
  };

  const handleAddToCart = (product) => {
    dispatch({ type: "ADD_TO_CART", payload: { ...product, quantity: 1 } });
  };

  const isInCart = (productId) => {
    return cart.some((item) => item.id === productId);
  };

  return (
    <>
      <div className="card border flex-row flex-lg-column shadow-sm w-100">
        <div className="position-relative w-50 w-lg-100 h-100">
          <div
            className="bg-primary position-absolute mt-3 p-2 rounded-start"
            style={{ right: 0 }}
          >
            {props.type == "paket-soal" && (
              <small className="text-light">
                <i className="fas fa-book"></i>{" "}
                <b>{Object.keys(props.data.data.detail.soal_id_list).length}</b>{" "}
                Soal
              </small>
            )}
          </div>
          {props.type !== "kartu-soal" && (
            <img
              src={props.data.cover}
              className="w-100 img-responsive"
              style={{ height: "237px" }}
              alt=""
            />
          )}
        </div>
        <div className="p-3 w-50 w-lg-100">
          <div className="d-flex justify-content-start mb-1">
            {props.type === "paket-soal" ? (
              <>
                {Object.entries(props.data.score).map(([key, value]) =>
                  value.jumlah_soal >= 1 ? (
                    <span
                      key={key}
                      className={`badge badge-sm me-1 badge-${getColorJenisSoal(
                        key
                      )}`}
                    >
                      {key}
                    </span>
                  ) : null
                )}
              </>
            ) : (
              ""
            )}
          </div>
          <TitleTooltip
            key={props.data.id}
            el={"h4"}
            title={props.data.judul}
          />
          <hr className="my-2" />
          <div className="d-flex justify-content-between text-align-center">
            <div style={{ width: "70%" }}>
              <p className="text-dark text-truncate mb-0">
                <b>SC. {props.data.price}</b>
              </p>
              <small className="text-truncate d-block" style={{ width: "90%" }}>
                By {props.data.created_by}
              </small>
            </div>
            <button
              className="btn btn-primary btn-sm btn-icon"
              onClick={(e) => {
                e.stopPropagation();
                handleAddToCart(props.data);
              }}
              disabled={isInCart(props.data.id)}
            >
              <i className="fa fa-shopping-cart me-1"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardBursa;
