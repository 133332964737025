import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import { TESTONLINE_HOSTNAME } from "../../../../constant";
import Swal from "sweetalert2";
import Pagination from "../../Pagination";
import { formatDateTime } from "../../../utils/dateFormat";
import { Dropdown } from "react-bootstrap";
import { closeLoading, showLoading } from "../../../customs/loading/loading";

const HasilUjianTryout = () => {
  const { typeujian } = useParams();
  const inputEl = useRef(null);

  const navigate = useNavigate();

  const [data, setData] = useState(
    document.querySelectorAll("#job_data tbody tr")
  );

  const [exData, setExData] = useState([]);

  const [perPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // use effect
  useEffect(() => {
    prepData();
  }, [currentPage, typeujian]);

  const prepData = async () => {
    showLoading();
    try {
      await loadData();
      await closeLoading();
    } catch (error) {
      await closeLoading();
    }
  };

  const loadData = async () => {
    try {
      const response = await axiosInstance.get(
        `ujian?per_page=${perPage}&page=${currentPage}&type=tryout`
      );
      var data = response.data.data;
      var tmp = [];
      for (var i = 0; i < data.length; i++) {
        var date = new Date(data[i].created_at);
        const formattedDate = `${String(date.getDate()).padStart(
          2,
          "0"
        )}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${date.getFullYear()}`;

        tmp.push({
          id: data[i].id,
          created_at: formattedDate,
          type: data[i].type,
          id_ujian: data[i].code,
          id_paket: data[i].paket_soal_code,
          nama_ujian: data[i].detail.nama,
          kelas: data[i].detail.kelas,
          rombel: data[i].detail.rombel,
          tanggal: data[i].started_at,
          durasi: data[i].durasi,
          status: data[i].status,
          tempat: data[i].detail.ruangan,
        });
      }
      setExData(tmp);
      setTotalData(response.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaging = (page) => {
    setCurrentPage(page);
  };

  const handleExport = async (v) => {
    try {
      // Meminta file Excel dari backend
      const response = await axiosInstance.get(`ujian/export/${v.id}`, {
        responseType: "arraybuffer", // Pastikan response diterima dalam format arraybuffer
      });

      // Membuat Blob dari data response
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Membuat URL object untuk file Blob
      const url = window.URL.createObjectURL(blob);

      // Membuat elemen anchor untuk memicu unduhan
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx"); // Nama file yang akan diunduh
      document.body.appendChild(link);

      // Memicu klik untuk mengunduh file
      link.click();

      // Membersihkan URL object setelah digunakan
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading Excel file", error);
    }
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-header d-flex justify-content-between flex-wrap">
          <div className="input-group search-area mb-md-0">
            <input
              type="text"
              className="form-control"
              placeholder="Search here..."
            />
            <span className="input-group-text">
              <Link to={"#"}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                    fill="#01A3FF"
                  />
                </svg>
              </Link>
            </span>
          </div>
          {/* <div className="d-flex">
            <Link
              type="button"
              className="btn btn-primary"
              to={"/daftar-ujian/"+ typeujian +"/create"}
            >
              + Buat Ujian
            </Link>
          </div> */}
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable table-bordered"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc">No</th>
                    {/* <th className="sorting">Tipe Ujian</th>
                    <th className="sorting">ID Ujian</th>
                    <th className="sorting">Paket Soal</th> */}
                    <th className="sorting">Waktu</th>
                    <th className="sorting">ID Ujian</th>
                    <th className="sorting">Nama Ujian</th>
                    <th className="sorting">Kelas</th>
                    <th className="sorting">Rombel</th>
                    <th className="sorting">Tgl Ujian</th>
                    <th className="sorting">Durasi</th>
                    {/* <th className="sorting">Tempat</th> */}
                    {/* <th className="sorting text-center">Detail</th> */}
                    <th className="sorting text-center">Status</th>
                    <th className="sorting text-center">Hasil</th>
                    {/* <th className="sorting text-center">Export</th> */}
                  </tr>
                </thead>

                <tbody>
                  {exData.length > 0 ? (
                    exData.map((v, i) => (
                      <tr className={(i + 1) % 2 == 1 ? "odd" : "even"}>
                        <td>{i + 1}</td>
                        <td>{v.created_at}</td>
                        <td>{v.id_paket}</td>
                        <td>{v.nama_ujian}</td>
                        <td>{v.kelas}</td>
                        <td>{v.rombel}</td>
                        <td
                          dangerouslySetInnerHTML={{
                            __html: formatDateTime(v.tanggal),
                          }}
                        ></td>
                        <td>{v.durasi / 60}</td>
                        <td className="text-center">
                          <span
                            className={`badge ${
                              v.status == "finished"
                                ? "bg-success"
                                : "bg-secondary"
                            }`}
                          >
                            {v.status == "finished"
                              ? "Selesai"
                              : v.status == "started"
                              ? "Mulai"
                              : "Belum Mulai"}
                          </span>
                        </td>
                        <td className="d-flex">
                          <Dropdown className="me-1">
                            <Dropdown.Toggle
                              size="sm"
                              variant="warning"
                              id="dropdown-basic"
                            >
                              <i className="fa fa-eye me-1"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Link
                                className="dropdown-item"
                                to={`preview/${v.id}?type_page=properti`}
                              >
                                Properti Paket Ujian
                              </Link>
                              <Link
                                className="dropdown-item"
                                to={`preview/${v.id}?type_page=preview`}
                              >
                                Preview Paket Soal
                              </Link>
                              <Link
                                className="dropdown-item"
                                to={`jawaban-peserta/${v.id}`}
                              >
                                Jawaban Siswa
                              </Link>
                              <Link
                                className="dropdown-item"
                                to={`detail/${v.id}?type_page=jawaban`}
                              >
                                Penilaian Soal Uraian
                              </Link>
                              <Link
                                className="dropdown-item"
                                to={`hasil-ujian-analisis/${v.id}`}
                              >
                                Nilai dan analisis hasil ujian
                              </Link>
                            </Dropdown.Menu>
                          </Dropdown>
                          <Dropdown>
                            <Dropdown.Toggle
                              size="sm"
                              variant="info"
                              id="dropdown-basic"
                            >
                              <i className="fa fa-bar-chart me-1"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Link
                                className="dropdown-item"
                                to={`statistik-realibel/${v.id}`}
                              >
                                Statistik Capaian realibel dan validitas
                              </Link>
                              <Link
                                className="dropdown-item"
                                to={`statistik/${v.id}`}
                              >
                                Statistik Kartu Soal dalam paket Soal
                              </Link>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}>Tidak ada data</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                totalData={totalData}
                dataPerPage={perPage}
                onChange={handlePaging}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HasilUjianTryout;
