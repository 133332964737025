import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { Modal, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import axiosInstance from "../../../../services/AxiosInstance";
import Pagination from "../../Pagination";
import Select from "react-select";
import { closeLoading, showLoading } from "../../../customs/loading/loading";

const CardPaketSoal = (props) => {
  const [show, setShow] = useState(false);

  const handleShow = (paketSoal) => {
    setShow(true);
    setSelectedPaketSoal(paketSoal);
  };
  const handleClose = () => setShow(false);

  const [selectedPaketSoal, setSelectedPaketSoal] = useState(null);
  const [price, setPrice] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [files, setFiles] = useState(null);

  const handleChangeFile = (fl) => {
    setFiles(fl.target.files[0]);
  };

  const uploadAdapter = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axiosInstance.post(`media/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return response.data.url;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Gagal Upload File",
      });
      throw error;
    }
  };

  const handleSubmitBursa = async () => {
    showLoading();
    const formData = {
      image: null,
      type: platform,
    };

    if (files != null) {
      const uploadedFileUrl = await uploadAdapter(files);
      formData.image = uploadedFileUrl;
    }

    var params = {
      price: price,
    };
    var resPrice = await axiosInstance.put(
      `paket-soal/price/${selectedPaketSoal.id}`,
      params
    );
    var resBursa = await axiosInstance.put(
      `paket-soal/activate-bursa/${selectedPaketSoal.id}`,
      formData
    );
    if (resPrice.data && resBursa.data) {
      props.onBursa(true);
      handleClose();
      await closeLoading();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Berhasil Mengubah data",
      });
    } else {
      await closeLoading();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Gagal Mengubah data",
      });
    }
  };

  const navigate = useNavigate();
  const handleLink = (e) => {
    navigate(e);
  };

  const onPaging = (page) => {
    props.onPaging(page);
  };

  return (
    <div className="col-xl-12">
      <div className="row">
        {props.contacts.length > 0 ? (
          props.contacts.map((contact, index) => (
            <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
              <div className="card p-0 contact_list">
                <div className="card-body p-3">
                  <div className="user-content">
                    <div className="user-info">
                      <div className="d-flex justify-content-between align-items-center">
                        <small className="text-muted d-flex justify-content-start align-items-center mb-0">
                          {contact.kode_paket}
                          <p className="mb-0 text-primary">
                            {contact.is_bursa && (
                              <b className="ms-2">
                                {contact.original.type == "test-online/usbk"
                                  ? "(USBK / Online)"
                                  : "(Tryout)"}
                              </b>
                            )}
                          </p>
                        </small>
                        {contact.is_draft ? (
                          <small className="badge badge-sm badge-secondary">
                            Draft
                          </small>
                        ) : contact.is_bursa ? (
                          <small className="badge badge-sm badge-success">
                            Bursa
                          </small>
                        ) : (
                          <small className="badge badge-sm badge-dark">
                            Done
                          </small>
                        )}
                      </div>
                      <div className="user-details">
                        <h4 className="user-name mb-0 text-truncate">
                          {contact.paket_soal_name}
                        </h4>

                        <p className="mb-0 text-truncate">by {contact.author}</p>
                      </div>
                    </div>
                  </div>
                  <div className="contact-icon">
                    {contact.jenis_soal.map((value, key) => (
                      <span className={`badge m-1 badge-${value.color}`}>
                        {value.name}
                      </span>
                    ))}
                  </div>
                  <hr />
                  <div className="d-flex align-items-center justify-content-between">
                    <div
                      className="btn-group btn-group-sm"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        type="button"
                        className="btn btn-outline-primary text-danger"
                        onClick={() => props.onDelete(contact.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                      {/* {contact.is_draft && (
                        <Link
                          to={`/paket-soal/action/update?id=${contact.id}`}
                          className="btn btn-outline-primary text-warning"
                        >
                          <i className="fas fa-pencil-alt me-1"></i> Lanjutkan
                        </Link>
                      )} */}

                      {!contact.is_draft && !contact.is_bursa && (
                        <button
                          type="button"
                          onClick={() => handleShow(contact)}
                          className="btn btn-outline-primary"
                        >
                          <i className="fas fa-plus"></i> Bursa
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() =>
                          handleLink(`/paket-soal/preview/${contact.id}`)
                        }
                      >
                        <i className="fas fa-eye"></i>
                      </button>
                    </div>
                    {!contact.is_used && contact.is_draft && (
                      <Dropdown className="position-relative top-0 bottom-0">
                        <Dropdown.Toggle
                          size="sm"
                          variant="outline-warning"
                          className="ms-2"
                          id="dropdown-basic"
                        >
                          <i className="fa fa-pencil me-1"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Link
                            to={`/paket-soal/edit/properti?id=${contact.id}`}
                            className="dropdown-item"
                          >
                            Properti
                          </Link>
                          <Link
                            to={`/paket-soal/edit/kartu?id=${contact.id}`}
                            className="dropdown-item"
                          >
                            Kartu Soal
                          </Link>
                          <Link
                            to={`/paket-soal/edit/topik?id=${contact.id}`}
                            className="dropdown-item"
                          >
                            Topik
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">Tidak ada data</p>
        )}
      </div>
      <Pagination
        totalData={props.total}
        dataPerPage={props.perPage}
        onChange={onPaging}
      />

      <Modal dialogClassName="modal-md" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Bursa Paket Soal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <b className="mb-3">Sampul</b>
            <input
              type="file"
              className="form-control"
              onChange={handleChangeFile}
              accept="image/*,audio/*,video/*"
            />
          </div>
          <div className="form-group mt-4">
            <h5 className="text-primary">
              Platform <span className="text-danger">*</span>
            </h5>
            <Select
              className="basic-single"
              classNamePrefix="select"
              name="color"
              options={[
                { value: "test-online/usbk", label: "Test Online/USBK" },
                { value: "tryout", label: "Tryout" },
              ]}
              onChange={(e) => setPlatform(e.value)}
            />
          </div>
          <div className="form-group mt-4">
            <b className="mb-3">Harga</b>
            <input
              type="number"
              className="form-control mt-2"
              placeholder="Masukkan Harga Scoin"
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={handleSubmitBursa}
          >
            Masukkan Bursa
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default CardPaketSoal;
