import React, { useEffect, useState } from "react";
import HeaderBursa from "./HeaderBursa";
import noImg from "../../../images/background/no-image.svg";
import FooterBursa from "./FooterBursa";
import Select from "react-select";
import axiosInstance from "../../../services/AxiosInstance";
import { useCart } from "../../../services/CartContext";
import CardPaginate from "./ShowPage/CardPaginate";
import TablePaginate from "./ShowPage/TablePaginate";
import Filter from "../../customs/Filter/filter";
import { showLoading, closeLoading } from "../../customs/loading/loading";
import SearchInput from "../../customs/Filter/searchInput";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import BG from "../../../images/background/bursa_kartu.svg";

const getColorJenisSoal = (type) => {
  switch (type) {
    case "PG":
      return "primary";
    case "SA":
      return "warning";
    case "BS":
      return "success";
    case "UR":
      return "danger";
    case "PJ":
      return "info";
    default:
      return "primary";
  }
};

const loadData = async (currentPage, perPage, searchKeyword, query) => {
  showLoading();
  try {
    const response = await axiosInstance.get(
      `bursa/soal?page=${currentPage}&per_page=${perPage}${
        searchKeyword != null ? "&search=" + searchKeyword : ""
      }${query != null ? query : ""}`
    );
    var data = response.data.data;
    var tmp = [];

    const formatDateSoal = (time) => {
      const date = new Date(time);
      const formattedDate = `${String(date.getDate()).padStart(
        2,
        "0"
      )}-${String(date.getMonth() + 1).padStart(2, "0")}-${date.getFullYear()}`;

      return formattedDate;
    };

    for (var i = 0; i < data.length; i++) {
      tmp.push({
        id: data[i].id,
        code: data[i].code,
        judul: data[i].detail.materi,
        jenis: "Butir Soal",
        type: data[i].type,
        created_by: data[i].created_by_user.name,
        created_at: formatDateSoal(data[i].created_at),
        cover: noImg,
        price: data[i].price,
        data: data[i],
      });
    }
    closeLoading();

    return {
      total: response.data.total,
      data: tmp,
    };
  } catch (error) {
    await Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Soal tidak ditemukan",
    });
    closeLoading();
  }
};

const BursaKartuSoal = (props) => {
  const [perPage, setPerPage] = useState(12);
  const [showStyle, setShowStyle] = useState("card");
  const [show, setShow] = useState(false);
  const [dataDetail, setDataDetail] = useState(null);
  const [jenjang, setJenjang] = useState([
    { value: "PAUD", label: "PAUD" },
    { value: "SD/MI", label: "SD/MI" },
    { value: "SMP/MTs", label: "SMP/MTs" },
    { value: "SMA/MA", label: "SMA/MA" },
    { value: "SMK/MAK", label: "SMK/MAK" },
  ]);

  const [nullSel, setNullSel] = useState([
    { value: "-", label: "Select Option" },
  ]);
  const [konten, setKonten] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [query, setQuery] = useState("");

  useEffect(() => {
    prepData();
  }, [query, currentPage, searchKeyword]);

  const prepData = async () => {
    showLoading();
    try {
      const rest = await loadData(currentPage, perPage, searchKeyword, query);
      setTotal(rest.total);
      setKonten(rest.data);
      await closeLoading();
    } catch (error) {
      await closeLoading();
    }
  };

  const handleDetail = async (id) => {
    showLoading();
    try {
      const response = await axiosInstance.get(
        `bursa/soal/${id}?with_jawaban=true`
      );
      await setDataDetail(response.data.data);
      setShow(true);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  const handlePaging = (page) => {
    setCurrentPage(page);
  };

  const handleQueryFilter = (query) => {
    const params = Object.entries(query)
      .filter(([_, value]) => value !== null)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    const queryString = params ? `&${params}` : "";
    setQuery(queryString);
    setCurrentPage(1);
  };

  const handleSearch = (query) => {
    setSearchKeyword(query);
    setCurrentPage(1);
  };

  return (
    <>
      <HeaderBursa />
      <div className="container min-vh-100">
        <div className="row">
          {/* <div className="col-12 mt-3">
            <div className="breadcumb d-flex">
              <b>Home </b> <span className="ms-1"> / Bursa Kartu Soal</span>
            </div>
          </div> */}

          <div className="col-12 mt-3">
            <div className="card">
              <div className="card-header row">
                <div className="col-sm-12 col-md-4">
                  <h4 className="mb-0">List Bursa Kartu Soal</h4>
                  <p className="text-dark mb-0">
                    <b>{total}</b> Konten Ditemukan !
                  </p>
                </div>
                <div className="col-sm-12 col-md-8 mt-2 mt-lg-0 d-flex justify-content-end align-items-end flex-column flex-md-row">
                  <div className="d-flex justify-content-end">
                    <SearchInput onChangeForm={handleSearch} />
                    <Filter onChangeForm={handleQueryFilter} />
                  </div>
                  <ul
                    as="ul"
                    className="nav nav-tabs dzm-tabs ms-0 mt-lg-0 mt-2 ms-lg-2 "
                    id="myTab"
                    role="tablist"
                    style={{ width: "fit-content" }}
                  >
                    <li as="li" className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          showStyle == "card" ? "active" : ""
                        }`}
                        type="button"
                        onClick={() => setShowStyle("card")}
                      >
                        <i className="fas fa-address-card"></i>
                      </button>
                    </li>
                    <li as="li" className="nav-item">
                      <button
                        className={`nav-link ${
                          showStyle == "list" ? "active" : ""
                        }`}
                        type="button"
                        onClick={() => setShowStyle("list")}
                      >
                        <i className="fas fa-list"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card-body py-2">
                {showStyle === "card" ? (
                  <CardPaginate
                    data={konten}
                    onDetail={handleDetail}
                    onBursa={loadData}
                    total={total}
                    perPage={perPage}
                    type="kartu-soal"
                    onPaging={handlePaging}
                  ></CardPaginate>
                ) : (
                  <TablePaginate
                    data={konten}
                    onDetail={handleDetail}
                    onBursa={loadData}
                    total={total}
                    perPage={perPage}
                    type="kartu-soal"
                    onPaging={handlePaging}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        dialogClassName="modal-lg"
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dataDetail != null ? (
            <>
              <div className="card">
                <div className="card-body py-0">
                  <h3>Informasi</h3>
                  <div className="d-flex justify-content-between">
                    <p>
                      <strong>Code : </strong> {dataDetail.code}
                    </p>
                    <p>
                      <strong>Type : </strong> {dataDetail.type}
                    </p>
                    <p>
                      <strong>Judul : </strong> {dataDetail.detail.materi}
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary"
            onClick={() => setShow(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <FooterBursa />
    </>
  );
};

const BursaMiniKartuSoal = (props) => {
  const [konten, setKonten] = useState([]);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    prepData();
  }, []);

  const prepData = async () => {
    const rest = await loadData(1, 6, "", "");
    setKonten(rest.data);
    setTotal(rest.total);
  };

  return (
    <>
      <div
        className="card mt-2 overflow-hidden"
        style={{ position: "relative" }}
      >
        <img
          src={BG}
          className="d-lg-block d-none"
          style={{
            maxWidth: "55.5%",
            minWidth: "183px",
            left: "-130px",
            top: "-66px",
            position: "absolute",
            objectFit: "cover",
          }}
          alt=""
        />
        <div className="card-body">
          <div className="row">
            <div className="col-lg-7 offset-lg-5 col-md-12">
              <div className="px-2 my-2 d-flex justify-content-between align-items-center flex-column flex-lg-row">
                <div className="text-center text-lg-start">
                  <h3 className="mb-0">Kartu Soal terbaru !</h3>
                  <p className="mb-0">total kartu soal {total}</p>
                </div>
                <button className="btn btn-info btn-sm mt-1 mt-lg-0">
                  Jelajahi lebih banyak  soal disini
                </button>
              </div>
              <div
                className="list-group overflow-auto border rounded"
                style={{ maxHeight: "297px" }}
              >
                {konten != null && konten.length > 0 ? (
                  konten.slice(0, 6).map((soal, index) => (
                    <div
                      key={soal.id}
                      to="#"
                      class="list-group-item list-group-item-action"
                      aria-current="true"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{soal.judul}</h5>
                        <small className="text-primary">
                          {soal.created_at}
                        </small>
                      </div>
                      <p class="mb-1">
                        <small
                          className={`badge badge-sm badge-${getColorJenisSoal(
                            soal.type
                          )}`}
                        >
                          {soal.type}
                        </small>
                        <span className="ms-2">By {soal.created_by}</span>
                      </p>
                    </div>
                  ))
                ) : (
                  <div>Tidak ada kartu soal</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { BursaMiniKartuSoal };
export default BursaKartuSoal;
