import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import axiosInstance from "../../../services/AxiosInstance";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Pagination from "../Pagination";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./AddPaketSoal.css";
import Topik from "./Topik";
import { showLoading, closeLoading } from "../../customs/loading/loading";
import BackButton from "../../customs/Button/BackButton";
import { FilterKartuSoal } from "../../customs/Filter/filter";
import SearchInput from "../../customs/Filter/searchInput";

const ItemType = "row";

const AddPaketSoal = () => {
  const { type_action } = useParams();
  const [searchParams] = useSearchParams();
  const id_paket = searchParams.get("id");

  const navigate = useNavigate();
  const [isClearable, setIsClearable] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRtl, setIsRtl] = useState(false);
  const [step, setStep] = useState("first");

  const [nullSel, setNullSel] = useState([
    { value: "-", label: "Select Option" },
  ]);
  const [showFilter, setShowFilter] = useState(false);

  const [elemenSoal, setElemenSoal] = useState([]);

  const [kelasOpt, setKelasOpt] = useState([]);
  const [selectedKelas, setSelectedKelas] = useState(null);

  const [active, setActive] = useState("pilihan-ganda");
  const [showBtnAdd, setShowBtnAdd] = useState(true);

  const [jurusanOpt, setJurusanOpt] = useState([]);
  const [selectedJurusan, setSelectedJurusan] = useState(null);

  const [typeOpt, setTypeOpt] = useState([
    { value: "test-online/usbk", label: "Test Online/USBK" },
    { value: "tryout", label: "Tryout" },
  ]);

  const [selectedType, setSelectedType] = useState(null);

  const [jenjangOpt, setJenjangOpt] = useState([
    { value: "PAUD", label: "PAUD" },
    { value: "SD/MI", label: "SD/MI" },
    { value: "SMP/MTs", label: "SMP/MTs" },
    { value: "SMA/MA", label: "SMA/MA" },
    { value: "SMK/MAK", label: "SMK/MAK" },
  ]);
  const [selectedJenjang, setselectedJenjang] = useState(null);

  const [semesterOpt, setSemesterOpt] = useState([
    { value: "ganjil", label: "Ganjil" },
    { value: "genap", label: "Genap" },
  ]);
  const [selectedSemester, setSelectedSemester] = useState(null);

  const [selectedMataPelajaran, setSelectedMataPelajaran] = useState(null);

  const [duration, setDuration] = useState(0);
  const [title, setTitle] = useState(null);
  const [indicator, setIndicator] = useState(null);

  const [arrButirSoal, setArrButirSoal] = useState([]);
  const [topics, setTopics] = useState([]);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [excludeSoalId, setExcludeSoalId] = useState([]);

  const [arrSelectedSoal, setArrSelectedSoal] = useState({});
  const [activeTabs, setActiveTabs] = useState(["PG", "SA", "UR", "PJ", "BS"]); // Menggunakan array untuk menyimpan tab aktif
  const [formValues, setFormValues] = useState(null);
  const [query, setQuery] = useState(null);

  useEffect(() => {
    // loadMataPelajaran();
    prepareData();
  }, []);

  const prepareData = async () => {
    await showLoading();
    await loadKurikulum();
    await loadJenjang();
    if (type_action == "update") {
      fetchEditDraftFile();
    } else {
      await fetchSelectLocalStorage();
      setFormValues({
        PG: {
          jumlah_soal: 20,
          bobot: "20",
          point_benar: 2,
          point_salah: -1,
          point_kosong: 0,
          sps: "1.00",
          bb: "20.00%",
          spsp: "1.00%",
        },
        SA: {
          jumlah_soal: 20,
          bobot: 20,
          point_benar: 2,
          point_salah: -1,
          point_kosong: 0,
          sps: "1.00",
          bb: "20.00%",
          spsp: "1.00%",
        },
        UR: {
          jumlah_soal: 20,
          bobot: 20,
          point_benar: 2,
          point_salah: -1,
          point_kosong: 0,
          sps: "1.00",
          bb: "20.00%",
          spsp: "1.00%",
        },
        PJ: {
          jumlah_soal: 20,
          bobot: 20,
          point_benar: 2,
          point_salah: -1,
          point_kosong: 0,
          sps: "1.00",
          bb: "20.00%",
          spsp: "1.00%",
        },
        BS: {
          jumlah_soal: 20,
          bobot: 20,
          point_benar: 2,
          point_salah: -1,
          point_kosong: 0,
          sps: "1.00",
          bb: "20.00%",
          spsp: "1.00%",
        },
      });
    }
    await closeLoading();
    await setIsLoading(false);
  };

  const fetchEditDraftFile = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        var res = await axiosInstance.get(
          `paket-soal/${id_paket}?with_content=true`
        );
        const retrievedValue = res.data.data.detail;
        setTitle(retrievedValue.judul);
        setSelectedType(res.data.data.type);
        setSelectedKurikulum({
          label: retrievedValue.kurikulum,
          value: retrievedValue.kurikulum,
        });
        setselectedJenjang({
          label: retrievedValue.jenjang,
          value: retrievedValue.jenjang,
        });

        // getJurusan(retrievedValue.jenjang);
        // getKelas(retrievedValue.jenjang);
        setSelectedJenisKelompokMataPelajaran({
          label: retrievedValue.jenis_kelompok_mata_pelajaran,
          value: retrievedValue.jenis_kelompok_mata_pelajaran,
        });
        setSelectedKelompokMataPelajaran({
          label: retrievedValue.kelompok_mata_pelajaran,
          value: retrievedValue.kelompok_mata_pelajaran,
        });
        setSelectedMataPelajaran({
          label: retrievedValue.mata_pelajaran,
          value: retrievedValue.mata_pelajaran,
        });

        setSelectedKelas({
          label: retrievedValue.kelas,
          value: retrievedValue.kelas,
        });
        setSelectedJurusan({
          label: retrievedValue.jurusan,
          value: retrievedValue.jurusan,
        });
        setSelectedSemester(retrievedValue.semester);
        setDuration(retrievedValue.durasi / 60);
        setIndicator(retrievedValue.indikator);

        let listSoal = [];
        res.data.data.value.map((soal, index) => {
          const typeName = typeByJenisSoal(soal.type);
          if (!listSoal[typeName]) listSoal[typeName] = [];

          listSoal[typeName].push({
            id: soal.id,
            judul: soal.code,
            kode_butir: soal.code,
            tipe_soal: "Multiple Pilihan Ganda",
            kelas: soal.detail.kelas,
            type_style: typeName,
            groupId: soal.detail.group,
          });
        });

        setTopics(res.data.data.topik);
        setFormValues(res.data.data.score);
        setArrSelectedSoal(listSoal);
        setOrder(Object.keys(listSoal));

        resolve(true);
      } catch (error) {
        reject();
      }
    });
  };

  const fetchSelectLocalStorage = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const retrievedValue = JSON.parse(
          localStorage.getItem("latestValuePaketSoal")
        );
        if (retrievedValue) {
          setTitle(retrievedValue.judul);
          setSelectedType(retrievedValue.type);
          setSelectedKurikulum(retrievedValue.kurikulum);
          setselectedJenjang(retrievedValue.jenjang);

          getJurusan(retrievedValue.jenjang.value);
          getKelas(retrievedValue.jenjang.value);
          loadKelompokMataPelajaran(
            retrievedValue.jenjang.id,
            retrievedValue.kurikulum.value
          );
          setSelectedJenisKelompokMataPelajaran(
            retrievedValue.jenis_kelompok_mata_pelajaran
          );

          loadJenisKelompokMataPelajaran(
            retrievedValue.jenis_kelompok_mata_pelajaran.value,
            retrievedValue.kurikulum.value
          );
          setSelectedKelompokMataPelajaran(
            retrievedValue.kelompok_mata_pelajaran
          );

          loadMataPelajaran(
            retrievedValue.jenis_kelompok_mata_pelajaran.value,
            retrievedValue.kurikulum.value
          );
          setSelectedMataPelajaran(retrievedValue.mata_pelajaran);

          setSelectedKelas(retrievedValue.kelas);
          setSelectedJurusan(retrievedValue.jurusan);
          setSelectedSemester(retrievedValue.semester);
          setDuration(retrievedValue.durasi);
          setIndicator(retrievedValue.indikator);

          // if (retrievedValue.tab) {
          // setActiveTabs(retrievedValue.tab);
          // setFormValues(retrievedValue.formValues);
          // }
        }
        resolve(true);
      } catch (error) {
        reject();
      }
    });
  };

  useEffect(() => {
    getListSoal();
  }, [currentPage, selectedKelas, selectedMataPelajaran, excludeSoalId, query, searchKeyword]);

  const handleQueryFilter = async (newQuery) => {
    const params = Object.entries(newQuery)
      .filter(([_, value]) => value !== null)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    const queryString = params ? `&${params}` : "";
    setQuery(queryString);
  }

  const getListSoal = async () => {
    const res = await axiosInstance.get(
      "soal?page=" +
        currentPage +
        "&per_page=" +
        perPage +
        "&kelas=" +
        (selectedKelas?.value ?? "") +
        "&mata_pelajaran=" +
        (selectedMataPelajaran?.label ?? "") +
        "&materi=" +
        (searchKeyword != null ? searchKeyword : '') +
        (query != null ? query : "") +
        "&exclude=" +
        excludeSoalId.join(",") ?? '' 
    );

    if (res.data) {
      var soal = res.data.data;
      console.log("soal:", soal);
      var tmp = [];
      for (var i = 0; i < soal.length; i++) {
        tmp.push({
          id: soal[i].id,
          judul: soal[i].detail.materi,
          kode_butir: soal[i].code,
          tipe_soal: getTypeName(soal[i].type),
          kelas: soal[i].detail.kelas,
          type_style: typeByJenisSoal(soal[i].type),
          groupId: soal[i].detail.group,
        });
      }

      setArrButirSoal(tmp);
      setTotal(res.data.total);
      // setCurrentPage(res.data.current_page);
    }
  };

  const typeByJenisSoal = (type) => {
    switch (type) {
      case "PG":
        return "pilihan-ganda";
      case "SA":
        return "sebab-akibat";
      case "BS":
        return "benar-salah";
      case "UR":
        return "uraian";
      case "PJ":
        return "penjodohan";
      default:
        return "PG";
    }
  };

  const [mataPelajaranOpt, setMataPelajaranOpt] = useState([]);
  const loadMataPelajaran = async (jenisKelompok, kurikulum) => {
    var response = await axiosInstance.get(
      "select/mata-pelajaran?jenis_kelompok_mata_pelajaran_id=" +
        jenisKelompok +
        "&kurikulum=" +
        kurikulum
    );
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setMataPelajaranOpt(tmp);
    }
  };

  const handleSelectMataPelajaran = (e) => {
    if (e) {
      setSelectedMataPelajaran(e);
    }
  };

  const onPaging = (page) => {
    // getListSoal();
    setCurrentPage(page);
  };

  const updateTable = (tab, key, value) => {
    if (value == null || value == "") value = 0;
    const updatedFormValues = { ...formValues };
    updatedFormValues[tab][key] = value;
    const totalBobot = Object.values(formValues).reduce(
      (sum, type) => sum + parseFloat(type.bobot),
      0
    );

    Object.entries(formValues).forEach(([key, row]) => {
      const jumlahSoal = parseFloat(row.jumlah_soal) || 0;
      const bobotSoal = parseFloat(row.bobot) || 0;
      const sps = jumlahSoal > 0 ? bobotSoal / jumlahSoal : 0;
      const bb = totalBobot > 0 ? parseFloat(bobotSoal / totalBobot) * 100 : 0;
      const spsp = totalBobot > 0 ? parseFloat(bb / jumlahSoal) : 0;

      updatedFormValues[key] = {
        ...row,
        sps: sps.toFixed(2),
        bb: bb.toFixed(2) + "%",
        spsp: spsp.toFixed(2) + "%",
      };
    });
    setFormValues(updatedFormValues);
  };

  const [errors, setErrors] = useState({
    PG: {},
    SA: {},
    UR: {},
    PJ: {},
    BS: {},
  });

  useEffect(() => {
    formValuesRef.current = formValues;
  }, [activeTabs, formValues, errors]);

  const formValuesRef = useRef(formValues);

  const nullableField = ["point_salah", "point_kosong"];

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (title == null) {
      isValid = false;
      newErrors["title"] = "Judul is required";
    }
    if (selectedJenjang == null) {
      isValid = false;
      newErrors["jenjang"] = "Jenjang is required";
    }

    if (selectedMataPelajaran == null) {
      isValid = false;
      newErrors["mapel"] = "Mata Pelajaran is required";
    }

    if (selectedKelas == null) {
      isValid = false;
      newErrors["kelas"] = "Kelas is required";
    }

    if (selectedJurusan == null) {
      isValid = false;
      newErrors["jurusan"] = "Jurusan is required";
    }

    if (selectedSemester == null) {
      isValid = false;
      newErrors["semester"] = "Semester is required";
    }

    if (duration == 0) {
      isValid = false;
      newErrors["duration"] = "Duration is required";
    }

    if (indicator == null) {
      isValid = false;
      newErrors["indicator"] = "Indikator is required";
    }

    if (activeTabs.length == 0) {
      isValid = false;
      newErrors["tab"] = "Komposisi Soal is required";
    }

    setErrors(newErrors);
    return {
      isValid: isValid,
      errors: newErrors,
    };
  };

  const getTypeName = (type) => {
    if (type == "PG") {
      return "Pilihan Ganda";
    } else if (type == "BS") {
      return "Benar Salah";
    } else if (type == "UR") {
      return "Uraian";
    } else if (type == "PJ") {
      return "Penjodohan";
    } else if (type == "SA") {
      return "Sebab Akibat";
    }
  };

  const handleActive = (tab) => {
    setActiveTabs(
      (prevActiveTabs) =>
        prevActiveTabs.includes(tab)
          ? prevActiveTabs.filter((t) => t !== tab) // Jika sudah aktif, nonaktifkan
          : [...prevActiveTabs, tab] // Jika belum aktif, tambahkan ke tab aktif
    );
  };

  const handleInput = (e, tab, key) => {
    const { value } = e.target;
    updateTable(tab, key, value);
  };

  const [kurikulum, setKurikulum] = useState([]);
  const [selectedKurikulum, setSelectedKurikulum] = useState(null);

  const loadKurikulum = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await axiosInstance.get("/select/kurikulum");
        var tmp = [];
        var data = response.data.data;
        for (var i = 0; i < data.length; i++) {
          tmp.push({ value: data[i].value, label: data[i].label });
        }
        setKurikulum(tmp);
        resolve(true);
      } catch (error) {
        reject();
      }
    });
  };

  const loadJenjang = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        var response = await axiosInstance.get("select/jenjang");
        if (response.data) {
          var tmp = [];
          var data = response.data.data;

          for (var i = 0; i < data.length; i++) {
            tmp.push({
              id: data[i].id,
              value: data[i].jenjang,
              label: data[i].jenjang,
            });
          }
          setJenjangOpt(tmp);
        }
        resolve(true);
      } catch (error) {
        reject();
      }
    });
  };

  const [kelompokMataPelajaranOpt, setKelompokMataPelajaranOpt] = useState([]);
  const [selectedKelompokMataPelajaran, setSelectedKelompokMataPelajaran] =
    useState(null);

  const loadKelompokMataPelajaran = async (jenjang, kurikulum) => {
    console.log("loadKelompokMataPelajaran : {}", jenjang);
    var response = await axiosInstance.get(
      "select/kelompok-mata-pelajaran?jenjang_id=" +
        jenjang +
        "&kurikulum=" +
        kurikulum
    );
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadKelompokMataPelajaran data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setKelompokMataPelajaranOpt(tmp);
    }
  };

  const [jenisKelompokMataPelajaranOpt, setJenisKelompokMataPelajaranOpt] =
    useState([]);
  const [
    selectedJenisKelompokMataPelajaran,
    setSelectedJenisKelompokMataPelajaran,
  ] = useState(null);

  const loadJenisKelompokMataPelajaran = async (kelompok, kurikulum) => {
    console.log("loadJenisKelompokMataPelajaran : {}", kelompok);
    var response = await axiosInstance.get(
      "select/jenis-kelompok-mata-pelajaran?kelompok_mata_pelajaran_id=" +
        kelompok +
        "&kurikulum=" +
        kurikulum
    );
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      console.log("loadJenisKelompokMataPelajaran data:", data);
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].id, label: data[i].label });
      }
      setJenisKelompokMataPelajaranOpt(tmp);
    }
  };

  const [disabledSelectSoal, setDisabledSelectSoal] = useState(false);
  const handleSelectedSoal = async (e) => {

    setDisabledSelectSoal(true);
    if (e.tipe_soal == "Penjodohan" || e.tipe_soal == "Sebab Akibat") {
      if (e.groupId != null && e.groupId != "" && e.groupId != undefined) {
        const res = await axiosInstance.get(
          "soal?page=1&per_page=10&group=" + e.groupId
        );

        if (res.data) {
          var soal = res.data.data;
          var tmp = [];
          var ids = [];
          for (var i = 0; i < soal.length; i++) {
            var e = {
              id: soal[i].id,
              judul: soal[i].detail.materi,
              kode_butir: soal[i].code,
              tipe_soal: getTypeName(soal[i].type),
              kelas: soal[i].detail.kelas,
              type_style: typeByJenisSoal(soal[i].type),
              groupId: soal[i].detail.group,
            };
            tmp.push(e);
            ids.push(soal[i].id);
            const exists = (arrSelectedSoal[e.type_style] || []).some(
              (item) => item.id === e.id
            );
            if (exists) {
              setDisabledSelectSoal(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Soal sudah dipilih",
              });
              return;
            }
          }

          setArrSelectedSoal((arrSelectedSoal) => ({
            ...arrSelectedSoal,
            [e.type_style]: [...(arrSelectedSoal[e.type_style] || []), ...tmp],
          }));
          setExcludeSoalId([...excludeSoalId, ...ids]);
        }
      } else {
        setExcludeSoalId([...excludeSoalId, e.id]);
        // setArrSelectedSoal([...arrSelectedSoal, e]);
        if (arrSelectedSoal[e.type_style] == undefined) {
          setArrSelectedSoal({ ...arrSelectedSoal, [e.type_style]: [e] });
          setDisabledSelectSoal(false);
          return;
        }
        const exists = arrSelectedSoal[e.type_style].some(
          (item) => item.id === e.id
        );
        if (exists) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Soal sudah dipilih",
          });
          setDisabledSelectSoal(false);
        } else {
          setArrSelectedSoal((arrSelectedSoal) => ({
            ...arrSelectedSoal,
            [e.type_style]: [...arrSelectedSoal[e.type_style], e],
          }));
        }
      }
    } else {
      setExcludeSoalId([...excludeSoalId, e.id]);
      if (arrSelectedSoal[e.type_style] == undefined) {
        setArrSelectedSoal({ ...arrSelectedSoal, [e.type_style]: [e] });
        console.log("arrSelectedSoal", arrSelectedSoal);

        return;
      }
      const exists = arrSelectedSoal[e.type_style].some(
        (item) => item.id === e.id
      );

      if (exists) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Soal sudah dipilih",
        });
        setDisabledSelectSoal(false);
      } else {
        setArrSelectedSoal((arrSelectedSoal) => ({
          ...arrSelectedSoal,
          [e.type_style]: [...arrSelectedSoal[e.type_style], e],
        }));
        setTimeout(() => {
          setDisabledSelectSoal(false);
        }, 100);
      }
    }

    setTimeout(() => {
      setDisabledSelectSoal(false);
    }, 100);
  };

  const parseAllValuesToFloat = (obj) => {
    const result = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[key] = Object.fromEntries(
          Object.entries(obj[key]).map(([k, v]) => [k, parseFloat(v)])
        );
      }
    }
    return result;
  };

  const handleNext = () => {
    var validate = validateForm();
    if (validate.isValid) {
      const score = {
        PG: formValues.PG,
        SA: formValues.SA,
        UR: formValues.UR,
        PJ: formValues.PJ,
        BS: formValues.BS,
      };

      if (type_action) {
        var floatScore = parseAllValuesToFloat(score);
        let latestValue = {
          type: "test-online/usbk",
          judul: title,
          kurikulum: selectedKurikulum,
          jenjang: selectedJenjang,
          kelas: selectedKelas,
          jurusan: selectedJurusan,
          semester: selectedSemester,
          kelompok_mata_pelajaran: selectedKelompokMataPelajaran,
          jenis_kelompok_mata_pelajaran: selectedJenisKelompokMataPelajaran,
          mata_pelajaran: selectedMataPelajaran,
          durasi: duration,
          indikator: indicator,
          tab: activeTabs,
          formValues: floatScore,
        };
        localStorage.setItem(
          "latestValuePaketSoal",
          JSON.stringify(latestValue)
        );
      }

      setFormValues(floatScore);
      setStep("next");
    } else {
      var text = "";
      for (const [key, value] of Object.entries(validate.errors)) {
        console.log(
          key,
          value,
          typeof value,
          typeof value === "object"
            ? Object.keys(value).length !== 0
            : "bukan object"
        );
        if (typeof value === "object") {
          if (Object.keys(value).length !== 0) {
            text += "<b>" + getTypeName(key) + "</b><br/>";
            for (const [k, v] of Object.entries(value)) {
              console.log(k, v);
              text += v + "<br/>";
            }
          }
        } else {
          text += value + "<br/>";
        }
      }
      Swal.fire({
        title: "Oops !",
        html: text,
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      });
    }
  };

  const handleFormChange = (value, type) => {
    if (type == "type") {
      setSelectedType(value);
    }

    if (type == "title") {
      setTitle(value);
    }

    if (type == "kurikulum") {
      setSelectedKurikulum(value);
    }

    if (type == "jenjang") {
      setselectedJenjang(value);
      loadKelompokMataPelajaran(value.id, selectedKurikulum.value);
      getJurusan(value.value);
      getKelas(value.value);
    }

    if (type == "jenis") {
      setSelectedJenisKelompokMataPelajaran(value);
      loadMataPelajaran(value.value, selectedKurikulum.value);
    }

    if (type == "kelompok") {
      setSelectedKelompokMataPelajaran(value);
      loadJenisKelompokMataPelajaran(value.value, selectedKurikulum.value);
    }

    if (type == "mapel") {
      setSelectedMataPelajaran(value);
    }

    if (type == "kelas") {
      setSelectedKelas(value);
    }

    if (type == "jurusan") {
      setSelectedJurusan(value);
    }

    if (type == "semester") {
      setSelectedSemester(value);
    }

    if (type == "duration") {
      setDuration(value);
    }

    if (type == "indicator") {
      setIndicator(value);
    }
  };

  function capitalizeWords(text) {
    return text
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const getJurusan = async (jenjang) => {
    var response = await axiosInstance.get(
      "/select/jurusan?jenjang=" + jenjang
    );
    if (response.data) {
      var tmp = [];
      var data = response.data.data;
      for (var i = 0; i < data.length; i++) {
        tmp.push({ value: data[i].value, label: data[i].text });
      }
      setJurusanOpt(tmp);
    }
  };

  const getKelas = async (jenjang) => {
    if (jenjang == "SMK/MAK") {
      setKelasOpt([
        { value: "X", label: "X" },
        { value: "XI", label: "XI" },
        { value: "XII", label: "XII" },
        { value: "XIII", label: "XIII" },
      ]);
    } else {
      var response = await axiosInstance.get(
        "/select/kelas?jenjang=" + jenjang
      );
      if (response.data) {
        var tmp = [];
        var data = response.data.data;
        for (var i = 0; i < data.length; i++) {
          tmp.push({ value: data[i].value, label: data[i].text });
        }
        setKelasOpt(tmp);
      }
    }
  };

  const handleSave = async () => {
    if (Object.entries(arrSelectedSoal).length == 0) {
      Swal.fire({
        title: "Oops !",
        text: "Pilih minimal 1 soal",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      });
      return;
    }

    setStep("topik");
  };

  const handleBack = async (step) => {
    setStep(step);
  };

  const handleSaveTopik = async (topik, is_draft) => {
    var no_urut = 1;
    var tmpSoal = {};
    try {
      if (Object.entries(arrSelectedSoal).length > 0) {
        order.map((item, index) => {
          arrSelectedSoal[item].map((item2, index2) => {
            tmpSoal[no_urut] = item2.id;
            no_urut++;
          });
        });
      }

      var params = {
        type: "test-online/usbk",
        is_draft: is_draft,
        detail: {
          judul: title,
          kurikulum: selectedKurikulum.value,
          jenjang: selectedJenjang.label.split("/")[0],
          kelas: selectedKelas.value,
          jurusan: selectedJurusan.value,
          kelompok_mata_pelajaran: selectedKelompokMataPelajaran.label,
          jenis_kelompok_mata_pelajaran:
            selectedJenisKelompokMataPelajaran.label,
          mata_pelajaran: selectedMataPelajaran.label,
          durasi: duration * 60,
          indikator: indicator,
          image: null,
          semester: selectedSemester,
        },
        score: formValues,
        soal_id: tmpSoal,
        topik: topik ?? null,
        // "soal_id": arrSelectedSoal.map((soal) => soal.id),
        // "soal_id": arrSelectedSoal.reduce((acc, soal, index) => {
        //   acc[index + 1] = soal.id;
        //   return acc;
        // }, {})
      };

      const res = id_paket
        ? await axiosInstance.put(`/paket-soal/${id_paket}`, params)
        : await axiosInstance.post("/paket-soal", params);

      await Swal.fire({
        title: "Success !",
        text: "Paket Soal Berhasil",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      });
      await navigate("/paket-soal");
    } catch (error) {
      Swal.fire({
        title: "Oops !",
        text: "Terjadi Kesalahan",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oke !",
      });
    }
  };

  const moveRow = (key, dragIndex, hoverIndex) => {
    setArrSelectedSoal((prevState) => {
      const updatedSoal = [...prevState[key]];
      const [movedItem] = updatedSoal.splice(dragIndex, 1);
      updatedSoal.splice(hoverIndex, 0, movedItem);

      return { ...prevState, [key]: updatedSoal };
    });
  };

  const DraggableRow = ({ item, index, categoryKey, moveRow }) => {
    const ref = React.useRef(null);

    const [, drop] = useDrop({
      accept: "row",
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveRow(categoryKey, draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: "row",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    return (
      <tr
        ref={ref}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
        }}
      >
        <td>{index + 1}</td>
        <td>{item.kode_butir}</td>
        <td>{item.judul}</td>
        <td>
          <button
            className="btn btn-danger btn-sm"
            onClick={() =>
              item.tipe_soal === "Penjodohan" ||
              item.tipe_soal == "Sebab Akibat"
                ? deleteRowGroup(categoryKey, item.id, item.groupId)
                : deleteRow(categoryKey, item.id)
            }
          >
            {item.tipe_soal === "Penjodohan" || item.tipe_soal == "Sebab Akibat"
              ? "Hapus Group"
              : "Hapus"}
          </button>
        </td>
      </tr>
    );
  };

  const formatText = (text) => {
    return text
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const [order, setOrder] = useState();

  useEffect(() => {
    setOrder(Object.keys(arrSelectedSoal));
    setDisabledSelectSoal(false);
  }, [arrSelectedSoal]);
  // Fungsi untuk memindahkan kategori
  const moveCategory = (dragIndex, hoverIndex) => {
    setOrder((prevOrder) => {
      const updatedOrder = [...prevOrder];
      const [movedKey] = updatedOrder.splice(dragIndex, 1);
      updatedOrder.splice(hoverIndex, 0, movedKey);
      return updatedOrder;
    });
  };

  //   return (
  //     <DndProvider backend={HTML5Backend}>
  //       <div>
  //         {order.map((key, index) => (
  //           <DraggableCategory
  //             key={key}
  //             categoryKey={key}
  //             category={arrSelectedSoal[key]}
  //             index={index}
  //             moveCategory={moveCategory}
  //           />
  //         ))}
  //       </div>
  //     </DndProvider>
  //   );
  // };

  const deleteRow = (categoryKey, itemId) => {
    setArrSelectedSoal((prevState) => ({
      ...prevState,
      [categoryKey]: prevState[categoryKey].filter(
        (item) => item.id !== itemId
      ),
    }));
    setExcludeSoalId((prevState) => prevState.filter((id) => id !== itemId));
  };

  const deleteRowGroup = (categoryKey, itemId, groupId) => {
    setArrSelectedSoal((prevState) => ({
      ...prevState,
      [categoryKey]: prevState[categoryKey].filter(
        (item) => item.groupId !== groupId
      ),
    }));
    // setExcludeSoalId((prevState) => prevState.filter((id) => id !== itemId));
    setExcludeSoalId((prevState) =>
      prevState.filter(
        (id) =>
          // Hapus ID yang sesuai dengan groupId
          !arrSelectedSoal[categoryKey]
            .filter((item) => item.groupId === groupId)
            .some((item) => item.id === id)
      )
    );
  };

  const DraggableCategory = ({
    categoryKey,
    category,
    index,
    moveCategory,
    deleteRow,
  }) => {
    const ref = React.useRef(null);

    // Drop target
    const [, drop] = useDrop({
      accept: "category",
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveCategory(draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });

    // Drag source
    const [{ isDragging }, drag] = useDrag({
      type: "category",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    return (
      <div
        ref={ref}
        style={{
          opacity: isDragging ? 0.5 : 1,
          marginBottom: "20px",
          border: "1px solid #ccc",
          padding: "10px",
          backgroundColor: "#f9f9f9",
          cursor: "move",
        }}
      >
        <h4>{formatText(categoryKey)}</h4>
        <table className="table table-responsive table-bordered w-100">
          <thead>
            <tr>
              <th>No</th>
              {/* <th>Bentuk Soal</th> */}
              <th>Kode Soal</th>
              <th>Judul Soal</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {category.map((item, idx) => (
              <DraggableRow
                key={idx}
                categoryKey={categoryKey}
                item={item}
                index={idx}
                moveRow={moveRow}
              />
              // <tr key={item.id}>
              //   <td>{idx + 1}</td>
              //   {/* <td>{item.bentukSoal}</td> */}
              //   <td>{item.kode_butir}</td>
              //   <td>{item.judul}</td>
              //   <td>
              //     <button
              //       className="btn btn-danger btn-sm"
              //       onClick={() => deleteRow(categoryKey, item.id)}
              //     >
              //       Hapus
              //     </button>
              //   </td>
              // </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  const handleCariButir = (e) => {
    console.log("handleCariButir");
    if (currentPage === 1) {
      getListSoal();
    }
    setCurrentPage(1);
  };

  const [arrTopik, setArrTopik] = useState([]);

  const handleTambahTopik = () => {
    setArrTopik([...arrTopik, ""]);
  };
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4>Tambah Paket Soal</h4>
          <div className="card-tool">
            <BackButton />
          </div>
        </div>
        <div className="card-body py-2">
          <div className="d-flex justify-content-between">
            <div
              className={step == "first" ? "active wizard-step" : "wizard-step"}
              id="step1-indicator"
            >
              <div className="step-number">1</div>
              <div>Step Properti Soal</div>
            </div>
            <div
              className={step == "next" ? "active wizard-step" : "wizard-step"}
              id="step2-indicator"
            >
              <div className="step-number">2</div>
              <div>Step Kartu Soal</div>
            </div>
            <div
              className={step == "topik" ? "active wizard-step" : "wizard-step"}
              id="step3-indicator"
            >
              <div className="step-number">3</div>
              <div>Step Topik Soal</div>
            </div>
          </div>
        </div>
      </div>

      {step == "topik" ? (
        <>
          <Topik
            topics={topics}
            arrSelectedSoal={arrSelectedSoal}
            typePage={"add"}
            onSaveButton={handleSaveTopik}
            handleBack={(step) => handleBack(step)}
          />
        </>
      ) : step == "first" ? (
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              {isLoading == false && (
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12 mb-4">
                      <div className="row">
                        <div className="col-4">
                          {" "}
                          <hr />
                        </div>
                        <div className="col-4 text-center">
                          <h4 className="text-primary mb-0">Form General</h4>
                        </div>
                        <div className="col-4">
                          {" "}
                          <hr />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group my-2">
                        <h5 className="text-primary">
                          Judul <span className="text-danger">*</span>
                        </h5>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkan Judul Paket"
                          defaultValue={title ?? ""}
                          onChange={(e) =>
                            handleFormChange(e.target.value, "title")
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group my-2">
                        <h5 className="text-primary">
                          Durasi (Menit)<span className="text-danger">*</span>
                        </h5>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Masukkan durasi"
                          value={duration}
                          onChange={(e) =>
                            handleFormChange(e.target.value, "duration")
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="col-sm-4">
                      <div className="form-group my-2">
                        <h5 className="text-primary">
                          Platform <span className="text-danger">*</span>
                        </h5>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          value={selectedType != null ? {value: selectedType, label: selectedType} : nullSel[0]}                            
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={typeOpt}
                          onChange={(e) => handleFormChange(e.value, "type")}
                        />
                      </div>
                    </div> */}

                    <div className="col-sm-12">
                      <div className="form-group">
                        <h5 className="text-primary">
                          Indikator <span className="text-danger">*</span>
                        </h5>
                        <textarea
                          name=""
                          id=""
                          className="form-control"
                          placeholder="Masukkan indikator paket soal"
                          defaultValue={indicator}
                          onChange={(e) =>
                            handleFormChange(e.target.value, "indicator")
                          }
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-sm-12 mt-5 mb-4">
                      <div className="row">
                        <div className="col-4">
                          {" "}
                          <hr />
                        </div>
                        <div className="col-4 text-center">
                          <h4 className="text-primary mb-0">
                            Form Mata Pelajaran
                          </h4>
                          <small className="text-muted">
                            Isi secara urut dari kiri ke kanan.
                          </small>
                        </div>
                        <div className="col-4">
                          {" "}
                          <hr />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group my-2">
                        <h5 className="text-primary">
                          Kurikulum <span className="text-danger">*</span>
                        </h5>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          value={
                            selectedKurikulum != null
                              ? {
                                  value: selectedKurikulum.value,
                                  label: selectedKurikulum.label,
                                }
                              : nullSel[0]
                          }
                          // defaultValue={nullSel[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={kurikulum}
                          onChange={(e) => handleFormChange(e, "kurikulum")}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4 my-2">
                      <div className="form-group">
                        <h5 className="text-primary">
                          Jenjang <span className="text-danger">*</span>
                        </h5>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          value={
                            selectedJenjang != null
                              ? {
                                  value: selectedJenjang.value,
                                  label: selectedJenjang.label,
                                  id: selectedJenjang.id,
                                }
                              : nullSel[0]
                          }
                          // defaultValue={nullSel[0]}
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={jenjangOpt}
                          onChange={(e) => handleFormChange(e, "jenjang")}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4 my-2">
                      <div className="form-group">
                        <h5 className="text-primary">
                          Kelompok Mata Pelajaran{" "}
                          <span className="text-danger">*</span>
                        </h5>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          value={
                            selectedKelompokMataPelajaran != null
                              ? selectedKelompokMataPelajaran
                              : nullSel[0]
                          }
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={kelompokMataPelajaranOpt}
                          onChange={(e) => handleFormChange(e, "kelompok")}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group my-2">
                        <h5 className="text-primary">
                          Jenis Kelompok Mata Pelajaran{" "}
                          <span className="text-danger">*</span>
                        </h5>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          value={
                            selectedJenisKelompokMataPelajaran != null
                              ? selectedJenisKelompokMataPelajaran
                              : nullSel[0]
                          }
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={jenisKelompokMataPelajaranOpt}
                          onChange={(e) => handleFormChange(e, "jenis")}
                          // value={selectedJenisKelompokMataPelajaran}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group my-2">
                        <h5 className="text-primary">
                          Mata Pelajaran <span className="text-danger">*</span>
                        </h5>
                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Masukkan Mata Pelajaran"
                          onChange={(e) => handleFormChange(e.target.value, "matapelajaran")}
                        /> */}
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          value={
                            selectedMataPelajaran != null
                              ? selectedMataPelajaran
                              : mataPelajaranOpt[0]
                          }
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={mataPelajaranOpt}
                          onChange={(e) => handleFormChange(e, "mapel")}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group my-2">
                        <h5 className="text-primary">
                          Kelas <span className="text-danger">*</span>
                        </h5>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          value={
                            selectedKelas != null
                              ? {
                                  value: selectedKelas.value,
                                  label: selectedKelas.label,
                                }
                              : nullSel[0]
                          }
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={kelasOpt}
                          onChange={(e) => handleFormChange(e, "kelas")}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group my-2">
                        <h5 className="text-primary">
                          Jurusan <span className="text-danger">*</span>
                        </h5>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          value={
                            selectedJurusan != null
                              ? {
                                  value: selectedJurusan.value,
                                  label: selectedJurusan.label,
                                }
                              : nullSel[0]
                          }
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={jurusanOpt}
                          onChange={(e) => handleFormChange(e, "jurusan")}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group my-2">
                        <h5 className="text-primary">
                          Semester <span className="text-danger">*</span>
                        </h5>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          value={
                            selectedSemester != null
                              ? {
                                  value: selectedSemester,
                                  label: selectedSemester,
                                }
                              : nullSel[0]
                          }
                          isDisabled={isDisabled}
                          isLoading={isLoading}
                          isClearable={isClearable}
                          isRtl={isRtl}
                          isSearchable={isSearchable}
                          name="color"
                          options={semesterOpt}
                          onChange={(e) =>
                            handleFormChange(e.value, "semester")
                          }
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-5 mb-4">
                      <div className="row">
                        <div className="col-4">
                          {" "}
                          <hr />
                        </div>
                        <div className="col-4 text-center">
                          <h5 className="text-primary mt-1">
                            Komposisi Paket Soal
                          </h5>
                        </div>
                        <div className="col-4">
                          {" "}
                          <hr />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 d-none d-flex justify-content-between flex-wrap mt-3">
                      <ul
                        className="nav nav-tabs dzm-tabs"
                        id="myTab"
                        role="tablist"
                      >
                        {["PG", "SA", "UR", "PJ", "BS"].map((tab, idx) => (
                          <li key={idx} className="nav-item">
                            <button
                              onClick={() => handleActive(tab)}
                              className={`nav-link m-1 btn-outline-primary btn-sm ${
                                activeTabs.includes(tab) ? "active" : null
                              }`}
                              type="button"
                            >
                              {getTypeName(tab)}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="col-sm-12 table-responsive">
                      <table className="table table-stripped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Bentuk Soal</th>
                            <th>N</th>
                            <th>Bb</th>
                            <th>NB</th>
                            <th>NS</th>
                            <th>NK</th>
                            <th>SpS</th>
                            <th>Bb %</th>
                            <th>SpS %</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formValues != null &&
                            activeTabs.map((tab, idx) => (
                              <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>
                                  <small>
                                    {getTypeName(tab.toUpperCase())}
                                  </small>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className={`form-control border-primary`}
                                    defaultValue={formValues[tab].jumlah_soal}
                                    onChange={(e) =>
                                      handleInput(e, tab, "jumlah_soal")
                                    }
                                    style={{
                                      padding: "5px",
                                      width: "70px",
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className={`form-control border-primary`}
                                    defaultValue={formValues[tab].bobot}
                                    onChange={(e) =>
                                      handleInput(e, tab, "bobot")
                                    }
                                    style={{
                                      padding: "5px",
                                      width: "70px",
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className={`form-control border-primary`}
                                    defaultValue={formValues[tab].point_benar}
                                    onChange={(e) =>
                                      handleInput(e, tab, "point_benar")
                                    }
                                    style={{
                                      padding: "5px",
                                      width: "70px",
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className={`form-control border-primary`}
                                    defaultValue={formValues[tab].point_salah}
                                    onChange={(e) =>
                                      handleInput(e, tab, "point_salah")
                                    }
                                    style={{
                                      padding: "5px",
                                      width: "70px",
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className={`form-control border-primary`}
                                    value={formValues[tab].point_kosong}
                                    disabled={true}
                                    onChange={(e) =>
                                      handleInput(e, tab, "point_kosong")
                                    }
                                    style={{
                                      padding: "5px",
                                      width: "70px",
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={`form-control border-primary`}
                                    value={formValues[tab].sps}
                                    disabled={true}
                                    style={{
                                      padding: "5px",
                                      width: "70px",
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={`form-control border-primary`}
                                    value={formValues[tab].bb}
                                    disabled={true}
                                    style={{
                                      padding: "5px",
                                      width: "70px",
                                    }}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={`form-control border-primary`}
                                    value={formValues[tab].spsp}
                                    disabled={true}
                                    style={{
                                      padding: "5px",
                                      width: "70px",
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-6 mb-2">
                      <ul className="mt-2">
                        <li className="mb-1">
                          <strong>N :</strong> Jumlah Kartu per Bentuk Soal
                        </li>
                        <li className="mb-1">
                          <strong>Bb :</strong> Nilai Bobot Skor untuk jenis
                          butir soal tertentu dalam paket soal ujian
                        </li>
                        <li className="mb-1">
                          <strong>TB :</strong> Total Jumlah Bobot jenis Butir
                          Soal
                        </li>
                        <li className="mb-1">
                          <strong>NB :</strong> Skor nilai Butir Soal jika
                          dijawab benar
                        </li>
                      </ul>
                    </div>
                    <div className="col-6 mb-2">
                      <ul className="mt-2">
                        <li className="mb-1">
                          <strong>NS :</strong> Skor nilai Butir Soal jika
                          dijawab salah
                        </li>
                        <li className="mb-1">
                          <strong>NK :</strong> Skor nilai Butir Soal jika tidak
                          dijawab
                        </li>
                        <li className="mb-1">
                          <strong>SpS :</strong> Nilai skor per butir soal =
                          Bb/N
                        </li>
                        <li className="mb-1">
                          <strong>Bb% :</strong> Nilai Bobot Skor untuk bentuk
                          soal tertentu dalam paket soal ujian dalam % = Bb1 /
                          Total Bobot
                        </li>
                      </ul>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <div className="d-flex mt-4 justify-content-end">
                        <button
                          type="button"
                          onClick={() => handleSaveTopik(null, true)}
                          className="btn btn-sm btn-info mt-3 me-2"
                        >
                          Simpan Draft
                        </button>
                        <button
                          type="button"
                          onClick={handleNext}
                          className="btn btn-sm btn-primary mt-3"
                        >
                          Selanjutnya <i className="fas fa-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-sm-8">
            <div className="card">
              <div className="card-header">
                <h5>Paket Soal</h5>
              </div>
              <div className="card-body table-responsive">
                {order && (
                  <DndProvider backend={HTML5Backend}>
                    <div>
                      {order.map((key, index) => (
                        <DraggableCategory
                          key={key}
                          categoryKey={key}
                          category={arrSelectedSoal[key]}
                          index={index}
                          moveCategory={moveCategory}
                          deleteRow={deleteRow}
                        />
                      ))}
                    </div>
                  </DndProvider>
                )}
              </div>
              <div className="card-footer d-flex justify-content-between">
                <div>
                  <button
                    className="btn btn-sm btn-secondary"
                    onClick={() => handleBack("first")}
                  >
                    Sebelumnya
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => handleSaveTopik(null, true)}
                    className="btn btn-sm btn-info me-2"
                  >
                    Simpan Draft
                  </button>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={handleSave}
                  >
                    Lanjutkan
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="row">
              <div className="col-12">
                <div className="card  card-sel-soal">
                  <div className="card-header">
                    <div className="d-flex col-12 justify-content-between align-items-center">
                      <SearchInput onChangeForm={(e) => {
                        e != null ? setSearchKeyword(e) : setSearchKeyword('')
                      }} />
                      <FilterKartuSoal
                        jenisSoal={[
                          { value: "PG", label: "Pilihan Ganda" },
                          { value: "PJ", label: "Penjodohan" },
                          { value: "UR", label: "Uraian" },
                          { value: "BS", label: "Benar Salah" },
                          { value: "SA", label: "Sebab Akibat" },
                        ]}
                        onChangeForm={handleQueryFilter}
                      />
                      {/* <button
                        className="btn btn-sm btn-primary ms-2"
                        onClick={() => handleCariButir()}
                      >
                        Cari
                      </button> */}
                    </div>
                  </div>
                  <div
                    className="card-body p-0 m-3 shadow-4"
                    style={{ maxHeight: "500px", overflow: "auto" }}
                  >
                    {arrButirSoal.map((v, i) => (
                      <>
                        <div className="border border-primary mt-3 rounded">
                          <div className="card-header py-3">
                            <div>
                              <h6 className="card-title">{v.judul}</h6>
                              <p className="mb-0">{v.kode_butir}</p>
                            </div>
                            <div className="card-toolbar">
                              <button
                                disabled={disabledSelectSoal}
                                type="button"
                                onClick={() => handleSelectedSoal(v)}
                                className="btn mb-1 btn-outline-primary btn-sm rounded-circle"
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="card-body py-3">
                            <div className="row mb-0 mt-3">
                              <div className="col-4"> Tipe Soal</div>
                              <div className="col-8"> : {v.tipe_soal}</div>
                            </div>

                            <div className="row">
                              <div className="col-4"> Kelas</div>
                              <div className="col-8"> : {v.kelas}</div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="card-footer">
                    <Pagination
                      totalData={total}
                      dataPerPage={perPage}
                      onChange={onPaging}
                      visiblePageNumbers={3}
                      showEllipsis={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddPaketSoal;
