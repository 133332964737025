import React, { useEffect, useRef, useState } from "react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { isEqual } from "../../../../utils";
import axiosInstance from "../../../../services/AxiosInstance";
import SMEditor from "../../Editor";

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file;
          const response = await axiosInstance.request({
            method: "POST",
            url: `media/upload`,
            data: {
              file: file,
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          resolve({
            default: `${response.data.url}`,
          });
        } catch (error) {
          reject("Hello");
        }
      });
    },
    abort: () => {},
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

const Penjodohan = (props) => {
  const [soalList, setSoalList] = useState([
    {
      pertanyaan: "",
      pasangan: "",
      pengecoh: [],
    },
  ]);
  const [pengecohList, setPengecohList] = useState([]);
  const [errors, setErrors] = useState([]);

  const prevSoalValue = useRef();

  useEffect(() => {}, [soalList]);

  useEffect(() => {
    if (
      props.defaultValue != null &&
      !isEqual(prevSoalValue.current, props.defaultValue)
    ) {
      prevSoalValue.current = props.defaultValue;
      if (props.action === "detail" || props.action == "update") {
        setSoalList([props.defaultValue]);
        var pengecoh = props.defaultValue.pengecoh;
        console.log('rosid pengecoh', props.defaultValue.pengecoh)
        if (pengecoh != undefined)
          setPengecohList(pengecoh)
      } else {
        setSoalList(props.defaultValue);
      }
    }
  }, [props.defaultValue]);

  const validateSoal = () => {
    const newErrors = soalList.map((soal, index) => {
      const error = {};
      if (!soal.pertanyaan.trim()) {
        error.pertanyaan = "Pernyataan tidak boleh kosong.";
      }
      if (!soal.pasangan.trim()) {
        error.pasangan = "Pasangan tidak boleh kosong.";
      }
      return error;
    });
    setErrors(newErrors);
    return newErrors.every((error) => Object.keys(error).length === 0);
  };

  const handleSaveButton = (redirect) => {
    if (!validateSoal()) return;

    props.onSaveButton(soalList, redirect);

    if (redirect) {
      setSoalList([
        {
          pertanyaan: "",
          pasangan: "",
          pengecoh: "",
        },
      ]);
      setPengecohList([])
      setErrors([]);
    }
  };

  const handleAddSoal = () => {
    setSoalList([
      ...soalList,
      { pertanyaan: "", pasangan: "", pengecoh: pengecohList },
    ]);
    setErrors([...errors, {}]);
  };

  const handleAddPengecoh = () => {
    setPengecohList([...pengecohList, ""]);

    const newSoal = soalList.map((soal) => ({
      ...soal,
      pengecoh: Array.isArray(soal.pengecoh) ? [...soal.pengecoh, ""] : [""],
    }));

    setSoalList(newSoal);
  };

  const handleRemoveSoal = (index) => {
    setSoalList(soalList.filter((_, i) => i !== index));
    setErrors(errors.filter((_, i) => i !== index));
  };

  const handleRemovePengecoh = (index) => {
    setPengecohList(pengecohList.filter((_, i) => i !== index));
  
    const newSoal = soalList.map((soal) => ({
      ...soal,
      pengecoh: soal.pengecoh.filter((_, i) => i !== index),
    }));
  
    setSoalList(newSoal);
  };

  const updateSoal = (index, field, value) => {
    const updatedSoalList = soalList.map((soal, i) =>
      i === index ? { ...soal, [field]: value } : soal
    );
    setSoalList(updatedSoalList);

    const updatedErrors = errors.map((error, i) => {
      if (i === index) {
        const newError = { ...error };
        if (field === "pertanyaan" && !value.trim()) {
          newError.pertanyaan = "Pernyataan tidak boleh kosong.";
        } else if (field === "pertanyaan") {
          delete newError.pertanyaan;
        }
        if (field === "pasangan" && !value.trim()) {
          newError.pasangan = "Pasangan tidak boleh kosong.";
        } else if (field === "pasangan") {
          delete newError.pasangan;
        }
        return newError;
      }
      return error;
    });
    setErrors(updatedErrors);
  };

  const updatePengecoh = (index, value) => {
    const updatedPengecohList = pengecohList;
    updatedPengecohList[index] = value;
    setPengecohList(updatedPengecohList);

    const newSoal = soalList.map((soal) => ({
      ...soal,
      pengecoh: soal.pengecoh.map((item, i) => (i === index ? value : item)),
    }));
  
    setSoalList(newSoal);
  };

  return (
    <>
      {soalList.map((soal, index) => (
        <div key={index} className="soal-item mb-4">
          <div className="row">
            <div className="col-md-12">
              <h5 className="text text-primary">
                Pernyataan {index + 1} <span className="text-danger">*</span>
              </h5>
              <div className="col-xl-12 mt-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Masukkan pertanyaan"
                  value={soal.pertanyaan}
                  onChange={(e) =>
                    updateSoal(index, "pertanyaan", e.target.value)
                  }
                  disabled={props.isDisabled}
                />
                {errors[index]?.pasangan && (
                  <div className="text-danger mt-1">
                    {errors[index].pasangan}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <h5 className="text text-primary">
                Pasangan {index + 1} <span className="text-danger">*</span>
              </h5>
              <input
                type="text"
                className="form-control"
                placeholder="Masukkan pasangan"
                value={soal.pasangan}
                onChange={(e) => updateSoal(index, "pasangan", e.target.value)}
                disabled={props.isDisabled}
              />
              {errors[index]?.pasangan && (
                <div className="text-danger mt-1">{errors[index].pasangan}</div>
              )}
            </div>
          </div>
          {!props.isDisabled && soalList.length > 1 && (
            <div className="text-end mt-3">
              <button
                className="btn btn-sm btn-danger"
                onClick={() => handleRemoveSoal(index)}
              >
                <i className="fa fa-trash me-2"></i>
                <small>Hapus Soal {index + 1}</small>
              </button>
            </div>
          )}
        </div>
      ))}

      {!props.isDisabled && props.action === "add" && (
        <div className="row mt-3">
          <div className="col-sm-12 text-start">
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={handleAddSoal}
            >
              Tambah Soal
            </button>
          </div>
        </div>
      )}

      <div className="border border-gray my-4"></div>

      {pengecohList.length > 0 ? (
        pengecohList.map((pengecoh, index) => (
          <div key={index} className="soal-item mb-4">
            <div className="row">
              <div className="col-sm-9">
                <h5 className="text text-primary">Pengecoh {index + 1}</h5>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Masukkan pengecoh"
                  value={pengecoh}
                  onChange={(e) => updatePengecoh(index, e.target.value)}
                  disabled={props.isDisabled}
                />
              </div>
              <div className="col-sm-3 d-flex justify-content-end align-items-center">
                {!props.isDisabled && (
                  <div className="text-end mt-4">
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => handleRemovePengecoh(index)}
                    >
                      <i className="fa fa-trash me-2"></i>
                      <small>Hapus Pengecoh {index + 1}</small>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>
          <b>Note: </b> Pengecoh tidak wajib ditambahkan
        </p>
      )}

      {!props.isDisabled && props.action === "add" && (
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={handleAddPengecoh}
        >
          Tambah Pengecoh
        </button>
      )}

      {!props.isDisabled && (
        <div className="row mt-3 mb-4">
          <div className="col-sm-12 text-end">
            {props.action === "update" ? (
              <>
                <button
                  className="btn btn-sm m-1 btn-danger"
                  onClick={() => handleSaveButton(false)}
                >
                  Update
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn btn-sm m-1 btn-danger"
                  onClick={() => handleSaveButton(false)}
                >
                  Simpan & Keluar
                </button>
                <button
                  className="btn btn-sm m-1 btn-primary"
                  onClick={() => handleSaveButton(true)}
                >
                  Simpan & Buat Baru
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Penjodohan;
