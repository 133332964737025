import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import axiosInstance from "../../../../services/AxiosInstance";
import Pagination from "../../Pagination";
import CardBursa from "../Card/CardBursa";

const CardPaginate = (props) => {
  const [selectedPaketSoal, setSelectedPaketSoal] = useState(null);
  const [price, setPrice] = useState(null);
  console.log('rosid bisa', props.data);

  const navigate = useNavigate();
  const handleLink = (e) => {
    navigate(e);
  };

  const onPaging = (page) => {
    props.onPaging(page);
  };

  const fetchGetId = (id) => {
    props.onDetail(id);
  };

  return (
    <div className="col-xl-12">
      <div className="row">
        {props.data.length > 0 ? (
          props.data.map((data, index) => (
            <div
              className="col-md-4 col-lg-3 col-sm-6 mt-2"
              onClick={() => fetchGetId(data.id)}
              style={{ cursor: "pointer" }}
            >
              <CardBursa keys={index} data={data} type={props.type} />
            </div>
          ))
        ) : (
          <div className="col-12 text-center py-4">Tidak ada data</div>
        )}
      </div>
      {props.data.length > 0 && (
        <Pagination
          totalData={props.total}
          dataPerPage={props.perPage}
          onChange={onPaging}
        />
      )}
    </div>
  );
};
export default CardPaginate;
