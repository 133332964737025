import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosBahanAjar from "../../../services/axiosBahanAjar";
import Pagination from "../Pagination";
import { nil } from "ajv";
const ReportPembelajaran = () => {
    const { id_pembelajaran } = useParams();
    
    const [perPage, setPerPage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);   

    useEffect(() => {
        loadData();
    }, [currentPage]);

    const loadData = async () => {
        
        try {
          const response = await axiosBahanAjar.get(`pembelajaran/${id_pembelajaran}/report`);
          console.log(response);
          var data = response.data;
          setData(data);
        //   setExData(data.items);
        //   setTotalData(data.meta.totalItems);
        } catch (error) {
          console.log(error);
        }
    }

    const handlePaging = (page) => {
        console.log("handlePaging");
        console.log("page:", page);
        setCurrentPage(page);
      }

    return (
        <div className="col-12">
          <div className="card">
          <div className="card-header card-header-komposisi">
              {/* <div>
                <div className="card-title">Komposisi Bahan Ajar</div>
              </div>
              <div>
                <span>
                  <i className={`fas fa-chevron-down`}></i>
                </span>
              </div> */}
              <div className="row">
                <div className="col-12">
                {data.paket && <table className="w-100 nm">
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Perangkai</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {data.paket.creator}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Kode Paket Ajar</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {data.paket.id_paket_ajar}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Jenjang</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {data.paket.detail.jenjang}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Kelas</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {data.paket.detail.kelas}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Rombel</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {data.pembelajaran.detail.rombel}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Jurusan</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {data.paket.detail.jurusan}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Mata Pelajaran</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {data.paket.detail.mata_pelajaran}</b>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 text-dark">
                        <b>Nama Paket Ajar</b>
                      </td>
                      <td className="p-2 text-dark">
                        <b>: {data.paket.topic}</b>
                      </td>
                    </tr>
                  </table>}
                </div>
                
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <div id="job_data" className="dataTables_wrapper">
                  <table
                    className="display w-100 dataTable table-bordered"
                    id="example5"
                    role="grid"
                    aria-describedby="example5_info"
                  >
                    <thead>
                      <tr role="row">
                        <th>No</th>
                        <th>Nama Siswa</th>
                        <th>Absensi</th>
                        <th>Catatan</th>
                        <th>Penilaian</th>
                      </tr>
                    </thead>
    
                    <tbody>
                      {data.data && data.data.map((d, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{d.nama}</td>
                          <td>{d.absensiView != null ? (d.absensiView.find((item) => item.siswa_email === d.siswa_email) == undefined ? <span class='badge badge-danger'>Belum Absen</span> : <span class='badge badge-success'>Sudah Absen</span>) : <span class='badge badge-danger'>Belum Absen</span>}</td>
                          <td>{d.catatanView != null ? (d.catatanView.find((item) => item.siswa_email === d.siswa_email) == undefined ? '-' : (d.catatanView.find((item) => item.siswa_email === d.siswa_email).catatan)) : '-'}</td>
                          <td>{d.penilaianView != null ? (d.penilaianView.find((item) => item.siswa_email === d.siswa_email) == undefined ? '-' : (d.penilaianView.find((item) => item.siswa_email === d.siswa_email).nilai)) : '-'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* <Pagination totalData={totalData} dataPerPage={perPage} onChange={handlePaging} /> */}
                </div>
              </div>
            </div>
          </div>
    
          
    
          <style>
            {`
              .position-relative:hover .overlay {
                opacity: 1;
              }
            `}
          </style>
        </div>
      );
}

export default ReportPembelajaran;
